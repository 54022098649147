import React from "react";
import { Input, Label, FormGroup, FormText, Col, InputGroup, InputGroupAddon,
  InputGroupText } from "reactstrap";

const FormElementRaw = ({name, value, onChange, type, step, children, checkboxText}) => (
  <Choose>
    <When condition={type === "select"}>
      <Input type="select" id={name} name={name} value={value}
        onChange={onChange}>
        {children}
      </Input>
    </When>
    <When condition={type === "checkbox"}>
      <FormGroup check className="align-self-start">
        <Label check>
          <Input type="checkbox" id={name} name={name}
          checked={value} onChange={onChange} />&nbsp;{checkboxText}
        </Label>
      </FormGroup>
    </When>
    <Otherwise>
      <Input type={type} id={name} name={name} value={value || ""} step={step || undefined}
        onChange={onChange} />
    </Otherwise>
  </Choose>
);

const FormElement = ({name, label, instructions, type, value, onChange,
  labelWidth, inputWidth, children, inputGroupAddon, step, ...props}) => {
  const labelCols = labelWidth || 3;
  const inputCols = inputWidth || 9;
  return (
    <FormGroup row>
      <Label for={name} sm={labelCols}>{label}</Label>
      <Col sm={inputCols}
        className="d-flex align-items-center flex-column justify-content-center">
      { inputGroupAddon
        ? <InputGroup size="sm">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>$</InputGroupText>
            </InputGroupAddon>
            <FormElementRaw name={name} type={type} value={value}
              onChange={onChange} children={children} step={step} {...props}/>
          </InputGroup>

        : <FormElementRaw name={name} type={type} value={value}
          onChange={onChange} children={children} step={step} {...props}/>
      }
      {
        instructions && 
        <FormText className="align-self-start" color="muted">
          {instructions}
        </FormText>
      }
      </Col>
    </FormGroup>
  )
}

export default FormElement;
