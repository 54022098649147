import React from "react";
import moment from "moment";
import { Card, ListGroup } from "reactstrap";

import BookingGroup from "./BookingGroup";
import { sortGroupedBookings, getReferenceTime } from "./utils";

const groupBookings = bookings =>
  bookings.reduce((groupedBookings, booking) => {
    let group;
    const referenceTime = getReferenceTime(booking);
    if (["complete", "canceled", "noshow"].includes(booking.status))
      group = "completed";
    else if (booking.status === "active" && referenceTime.isBefore(moment()))
      group = "overdue";
    else
      group = "active";

    if (!groupedBookings[group]) {
      groupedBookings[group] = [];
    }
    groupedBookings[group].push(booking);
    return groupedBookings;
  }, {});

const BookingsTab = ({ bookings, activeTimezone, loadBooking }) => {
  if (!bookings.size) {
    return (
      <Card className="nearestBookings" body>
        <div className="panelStatusMessage">
          No upcoming or overdue bookings
        </div>
      </Card>
    ) 
  }

  const groupedBookings = sortGroupedBookings(groupBookings(bookings));
  return (
    <Card className="nearestBookings">
      <ListGroup flush>
        <BookingGroup
          group="overdue"
          bookings={groupedBookings["overdue"]}
          activeTimezone={activeTimezone}
          loadBooking={loadBooking}
        />
        <BookingGroup
          group="active"
          bookings={groupedBookings["active"]}
          activeTimezone={activeTimezone}
          loadBooking={loadBooking}
        />
        <BookingGroup
          group="completed"
          bookings={groupedBookings["completed"]}
          activeTimezone={activeTimezone}
          loadBooking={loadBooking}
        />
      </ListGroup>
    </Card>
  )
};

export default BookingsTab;
