import React from "react";
import { ListGroupItem } from "reactstrap";

const Separator = ({ text }) => (
  <ListGroupItem className="separator">
  	{text}
  </ListGroupItem>
)

export default Separator;
