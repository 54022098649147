import React from 'react';
import { connect } from 'react-redux';

import Icon from '../Icon'
import { Button, ButtonGroup } from "reactstrap";

import { setMode, reset, initializePlaceholderBooking } from "../../actions";

import classNames from "classnames";
import "./index.global.scss";

const buttons = [
  {name: "create", title: "New Booking", icon: "plus", style: "success", mode: "create"},
  {name: "activate", title: "Existing Booking", icon: "redo2", style: "primary", mode: "activate"},
  {name: "return", title: "Return", icon: "reply", style: "warning", mode: "return"}
];

const ModeSelectorPresentational = ({ mode, config, navigate, reset }) => (
  <ButtonGroup>
    <Button
      key={"home"}
      className={classNames("navButton", "home")}
      active={false}
      onClick={() => reset()}
    >
      <Icon icon="house" size={18} color="dark" />
    </Button>
    <div className="navButtonSeparator" />
    {
      buttons.filter(btn => config.modes.includes(btn.name)).map(btn => {
        return (
          <Button
            key={btn.name}
            className={classNames("navButton", btn.name)}
            active={btn.mode == mode}
            style={{ width: btn.width }}
            onClick={() => navigate(btn.mode)}
          >
            <Icon icon={btn.icon} size={btn.size} color="dark" />
            {
              btn.title && <span>{` ${btn.title}`}</span>
            }
          </Button>
        );
      })
    }
  </ButtonGroup>
);

const mapStateToProps = (state) => {
  return {
    mode: state.mode,
    config: state.config
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    navigate: (navChoice) => {
      dispatch(setMode(navChoice));
      dispatch(initializePlaceholderBooking());
    },
    reset: () => dispatch(reset())
  }
}

const ModeSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModeSelectorPresentational)

export default ModeSelector; 
