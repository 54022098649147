import React from 'react';
import { push } from 'redux-first-router';
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Label, Input, Form,
  FormGroup, FormFeedback, FormText, Container, Alert } from "reactstrap";
import Icon from "../Icon.jsx";

import classNames from "classnames";
import styles from "./index.scss";

const env = process.env.ENV;

class Login extends React.Component {

  SSO_AUTH = "sso"
  BASIC_AUTH = "basic"

  defaultActionButtonText = "Log in"

  state = {
    errorId: null,
    loginData: {
      email: "",
      password: ""
    },
    showPasswordField: false,
    emailInvalid: false,
    actionButtonText: this.defaultActionButtonText,
    actionButtonDisabled: false
  }

  componentDidMount()
  {
    const { errorId } = this.props;
    this.setState({ errorId });
  }

  getError = (errorId) => {
    switch (errorId) {
      case 1:
        return "Manta Equipment is currently available to staff only";
      case 2:
        return "Invalid e-mail or password";
      default:
        return "Unknown error";  
    }
  }

  decomposeEmail(email) {
    const emailRegex = /^(\S+)@(\S+)$/g;
    let lookup, matches;
    while ((lookup = emailRegex.exec(email)) !== null) {
      matches = lookup;
    }
    return matches || [];
  }

  getLoginParams(domain) {
    return API.get("loginParams", {domain});
  }

  handleFieldChange = (event) => {
    this.setState({
      loginData: {
        ...this.state.loginData, 
        [event.target.name]: event.target.value
      }
    });
  }

  /*
   * Authenticate with server when button is clicked
   */
  handleLogin = (event) => {
    event.preventDefault();

    const emailFieldValue = this.state.loginData.email;
    const [email, username, domain] = this.decomposeEmail(emailFieldValue);
    if (!email || !username || !domain) {
      this.setState({ emailInvalid: true });
      return;
    } else {
      this.setState({ emailInvalid: false });  
    }

    this.getLoginParams(domain)
    .then(({ loginMethod }) => {
      if (loginMethod == this.SSO_AUTH) {
        this.setState({
          actionButtonDisabled: true,
          actionButtonText: "Transferring you..."
        });
        window.location = this.props.API.federatedLoginPath;
      } else if (loginMethod == this.BASIC_AUTH) {
        if (this.state.showPasswordField == false
          && this.state.loginData.password.length == 0) {
          this.setState({
            showPasswordField: true
          });
        } else {
          this.setState({
            actionButtonDisabled: true,
            actionButtonText: "Logging in...",
            errorMessage: null,
            errorId: null
          });
          this.handleBasicLogin()
          .then(success => {
            if (!success) {
              this.setState({
                actionButtonDisabled: false,
                actionButtonText: this.defaultActionButtonText
              });
            }
          });
        }
      } else {
        this.setState({
          actionButtonDisabled: false,
          actionButtonText: this.defaultActionButtonText,
          errorMessage: "An unknown error occurred. Please refresh and try again.",
        });
      }
    });
  }

  handleBasicLogin = () => {
    const { email, password } = this.state.loginData;
    return this.props.API.authWithServer(email, password)
    .then(() => {
      const { previousLocation } = this.props;
      if(previousLocation && previousLocation !== "/logout") {
        push(previousLocation);
      } else {
        push("/app");
      }
      return true;
    })
    .catch(errorObj => {
      console.error("Error logging in:", errorObj);
      if (errorObj.errorId) {
        this.setState({ errorId: errorObj.errorId });
      } else {
        const errorMessage = errorObj.error || "Unknown error";
        this.setState({ errorMessage });
      }
      return false;
    });
  }

  handleReset = (event) => {
    event.preventDefault();
    push("/account/reset");
  }

  render() 
  {
    return (
      <Container fluid className="d-flex align-items-center justify-content-center">
        <Card className={styles.loginCard}>
          <CardHeader className={styles.loginCardHeader} />
          <CardBody>
            <Form onSubmit={this.handleLogin} className="mb-0 mt-4">
              <FormGroup>
                <Label for="email" className="sr-only">E-mail address</Label>
                <Input type="text" id="email" name="email"
                  placeholder="Enter e-mail address"
                  autoFocus={true} onChange={this.handleFieldChange}
                  invalid={this.state.emailInvalid} />
                <FormFeedback>Sorry, that doesn't look like a valid e-mail address</FormFeedback>
              </FormGroup>
              {
                this.state.showPasswordField &&
                <FormGroup>
                  <Label for="password" className="sr-only">Password</Label>
                  <Input type="password" id="password" name="password" placeholder="Enter password"
                    autoFocus={true} onChange={this.handleFieldChange} />
                </FormGroup>
              }
              <FormGroup>
                <Label for="submit" className="sr-only">Log in</Label>
                <Input id="submit" color="primary" type="submit"
                  className={styles.primaryButton}
                  value={this.state.actionButtonText}
                  disabled={this.state.actionButtonDisabled}
                  onClick={this.handleLogin} />
              </FormGroup>
              <div className={styles.loginCardFooter}>
                {
                  (this.state.errorId || this.state.errorMessage) &&
                  <FormText color="danger" className={styles.loginInfoBox}>
                    {
                      this.state.errorId
                        ? this.getError(this.state.errorId)
                        : this.state.errorMessage
                    }
                  </FormText>
                }
                <FormText color="secondary" className={styles.loginActions}>
                  <a className="text-secondary" href="/account/reset"
                    onClick={this.handleReset}>Reset password</a>
                </FormText>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>);
  }
}

export default Login;
