import React from 'react';
import { connect } from 'react-redux';
import { push, go, replace } from 'redux-first-router';

import { Group } from '../../records';
import { toggleParentCategory } from '../../actions';
import CategoryEditor from "./CategoryEditor.jsx";
import ResourceCategories from "../ResourceCategories.jsx";
import CategoryResources from "./CategoryResources.jsx";
import MessageBox from "../MessageBox.jsx";

import FormElement from "./FormElement.jsx";
import Icon from "../Icon.jsx";

import { showNotification, fetchCategories } from '../../actions';

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, CardHeader,
  Button, CardTitle, CardText, Container, Row, Col, ListGroup, ListGroupItem,
  ListGroupItemHeading, ListGroupItemText } from "reactstrap";

const GroupItem = ({group, onEdit, onDelete}) => (
    <ListGroupItem style={{display: "block"}}>
      <CardBody className="float-right">
        <Button color="info" onClick={() => onEdit(group)}>Edit</Button> &nbsp;
        <Button color="danger" onClick={() => onDelete(group)}>Delete</Button>
      </CardBody>
      <ListGroupItemHeading>{group.name}</ListGroupItemHeading>
      <ListGroupItemText>
        {group.notes}
      </ListGroupItemText>
    </ListGroupItem>
);

class GroupItemEdited extends React.Component 
{
  state = { group: this.props.group };

  handleFieldChange = (event) =>
  {
    this.setState({
      group: {
        ...this.state.group, 
        [event.target.name]: event.target.value
      }
    });
  }

  render()
  {
    const { onCancel, onSave } = this.props;
    const { group } = this.state;
    return (<ListGroupItem style={{display: "block"}}>
      <CardBody className="float-right" >
        <Button onClick={() => onSave(group)} color="info">Save</Button> &nbsp;
        <Button onClick={onCancel}>Cancel</Button>
      </CardBody>
      <FormElement name="name" label="Group name" 
        onChange={this.handleFieldChange} value={group.name} />
      <FormElement name="notes" label="Group notes"
        onChange={this.handleFieldChange} value={group.notes} />
    </ListGroupItem>);
  }
};

const GroupList = ({groups, editedGroup, ...props}) =>
(
  <ListGroup>
  {groups.map((group, index) => {
      const GroupComponent = (editedGroup && group.id == editedGroup.id) ? GroupItemEdited : GroupItem;
      return <GroupComponent key={`${group.id}-${index}`} group={group} {...props} />;
    }
  )}
  </ListGroup>
);

class GroupsSection extends React.Component
{
  state = {
    pending: false,
    groups: [],
    editedGroup: null
  }
  
  componentWillMount() {
    this.loadGroups();
  }

  loadGroups = () => {
    return API.get("groups").then(groups => this.setState({ groups }));
  }

  removeEmptyGroups = () => {
    this.setState({ groups: this.state.groups.filter(g => g.id > 0) });
  }

  newGroup = () => {
    if(this.state.groups.find(g => !g.id))
      return;
    const newGroup = Group.newGroup(); 
    const { groups } = this.state;
    this.setState({ groups: [newGroup, ...groups], editedGroup: newGroup });
  }

  editGroup = (group) => {
    this.setState({ editedGroup: group }, () => {
      this.removeEmptyGroups();
    });
  }

  saveGroup = (group) => {
    const existingGroup = group && group.id && group.id > 0;
    (() => {
      if(existingGroup)
        return API.put("group/" + group.id, group);
      else
        return API.post("groups", group);
    })()
    .then((response) => {
      if(response.id)
      {
        this.loadGroups();
        this.editGroup(null);
        this.props.dispatch(showNotification({
          message: `Group ${group.name} has been saved`
        }))
      }
      else
      {
        const errors = response.errors.map(err => `${err.message}`).join(", ");
        this.props.dispatch(showNotification({
          message: `Error saving group. ${response.message}: ${errors}`,
          color: "danger",
          dismiss: 10000
        }))
      }
    })
    .catch(response => {
      this.props.dispatch(showNotification({
        message: response.message,
        color: "danger",
        dismiss: 10000
      }));
      this.setState({ pending: false });
      return;
    });
  }

  deleteGroup = (group) => {
    if(!confirm("Deleting this group will unassociate all users from it. Confirm?"))
      return;
    return API.delete("group/" + group.id)
    .then(() => {
      this.loadGroups();
      this.props.dispatch(showNotification({
        message: `Group ${group.name} has been deleted`
      }));
    });
  }

  render() {
    const { groups, editedGroup } = this.state;
    return (
      <Row className="manager-row">
        <Col md={12} className="scroll-col">
          <Card>
            <CardHeader>Groups 
              <Button className="float-right" onClick={this.newGroup} color="primary">
                <Icon icon="plus" />&nbsp; New group
              </Button>
            </CardHeader>
            <CardBody>
              <GroupList groups={groups} editedGroup={editedGroup}
                onEdit={this.editGroup}
                onCancel={() => this.editGroup(null)}
                onSave={this.saveGroup}
                onDelete={this.deleteGroup}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    DepartmentId: state.selectedDepartment
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsSection);
