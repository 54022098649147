export * from "./app/app.record.js";
export * from "./booking/booking.record.js";
export * from "./bookedResource/bookedResource.record.js";
export * from "./user/user.record.js";
export * from "./group/group.record.js";
export * from "./resource/resource.record.js";
export * from "./category/category.record.js";
export * from "./department/department.record.js";
export * from "./notification/notification.record.js";


