import React from "react";
import Joyride from "react-joyride";
import { ACTIONS, EVENTS } from "react-joyride/lib/constants";

import API from "../../api.js";
import { tourSteps } from "../../records.js";

class MantaTour extends React.Component {
  state = {
    run: true,
    skipped: false,
    stepIndex: 0,
    tourInstanceKey: 0
  }

  tourHandler = (tourState) => {
    const { action, index, type } = tourState;
    if (type === EVENTS.TOUR_END) {
      if (action === ACTIONS.SKIP) {
        return this.setState({
          tourInstanceKey: this.state.tourInstanceKey + 1,
          stepIndex: tourSteps.length - 1,
          skipped: true,
        })
      }
      API.cookies.set("tourComplete", true);
      this.setState({ run: false });
      this.props.onEndTour();
    } else if ([EVENTS.STEP_AFTER, EVENTS.CLOSE, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    }
  }

  render() {
    const { run, stepIndex, skipped, tourInstanceKey } = this.state;

    if(process.env.ENV === "test") // jsdom does not mount targets in test
      return null;

    return (
      <Joyride
        key={tourInstanceKey}
        run={run}
        hideBackButton={skipped}
        showSkipButton={true}
        continuous={true}
        steps={tourSteps}
        stepIndex={stepIndex}
        callback={this.tourHandler}
        locale={{
          back: "Back",
          next: "Next",
          close: "Close",
          last: skipped ? "Okay, got it!" : "Finish",
          skip: "Skip Tour"
        }}
      />
    );
  }
}

export default MantaTour;
