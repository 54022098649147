import { Record, Map } from "immutable";
import moment from "moment";

//BookedResource
const bookedResourceLocalStatusMap = {
  active:             {name: "Active"},
  complete:           {name: "Completed"},
  overdue:            {name: "Late"},
  partiallyComplete:  {name: "Returned"},
  reserved:           {name: "Reserved"},
  tentative:          {name: "Pending"},
  canceled:           {name: "Canceled"},
  noshow:             {name: "Canceled"},
  error:              {name: "Error"},
  unclassified:       {name: "Unknown"}
}

export const BookedResourceRecord = Record({
  id: -1,
  start: null,
  end: null,
  bookedStart: null,
  bookedEnd: null,
  adjustedStart: null,
  adjustedEnd: null,
  renewals: null,
  status: "active",
  BookingId: null,
  ResourceId: null,
  PenaltyId: null,
  deleted: false,

  Booking: null,
  Resource: null,
});

export const PenaltyRecord = Record({
  id: -1,
  notes: "",
  charges: null,
  paid: false
});

export class BookedResource extends BookedResourceRecord {
  constructor(obj) {
    super(obj);
  }

  prepareForBooking(booking) {
    let object = this.merge({ BookingId: booking.id })

    if(booking.status !== "canceled" && booking.status !== "complete")
    {
      if(object.status !== "returned" &&
        (object.adjustedStart || object.adjustedEnd))
        object = object.applyAdjustments();
    }

    if(object.id < 0)
      object = object.merge({ id: undefined });

    return object; 
  }

  applyAdjustments() {
    let object = this;

    if(this.adjustedEnd)
      object = object.merge({
        end: this.adjustedEnd,
        bookedEnd: this.adjustedEnd,
      });

    if(this.adjustedStart)
      object = object.merge({
        start: this.adjustedStart,
        bookedStart: this.adjustedStart,
      });
    return object;
  }

  getTimelineClasses(localStatus, mode) {
    // Depending on the current mode, we dim the elements that are irrelevant
    // e.g. when return is enabled, non-returnable bookings are dimmed.
    let classes = [localStatus];
    if((mode === "activate" && localStatus !== "reserved")
      || (mode === "return" && localStatus !== "overdue" && localStatus !== "active")
      || (mode === "create" && localStatus !== "tentative"))
    {
        classes.push("irrelevant");
    }

    if(this.deleted)
      classes.push("invisible");

    return classes;
  }

  // BookedResource.status = state of resource in booking workflow
  // Local status = inferred from time, mode and user adjustments, mostly for UI
  getLocalStatus(bookingLocalStatus, mode) {
    let localStatusID;
    const endDatePassed = this.end.isBefore(moment());

    if(bookingLocalStatus.isPastInactive && this.status === "returned")
      localStatusID = "complete";
    else if(bookingLocalStatus.isPastInactive)
      localStatusID = "canceled";
    else if(bookingLocalStatus.isActive && this.status === "active" && endDatePassed)
      localStatusID = "overdue";
    else if(bookingLocalStatus.isActive && this.status === "returned")
      localStatusID = "partiallyComplete";
    else if(bookingLocalStatus.isActive && !endDatePassed)
      localStatusID = "active";
    else if(bookingLocalStatus.status === "reserved")
      localStatusID = "reserved";
    else if(bookingLocalStatus.status === "tentative")
      localStatusID = "tentative";
    else
      localStatusID = "error";

    const timelineClasses = mode
      ? this.getTimelineClasses(localStatusID, mode)
      : undefined;

    return {
      status: localStatusID,
      name: bookedResourceLocalStatusMap[localStatusID].name,
      classList: timelineClasses || [],
      className: timelineClasses ? timelineClasses.join(" ") : "",
      isActive: !["complete", "partiallyComplete", "error",
        "unclassified"].includes(localStatusID)
    };
  }

  getDuration() {
    const start = this.adjustedStart || this.start;
    const end = this.adjustedEnd || this.end;
    return end.diff(start);
  }

  // Helper that determines item bounds for timeline item
  getTimeframe(bookingStatus, bookedResourceStatus) {
    // Displaying start and end times:
    // * Past, complete booking items AND partially returned booking
    // items that have been returned display the real times [start], [end].
    // * Active items show actual [start], but the [bookedEnd].
    // * Overdue items also show the real [start], and [bookedEnd]. 
    // * Future reservation items show [bookedStart] and [bookedEnd],
    //   although these are initially set to match [start] and [end].
    let start, end;

    if(bookingStatus.status === "complete"
      || bookedResourceStatus.status === "partiallyComplete")
    {
      start = this.start;
      end = this.end;
    }
    else if(bookingStatus.status === "active")
    {
      start = this.start;
      end = this.bookedEnd;
    }
    else
    {
      start = this.bookedStart;
      end = this.bookedEnd;
    }

    start = this.adjustedStart || start;
    end = this.adjustedEnd || end;
    return { start, end };
  }

  getTimelineItem(resource, booking, mode) {
    const bookingLocalStatus = booking.getLocalStatus();
    const localStatus = this.getLocalStatus(bookingLocalStatus, mode);
    const itemClassName = localStatus.className;
    const isRelevant = !localStatus.classList.includes("irrelevant");
    const { start, end } = this.getTimeframe(bookingLocalStatus, localStatus);

    let itemEnd = end;
    if(localStatus.status === "overdue" && end.isBefore(moment()))
      itemEnd = moment();

    const isDeleted = resource.deletedAt;

    const item = {
      id: this.id,
      // subgroup: `regular-${this.id}`,
      // group: "all",
      start,
      end: itemEnd,
      content: resource.name + (isDeleted ? " <i style='color: red'>(deleted)</i>" : "") ,
      // visjs-timeline does not support setting selectable for each item, but
      // we pass it anyways to read it as the `data-...` property via DOM node
      selectable: isRelevant,// && !isDeleted,
      
      // TODO: Setting settings below does not seem to work... visjs or react
      // wrapper issue likely, investigate

      // editable: {
      //   updateTime: isRelevant,
      //   updateGroup: false,
      //   remove: isRelevant && mode !== "return"
      // },
      className: itemClassName
    };

    return item;

    // Use code below to enable pretty overdue items with actual and booked times
    // Also enable group and subgroup above and in render() of resourceTimeline
    /*

    if (localStatus.status === "overdue") {
      return List([
        {
          ...item,
          subgroup: `overdue-${this.id}`,
          group: "all"
        }, {
          ...item,
          id: "actual-" + this.id,
          subgroup: `overdue-${this.id}`,
          group: "all",
          end: moment().subtract(1, "h"),
          content: `<svg class="svgIcon timeline" width="16" height="16" viewBox="0 0 16 16" display="inline-block"><path fill="#F39C12" d="M8 1.5c-1.736 0-3.369 0.676-4.596 1.904s-1.904 2.86-1.904 4.596c0 1.736 0.676 3.369 1.904 4.596s2.86 1.904 4.596 1.904c1.736 0 3.369-0.676 4.596-1.904s1.904-2.86 1.904-4.596c0-1.736-0.676-3.369-1.904-4.596s-2.86-1.904-4.596-1.904zM8 0v0c4.418 0 8 3.582 8 8s-3.582 8-8 8c-4.418 0-8-3.582-8-8s3.582-8 8-8zM7 11h2v2h-2zM7 3h2v6h-2z"></path></svg><span>&nbsp;</span>`,
          className: itemClassName + " actual",
          selectable: false
        }
      ]);
    } else return item;
    */
  }

  getConflictingTimelineItem(displayName, booking, anonymize) {
    let displayEnd = this.end;
    let conflictDescription = "conflicting";
    if(booking.status === "active" && this.status === "active")
    {
      // Show current time in case when unreturned and past booking end time
      // 15 min gap for visjs to display things on same line
      displayEnd = this.end.isAfter(moment())
        ? this.end
        : moment().subtract(15, "minutes");
      conflictDescription = "not returned";
    }
    else
      conflictDescription = `booked by ${anonymize ? "another user" : booking.Keeper.name}`;


    return {
      id: `conflict-${this.id}`,
      subgroup: `conflict-${this.id}`,
      group: "all",
      start: this.start,
      end: displayEnd,
      content: `${displayName} (${conflictDescription})`,
      selectable: false,
      className: "conflict",
    };
  }

  getPeerTimelineItem(displayName, booking) {
    return {
      id: `peer-${this.id}`,
      subgroup: `peer-${this.id}`,
      group: "all",
      start: this.bookedStart,
      end: this.bookedEnd,
      content: `${displayName} (Booking ${booking.id})`,
      selectable: false,
      className: "peer"
    };
  }

  isAdjusted() {
    return this.adjustedStart || this.adjustedEnd;
  }

  static makeRecord(bookedResource) {
    return new BookedResource({
      ...bookedResource,
      start: moment(bookedResource.start),
      end: moment(bookedResource.end),
      bookedStart: moment(bookedResource.bookedStart),
      bookedEnd: moment(bookedResource.bookedEnd)
    });
  }

  static makeRecords(bookedResources, options=null) {
    let immutableMap = Map();
    for(let key in bookedResources)
    {
        // haxx sorrix
      if(options && options.plain === true)
      {
        immutableMap = immutableMap.set(
          bookedResources[key].id,
          bookedResources[key]
        );
      }
      else
      {
        immutableMap = immutableMap.set(
          bookedResources[key].id,
          BookedResource.makeRecord(bookedResources[key])
        );
      }
    }
    return immutableMap;
  }
}
