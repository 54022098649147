import React from "react";
import FormElement from "./FormElement.jsx";

const MetaFieldEditor = ({ entity, metaMap, handleMetaChange }) => 
  <meta-field-editor>
    <hr />
    <h5 className="mt-3">Meta fields <small>(optional)</small></h5>
    {
      metaMap.map(meta => {
        if(meta.type === "boolean")
        {
          return (
            <FormElement key={meta.key} name={meta.key} label={meta.name} type="checkbox"
              value={entity.meta[meta.key] || 0}
              onChange={handleMetaChange} />
          );
        }
        else if(meta.type === "int")
        {
          return (
            <FormElement key={meta.key} name={meta.key} label={meta.name} type="number"
              value={entity.meta[meta.key] || 0}
              onChange={handleMetaChange} />
          );
        }
        else if(meta.type === "enum")
        {
          return (
            <FormElement key={meta.key} name={meta.key} label={meta.name} type="select"
              value={entity.meta[meta.key] || meta.defaultValue}
              onChange={handleMetaChange}
            >
            {
              meta.options.map(option => 
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              )
            }
            </FormElement>
          );
        }
        else
        {
          return <span>Unspported meta: {meta.key}</span>
        }
      })
    }
  </meta-field-editor>

export default MetaFieldEditor;