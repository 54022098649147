export const postForm = (API, url, fields) => {
  const formData = new FormData();

  // AWS is very fussy about form data order:
  // - `key` must come first
  // - `file` must come last
  // - `Content-type` must come before `file`
  let fieldNames = Object.keys(fields);
  const keyFieldIndex = fieldNames.indexOf("key")
  if (keyFieldIndex !== -1) {
    fieldNames.splice(keyFieldIndex, 1);
    fieldNames = ["key", ...fieldNames];
  }
  const fileFieldIndex = fieldNames.indexOf("file")
  if (fileFieldIndex !== -1) {
    fieldNames.splice(fileFieldIndex, 1);
  }


  fieldNames.forEach(fieldName => {
    formData.append(fieldName, fields[fieldName]);
  });

  formData.append("Content-Type", fields["file"].type);
  formData.append("file", fields["file"]);

  // 1 min limit to accommodate large files over lossy connections
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => reject(`${url} timed out`), 60000);
  });

  const fetchPromise = fetch(url, {
      method: "POST",
      body: formData
    })
    .then(API.unwrapResponse);

  return Promise.race([fetchPromise, timeoutPromise])
    .catch(e => {
      API.logError(e)
      return Promise.reject(e);
    });
}
