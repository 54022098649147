import React from "react";
import moment from "moment";
import { Booking, BookedResource } from "../../records";

const dateTimeFormat = 'MMM Do YYYY, h:mm a';

export default (bookedResource, booking, absoluteDates=false, timezone="UTC") => {
  const bookingRecord = Booking.makeRecord(bookedResource.Booking || booking);
  const bookedResourceRecord = BookedResource.makeRecord(bookedResource);
  const bookingStatus = bookingRecord.getLocalStatus();
  const bookedResourceStatus = bookedResourceRecord.getLocalStatus(bookingStatus);

  if(bookingStatus.status === "noshow")
    return <span>Auto-canceled</span>;

  switch(bookedResourceStatus.status)
  {
    case "complete":
    case "partiallyComplete":
      return <span>{`Returned ${absoluteDates 
          ? moment.tz(bookedResource.end, timezone).format(dateTimeFormat) 
          : moment.tz(bookedResource.end, timezone).fromNow()}`}</span>;
    case "overdue":
      return (
        <strong style={{color: "red"}}>
          {`Overdue by ${moment().from(bookedResource.bookedEnd, true)}`}
        </strong>
      );
    default:
      return <span>{bookedResourceStatus.name}</span>;
  }
}
