import React from "react";
import { connect } from 'react-redux';
import { reset } from '../../../actions';
import BookingControlButton from './BookingControlButton'

const DiscardBookingButton = ({ booking, mode, dispatch }) => (
  <BookingControlButton
    bsStyle="danger" icon="cross"
    caption="Discard"
    clickHandler={() => dispatch(reset())}
  />
)

export default connect()(DiscardBookingButton)
