import API from "../api.js";
import { PendingState } from "../records.js";

export const initialized = (state=false, action) => {
  if(action.type === 'INITIALIZED')
    return true;
  return state;
};

export const config = (state=null, action) => {
  if(action.type === 'INITIALIZED')
    return action.config;
  return state;
};

export const mode = (state="create", action) => {
  switch(action.type) {
    case "SET_MODE":
      return action.mode;
    default:
      return state;
  }
}

export const query = (state = "", action) => {
  switch(action.type)
  {
    case 'ENTER_QUERY':
      return action.query;
    case 'SELECT_CATEGORY':
      return "";
    default:
      return state;
  }
}

export const filterSource = (state="omnibox", action) => {
  if(action.type === 'ENTER_QUERY')
    return "omnibox";
  else if(action.type == "SELECT_CATEGORY")
    return "categories";
  return state;
};


export const defaultSettings = {
  serviceSchedules: []
}
export const settings = (state=defaultSettings, action) => {
  switch(action.type)
  {
    case 'LOAD_SETTINGS':
      return action.settings;
    default:
      return state;
  }
}

const defaultPendingState = new PendingState();
const pendingRegex = /(?:FETCH|CREATE|ACTIVATE|UPDATE|CANCEL|RETURN|RENEW)_(.+)_(PENDING|FULFILLED|REJECTED)/;

export const pending = (state=defaultPendingState, action) => {
  const matchesPending = action.type.match(pendingRegex);
  if(matchesPending !== null)
  {
    let item = matchesPending[1].toLowerCase();
    if(item === "resource")
      item = "booking";
    else if(item === "booked_resources")
      item = "bookedResources";
    else if(item === "user_with_bookings")
      item = "userWithBookings";
    else if(["booked_resources_only", "booking_only", "booked_peers",
      "service_schedule", "resources_unavailable", "nearest_bookings"].includes(item))
      return state;
    const pending = matchesPending[2] === "PENDING";
    return state.set(item, pending);
  }

  return state;
}



export const logger = (state=null, action) => {
  const badPayload = action.type.includes("_FULFILLED") 
                     || action.type === "LOAD_SETTINGS"; 
  API.log("actions", !badPayload ? action : {type: action.type});
  return null;
};
