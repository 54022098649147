import React from 'react';
import { connect } from 'react-redux'
import { Map } from "immutable";
import API from "../api.js";

import _ from "underscore";
import Moment from "moment-timezone";
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

import { getActiveBooking, getEarliestStart, getLatestEnd, getTimeHandles,
  getTimelineItems, getBookingConflictTimelineItems, getBookedPeerTimelineItems,
  getActiveTimezone } from "../selectors";
import { adjustResourceTime, adjustAllResourceTime, removeResourceFromBooking,
  selectTimelineBookingResource } from "../actions";

import GenericSpinner from "./GenericSpinner.jsx";
import Timeline from 'react-visjs-timeline'
import { Button } from "reactstrap";
import Icon from "../components/Icon.jsx";

const ZOOM_MIN_MINS = 60 * 60 * 3; //3h
const ZOOM_MAX_MINS = 60 * 60 * 24 * 30 * 4; //~4mo
const TIME_PADDING_MINS = 60 * 12; //12 h

const DEFAULT_HEIGHT = 225;
// http://visjs.org/docs/timeline/#Configuration_Options
const timelineOptions = {
  width: '100%',
  showMajorLabels: true,
  showCurrentTime: true,
  zoomMin: ZOOM_MIN_MINS * 1000,
  zoomMax: ZOOM_MAX_MINS * 1000,
  type: 'range',
  format: {
    minorLabels: {
      minute: 'h:mma',
      hour: 'ha'
    }
  }
}

const TimelineSizeControls = ({onExpand, onContract, showContract=false, scrollBuffer=100}) => (
  <div style={{position: "relative", top: -27, float: "right"}}>
    {showContract && (<Button size="sm" onClick={onContract}>
      <Icon icon="arrow-up2" color="dark" />
    </Button>)
    }
    <Button size="sm" onClick={onExpand}>
      <Icon icon="arrow-down2" color="dark" />&nbsp;Expand
    </Button>
    <div style={{height: scrollBuffer}}></div>
  </div>
)

class ResourceTimeline extends React.Component {

  constructor(props){
    super(props);
    const { start, end } = this.calculateTimelineRange();
    this.state = {
      start: start,
      end: end,
      setRange: true,
      timelineSizeControlsShowing: false,
      height: DEFAULT_HEIGHT
    };
  }

  componentDidMount() {
    /*
    this.scrollListener = window.addEventListener("scroll", () => {
      this.setState({ height: DEFAULT_HEIGHT + window.scrollY });
    });
    */
  }

  componentWillUnmount() {
    //window.removeEventListener("scroll", this.scrollListener);
  }

  onRangeChanged = ({ start, end, byUser }) => {
    if(!byUser)
      return;

    this.setState({
      setRange: false
    });
  }

  componentWillReceiveProps(nextProps) {
    if(!nextProps.selection)
      return;

    this.updateTimelineRange(nextProps);
    this.setState({
      setRange: true
    });
  }

  calculateTimelineRange = (props = null) => {
    if(!props)
      props = this.props;
    const selectedItem = props.items.find(item => item.id === props.selection);
    if(!selectedItem)
      return {
        start: moment().subtract(TIME_PADDING_MINS, "m"),
        end: moment().add(TIME_PADDING_MINS, "m")
      };

    switch(props.mode)
    {
      case "create":
      case "activate":
        return {
          start: moment().subtract(TIME_PADDING_MINS, "m"),
          end: moment(selectedItem.end).add(TIME_PADDING_MINS, "m")
        };
      case "return":
      {
        const endsInFuture = selectedItem.end.isAfter(moment());
        if(endsInFuture){
          return {
            start: moment().subtract(TIME_PADDING_MINS, "m"),
            end: moment(selectedItem.end).add(TIME_PADDING_MINS, "m")
          };
        }
        else
        {
          return {
            start: moment(selectedItem.end).subtract(TIME_PADDING_MINS, "m"),
            end: moment().add(TIME_PADDING_MINS, "m")
          };
        }
      }
    }
  }

  updateTimelineRange = (props) => {
    const { start, end } = this.calculateTimelineRange(props);
    this.setState({
      start,
      end
    });
  }

  getTimelineRange = () => {
    if(!this.state.setRange)
      return {};

    return {
      start: this.state.start,
      end: this.state.end
    }
  }

  expandTimeline = () => {
    window.scrollTo(window.scrollX, window.scrollY + 100);
  }

  contractTimeline = () => {
    window.scrollTo(window.scrollX, 0);
  }

  render() {
    API.log("rendering", "timeline");
    const items = this.props.items
      .concat(this.props.bookedPeers
        .merge(this.props.bookingConflicts)
        .valueSeq().toJS()
      )
      .concat(this.props.eventConflicts.toJS());

    // [selection] must be an empty array to deselect all items
    const trueSelection = (!this.props.selection ? [] : [this.props.selection]);
    const timelineRange = this.getTimelineRange();
    const animateSetting = process.env.ENV === "test" ? { animate: false } : {};
    // const subgroupStackSettings = this.props.items.reduce((settings, item) => {
    //   settings[item.subgroup] = !item.subgroup.includes("overdue");
    //   return settings;
    // }, {});
    return (
      <div>
        {this.props.loading
          ? <GenericSpinner />
          : <Timeline
            options={{
              dataAttributes: "all",
              onMove: this.props.moveResource,
              onRemove: this.props.removeResource,
              editable: {
                add: false,
                remove: (this.props.mode !== "return"),
                updateTime: true,
              },
              moment: date => moment.tz(date, this.props.activeTimezone),
              // hiddenDates: hiddenDates,
              ...timelineRange,
              ...timelineOptions,
              height: this.state.height
            }}
            items={items}
            // groups={[
            //   {
            //     id: "all",
            //     content: "",
            //     subgroupStack: subgroupStackSettings
            //   }
            // ]}
            selection={trueSelection}
            selectHandler={this.props.selectResource}
            customTimes={this.props.timeHandles}
            timechangeHandler={this.props.movingAllResources}
            timechangedHandler={this.props.moveAllResources}
            rangechangedHandler={this.onRangeChanged}
            {...animateSetting}
          />
      }
      {this.state.timelineSizeControlsShowing && <TimelineSizeControls
        onExpand={this.expandTimeline}
        onContract={this.contractTimeline}
        showContract={window.scrollY > 0}
      />}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    mode: state.mode,
    items: getTimelineItems(state),
    selection: state.selectedBookedResource,
    timeHandles: getTimeHandles(state),
    bookedPeers: getBookedPeerTimelineItems(state),
    bookingConflicts: getBookingConflictTimelineItems(state),
    eventConflicts: state.eventConflicts,
    // hiddenDates: getHiddenDates(state.settings.serviceSchedules[0])
    pending: state.pending.get("bookedResources") || state.pending.get("resources"),
    activeTimezone: getActiveTimezone(state)
  }
}

const hasClass = (element, cls) =>
{
  return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
}

const mapDispatchToProps = {
  selectResource: (properties) => {
    return (dispatch, getState) => {
      if(properties.items.length)
      {
        let itemElement;
        const eventTarget = properties.event.target;
        if(hasClass(eventTarget, "vis-item-content"))
          itemElement = eventTarget.parentNode.parentNode;
        else if(hasClass(eventTarget, "vis-drag-center"))
          itemElement = eventTarget.parentNode;
        else
          console.warn("unknown item hierarchy, may fail", eventTarget);
        
        if(itemElement && itemElement.dataset.selectable === "true")
        {
          // We allow selecting only one item, and it is always the first -- [0]
          dispatch(selectTimelineBookingResource(properties.items[0]));
        }
        else
        {
          // Clicking on a non-selectable resource should be the same as tapping
          // on an empty spot - deselect current selection.
          dispatch(selectTimelineBookingResource(null));
        }
      }
      else
      {
        // No items - a deselect event
        dispatch(selectTimelineBookingResource(null));
      }
    }
  },
  // Custom time bar has been released
  moveAllResources: (properties) => {
    return (dispatch, getState) => {
      const state = getState();
      const activeBooking = getActiveBooking(state);

      let handleMoment = moment(properties.time);
      const handleID = properties.id;

      if(handleID === "bookingNewStart")
      {
        dispatch(adjustAllResourceTime(activeBooking.id, handleMoment, null));
      }
      else if(handleID === "bookingNewEnd" || handleID === "activeBookingEnd")
      {
        dispatch(adjustAllResourceTime(activeBooking.id, null, handleMoment));
      }
    }
  },
  // Single resource is being moved
  moveResource: (item, callback) => {
    return (dispatch, getState) => {
      const state = getState();
      const bookedResourceID = item.id;
      const bookingID = getActiveBooking(state);

      const bookedResTime = Object.assign({}, item);
      dispatch(adjustResourceTime(bookedResourceID, moment(bookedResTime.start), moment(bookedResTime.end)));
    }
  },
  // Item delete button has been pressed
  removeResource: (item, callback) => {
    return (dispatch) => {
      const bookedResourceID = item.id;
      callback(item);
      dispatch(removeResourceFromBooking(bookedResourceID));
    }
  },
}

const ResourceTimelineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceTimeline)

export default ResourceTimelineContainer;
