import React from "react";
import classNames from "classnames";
import styles from "./index.scss";

const Logo = ({ environment }) => (
	<div className={styles.logo}>
	  <div className={classNames(styles.envBanner, styles[environment])}>{environment}</div>
	</div>
);

export default Logo;