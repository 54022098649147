import { Record, Map } from "immutable";
import moment from "moment";

const bookingLocalStatusMap = {
  active:             {name: "Active Booking"},
  complete:           {name: "Completed Booking"},
  reserved:           {name: "Reservation"},
  canceled:           {name: "Canceled Booking"},
  noshow:             {name: "Canceled Booking (auto)"},
  tentative:          {name: "New Booking"},
}

export const successfulBookingActions = [
  "CREATE_BOOKING_FULFILLED",
  "ACTIVATE_BOOKING_FULFILLED",
  "UPDATE_BOOKING_FULFILLED",
  "CANCEL_BOOKING_FULFILLED",
  "RETURN_BOOKING_FULFILLED",
  "RETURN_RESOURCE_FULFILLED",
  "RENEW_BOOKING_FULFILLED",
  "RENEW_RESOURCE_FULFILLED"
];


export const BookingRecord = Record({
  id: -1,
  status: "tentative",
  notes: "",
  KeeperId: null,
  ServicedById: null
});

export class Booking extends BookingRecord {
  constructor(obj) {
    super(obj);
  }

  prepareForBookedResources(bookedResources) {
    if(bookedResources.isEmpty() || this.status !== "tentative")
      return this;

    // Determine if reservation
    const status = this.isReservation(bookedResources) ? "reserved" : "active";
    return this.set("status", status).set("id", undefined);
  }

  isReservation(bookedResources) {
    const firstBookedRes = bookedResources.first();
    const earliestStart = firstBookedRes.adjustedStart || firstBookedRes.start;
    const isReservation = earliestStart &&
      earliestStart.diff(moment(), "minutes") >= 5;
    return isReservation;
  }

  getLocalStatus() {
    let localStatus = { status: this.status };
    localStatus.isActive = (this.status === "active");
    localStatus.isPastInactive =  (
      this.status === "complete"
      || this.status === "noshow"
      || this.status === "canceled"
    );
    localStatus.name = bookingLocalStatusMap[this.status].name;
    return localStatus;
  }

  static makeRecord(booking) {
    return new Booking(booking);
  }

  static makeRecords(bookings) {
    let immutableMap = Map();
    for(let key in bookings)
      immutableMap = immutableMap.set(
        bookings[key].id,
        Booking.makeRecord(bookings[key])
      );
    return immutableMap;
  }
}
