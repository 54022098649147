import React from "react";
import ReactDOM from "react-dom";

import { Provider } from 'react-redux'
import configureStore from "./store";

import MantaRouter from './MantaRouter.jsx';

import '../styles/global.scss';
import "tether"; //for bootstrap 4.x <-- prob not needed, tether was replaced by popper?

const mantaStore = configureStore();

ReactDOM.render(
  <Provider store={mantaStore}>
    <MantaRouter />
  </Provider>,
  document.getElementById("app")
);
