import React from "react";
import { connect } from 'react-redux';
import { updateBooking } from '../../../actions';
import { isActiveBookingAdjusted } from '../../../selectors';
import BookingControlButton from './BookingControlButton'

const UpdateBookingButton = ({ user, booking, bookedResources, bookingAdjusted, dispatch }) => (
  <BookingControlButton
    bsStyle="primary" icon="loop2" caption="Update"
    disabled={!bookingAdjusted}
    clickHandler={() => dispatch(updateBooking(booking, bookedResources))}
  />
)

export default connect(
  (state) => {
    return {
      mode: state.mode,
      bookingAdjusted: isActiveBookingAdjusted(state)
    }
  }
)(UpdateBookingButton)
