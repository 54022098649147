import React from 'react';
import API from "../api.js";

import { connect } from 'react-redux';
import { getFilteredResources } from "../selectors.js";
import { addResourceToBooking } from '../actions';

import Icon from './Icon.jsx';
import GenericSpinner from "./GenericSpinner.jsx";
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem }
  from "reactstrap";

// function addResource(id) {console.log("add item " + id)}

const FilteredResources = ({filteredResources, resourcesUnavailable, onSelectResource, emptyPlaceholder, pending, showInactives=false}) => {
  API.log("rendering", "filtered resources");
  return (
  <Card className="filteredResources">
    <CardHeader>Resources</CardHeader>
    <Choose>
      <When condition={pending}>
        <GenericSpinner />
      </When>
      <Otherwise>
        <ListGroup flush>
        {
          filteredResources.size === 0
          ? emptyPlaceholder 
          : filteredResources.toList()
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(res => showInactives || res.status == "active")
            .filter(res => !res.deletedAt)
            .map(fr => (
              <ListGroupItem
                key={fr.id}
                className={"filtered-resource" + (resourcesUnavailable.includes(fr.id) ? " unavailable" : "")}
                onClick={() => onSelectResource(fr)}
                tag="div"
                action
              >
                <div className="filtered-resource-name">
                  <strong>{fr.name}</strong>&nbsp;{fr.barcode ? <small>({fr.barcode})</small> : ''}
                </div>
                <If condition={fr.meta.autoCirculating}>
                  <div className="filtered-resource-icons">
                    <Icon icon="spinner10" />
                  </div>
                </If>
              </ListGroupItem>
          ))
        }
        </ListGroup>
      </Otherwise>
    </Choose>
  </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    filteredResources: getFilteredResources(state),
    resourcesUnavailable: state.resourcesUnavailable,
    pending: state.pending.resources
  }
}

const FilteredResourcesContainer = connect(mapStateToProps)(FilteredResources)

export default FilteredResourcesContainer;
