import React from 'react';
import { push } from 'redux-first-router';
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Label, Input, Form,
  FormGroup, FormFeedback, FormText, Container, Alert } from "reactstrap";
import Icon from "../Icon.jsx";
import SendRecoveryLinkStep from "./SendRecoveryLinkStep";
import ChangePasswordStep from "./ChangePasswordStep";

import classNames from "classnames";
import styles from "./index.scss";

import API from "../../api";

class ResetAccount extends React.Component {

  SSO_AUTH = "sso"
  BASIC_AUTH = "basic"

  defaultActionButtonText = "Log in"

  state = {
    errorMessage: null,
    loginData: {
      email: "",
      password: ""
    },
    emailInvalid: false,

    sendRecoveryLinkDone: Boolean(this.props.token),
    changePasswordDone: false
  }

  componentDidMount()
  {
    const { errorId } = this.props;
    this.setState({
      errorId
    });
  }

  getLoginParams(domain) {
    return API.get("loginParams", {domain});
  }

  setError = (errorMessage) => {
    this.setState({ errorMessage });
  }

  handleSendRecoveryLink = ({ email, recaptchaResponse }) => {
    API.get("initiatePasswordReset", { email, recaptchaResponse })
    .then(result => {
      if (result instanceof Error) {
        this.setError(result.message);
      } else {
        this.setState({ sendRecoveryLinkDone: true });
      }
    })
    .catch(error => this.setError(error));
  }

  handleChangePassword = password => {
    this.setState({changePasswordDone: true});
    API.get("resetPassword", { token: this.props.token, password })
    .then(e => {
      if(e && e.stack && e.message)
        this.setError(e.message);
    })
    .catch(error => {this.setError(error)});
  }

  render() 
  {
    return (
      <Container fluid className="d-flex align-items-center justify-content-center">
        <Card className={styles.resetCard}>
          <CardHeader className={styles.resetCardHeader} />
          <CardBody>
            <SendRecoveryLinkStep
              handleSendRecoveryLink={this.handleSendRecoveryLink}
              done={this.state.sendRecoveryLinkDone}
              setError={this.setError}
            />
            {
              this.props.token &&
              <ChangePasswordStep
                handleChangePassword={this.handleChangePassword}
                done={this.state.changePasswordDone && !this.state.errorMessage}
                setError={this.setError}
              />
            }
            <div className={styles.loginCardFooter}>
              {
                this.state.errorMessage &&
                <FormText color="danger" className={styles.resetInfoBox}>
                  {this.state.errorMessage}
                </FormText>
              }
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default ResetAccount;
