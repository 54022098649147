import React from 'react';

import { Card, CardHeader, CardBody, CardFooter, Input, Label, FormGroup,
  Button, Container } from "reactstrap";
import Icon from "./Icon.jsx";


class Error extends React.Component {

  render() 
  {
    return (
      <Container fluid className="d-flex align-items-center justify-content-center">
        <Card>
          <CardHeader>Error</CardHeader>
          <CardBody>The requested resource could not be found.</CardBody>
        </Card>
      </Container>
    );
  }
}

export default Error;
