import React from "react";
import moment from "moment";
import { Card, ListGroup } from "reactstrap";

import BookingGroup from "./BookingGroup";
import { sortGroupedBookings, getReferenceTime } from "./utils";

const groupBookings = bookings =>
  bookings.reduce((groupedBookings, booking) => {
    let group;
    const referenceTime = getReferenceTime(booking);
    if (referenceTime.isBefore(moment()))
      group = "overdue for pickup";
    else
      group = "upcoming";

    if (!groupedBookings[group]) {
      groupedBookings[group] = [];
    }
    groupedBookings[group].push(booking);
    return groupedBookings;
  }, {});

const ReservationsTab = ({ bookings, activeTimezone, loadBooking }) => {
  if (!bookings.size) {
    return (
      <Card className="nearestBookings" body>
				<div className="panelStatusMessage">
					No upcoming or late reservations
				</div>
			</Card>
    ) 
  }

  const groupedBookings = sortGroupedBookings(groupBookings(bookings));
  return (
    <Card className="nearestBookings">
      <ListGroup flush>
        <BookingGroup
        	group="overdue for pickup"
        	bookings={groupedBookings["overdue for pickup"]}
        	activeTimezone={activeTimezone}
          loadBooking={loadBooking}
        />
        <BookingGroup
        	group="upcoming"
        	bookings={groupedBookings["upcoming"]}
        	activeTimezone={activeTimezone}
          loadBooking={loadBooking}
        />
      </ListGroup>
    </Card>
  )
};

export default ReservationsTab;

/*
	<NearestBooking
		key={booking.id}
	  userName={booking.Keeper.name}
	  time={displayTimeFormatted(booking, activeTimezone)}
	  color={bookingRowColor(displayTime(booking), booking.status)}
	  booking={booking}
	  bookedResources={booking.BookedResources}
	  action={booking.status === "active" ? "return" : "checkout"}
	  onSelectBooking={() => loadBooking(booking)}
	  onRapidAction={() => onRapidAction(booking)}
	/>
*/