// From https://github.com/facebook/react/issues/5465#issuecomment-157888325
const makeCancelable = (promise) => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      result => hasCanceled ? reject({ isCanceled: true }) : resolve(result),
      error => hasCanceled ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};

module.exports = makeCancelable;
