import React from "react";
import { connect } from 'react-redux';
import { cancelBooking } from '../../../actions';
import BookingControlButton from './BookingControlButton'

const CancelBookingButton = ({ user, booking, bookedResources, dispatch }) => (
  <BookingControlButton
    bsStyle="danger" icon="cross"
    disabled={bookedResources.size === 0 || user.id === -1}
    caption="Cancel"
    clickHandler={() => dispatch(cancelBooking(booking, bookedResources))}
  />
)

export default connect()(CancelBookingButton)
