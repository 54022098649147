import { createSelector } from 'reselect'
import { Map, List } from "immutable";
import API from "./api.js";
import { daysOfWeek, getDayName, getRelevantServiceSchedules }
  from "../utils/scheduleUtils";

import Moment from "moment-timezone";
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

const getConfig = (state) => state.config;
const getQuery = (state) => state.query;
const getPending = (state) => state.pending;
const getFilterSource = (state) => state.filterSource;
const getMode = (state) => state.mode;
const getBookings = (state) => state.bookings;
const getResources = (state) => state.resources;
const getBookedResources = (state) => state.bookedResources;
const getDepartments = (state) => state.departments;

const getSelectedBookingID = (state) => state.selectedBooking;
const getSelectedCategoryID = (state) => state.selectedCategory;
const getSelectedDepartmentID = (state) => state.selectedDepartment;
const getSelectedBookedResourceID = (state) => state.selectedBookedResource;

const getBookedPeers = (state) => state.bookedPeers;
const getBookingConflicts = (state) => state.bookingConflicts;
const getSettings = (state) => state.settings;

const queryMatches = (query, name) => query.length >= 3 
  && name.toLowerCase().includes(query.toLowerCase());

const isBarcode = (value, resources) => resources.some(resource => resource.barcode === value);

export const getBarcodeResource = createSelector(
  [ getResources, getSelectedDepartmentID, getQuery ], (resources, selectedDepartment, query) => {

  const inDepartment = (res) =>
    !selectedDepartment || (res.DepartmentId == selectedDepartment);

  if(!isBarcode(query, resources))
    return;

  return resources.filter(inDepartment).find(resource => resource.barcode == query);
})

export const getFilteredResources = createSelector(
  [ getResources, getSelectedCategoryID, getQuery, getFilterSource, getSelectedDepartmentID, getBarcodeResource],
  (resources, selectedID, query, filterSource, selectedDepartment, barcodeResource) => {

    const inDepartment = (res) => 
      !selectedDepartment || (res.DepartmentId == selectedDepartment);

    if(barcodeResource && query.length < 3)
      return List([barcodeResource]);

    const filters = {
      categories: res => res.CategoryId === selectedID,
      omnibox: res => (query.length < 3 || query === res.barcode || queryMatches(query, res.name)) 
    }
    return resources.filter(inDepartment).filter(filters[filterSource]);
  }
)

export const getActiveBooking = createSelector(
  [ getSelectedBookingID, getBookings ],
  (selectedBookingID, bookings) => {
    return bookings.get(selectedBookingID);
  }
)
export const getActiveBookedResources = createSelector(
  [ getSelectedBookingID, getBookedResources ],
  (selectedBookingID, bookedResources) => {
    return bookedResources.filter(bRes => bRes.BookingId === selectedBookingID);
  }
)

export const getAutoCirculatingResources = createSelector(
  [ getResources, getActiveBookedResources ],
  (resources, activeBookedResources) => {
    return activeBookedResources.filter(
      br => resources.getIn([br.ResourceId, "meta"]).autoCirculating
    );
  }
)

// Booking is considered adjusted if any start/end times have been changed, or
// if resources have been added/removed to/from an existing booking
export const isActiveBookingAdjusted = createSelector(
  [ getActiveBookedResources, getMode ],
  (activeBookedResources, mode) => {
    let isAdjusted = false;
    activeBookedResources.forEach(bookedResource => {
      if(bookedResource.adjustedStart
         || bookedResource.adjustedEnd
         || bookedResource.deleted === true
         || (mode === "activate" && bookedResource.id < 0))
      {
        isAdjusted = true;
        return false;
      }
    })
    return isAdjusted;
  }
)

export const getTimelineItems = createSelector(
  [ getBookings, getResources, getBookedResources, getMode, getSelectedDepartmentID ],
  (bookings, resources, bookedResources, mode, selectedDepartment) => {

    //TODO: remove when departments are filtered on the backend
    if(resources.size === 0)
      return [];

    return bookedResources
      .filter(br =>
        resources.get(br.ResourceId).DepartmentId == selectedDepartment
      )
      .map(bookedRes =>
      {
        const booking = bookings.get(bookedRes.BookingId);
        const resource = resources.get(bookedRes.ResourceId);
        return bookedRes.getTimelineItem(resource, booking, mode)
      })
      .valueSeq()
      .flatten()
      .toJS();
  }
)

export const getBookedPeerTimelineItems = createSelector(
  [ getBookedPeers, getResources ],
  (bookedPeers, resources) => {
    return bookedPeers.map(bookedPeer =>
    {
      const booking = bookedPeer.Booking;
      const resourceName = resources.get(bookedPeer.ResourceId).name;
      return bookedPeer.getPeerTimelineItem(resourceName, booking)
    })
  }
)

export const getBookingConflictTimelineItems = createSelector(
  [ getBookingConflicts, getConfig ],
  (conflictingResources, { anonymize }) => {
    return conflictingResources.map(conflictingBookedRes =>
    {
      // const booking = bookings.get(conflictingBookedRes.BookingId);
      // console.log(booking, conflictingBookedRes);
      const booking = conflictingBookedRes.Booking;
      const resourceName = conflictingBookedRes.Resource.name;
      return conflictingBookedRes.getConflictingTimelineItem(resourceName, booking, anonymize)
    })
  }
)


export const getNextBookedResourceID = createSelector(
  [ getActiveBookedResources ],
  (activeBookedResources) => {
    const maxID = activeBookedResources.reduce((nextID, bRes) =>
    {
      return bRes.id < nextID ? bRes.id : nextID;
    }, 0);
    return maxID - 1;
  }
)

// Deprecated, only kept to make sure it isn't called anywhere
export const getActiveBookingID = createSelector(
  [ getSelectedBookingID ],
  (selectedBookingID) => {
    return selectedBookingID;
  }
)

// getEarliestStart and getLatestEnd return earliest and latest times of all
// possibly set times - end/start, bookedEnd/Start, adjustedEnd/Start
// null if no Resources in booking
export const getEarliestStart = createSelector(
  [ getActiveBookedResources ],
  (bookedResources) => {
    if(bookedResources.isEmpty())
      return null;

    const firstBookedRes = bookedResources.first();
    return firstBookedRes.adjustedStart || firstBookedRes.start;
  }
)
export const getLatestEnd = createSelector(
  [ getActiveBookedResources ],
  (bookedResources) => {
    if(bookedResources.isEmpty())
      return null;

    return bookedResources.reduce((latestTime, bookedRes) => {
      const trueTime = bookedRes.adjustedEnd || bookedRes.end;
      if(latestTime === null)
        return trueTime;
      else
        return (latestTime.isBefore(trueTime) ? trueTime : latestTime);
    }, null);
  }
)

export const getActiveServiceSchedule = createSelector(
  [ getSettings, getSelectedDepartmentID ],
  (settings, selectedDepartment) => {
    return settings.serviceSchedules.find(
      serviceSchedule => serviceSchedule.type === "regular" &&
        serviceSchedule.DepartmentId === selectedDepartment);
  }
)

export const getActiveServiceScheduleAndHolidays = createSelector(
  [ getSettings, getSelectedDepartmentID ],
  (settings, selectedDepartment) => {
    return settings.serviceSchedules.filter(serviceSchedule =>
      serviceSchedule.DepartmentId === selectedDepartment);
  }
)


export const getActiveTimezone = createSelector(
  [ getDepartments, getSelectedDepartmentID ],
  (departments, selectedDepartmentID) => {
    const selectedDepartment = departments.get(selectedDepartmentID);
    const defaultTimezone = "America/New_York";
    return selectedDepartment
      ? selectedDepartment.timezone
      : defaultTimezone;
  }
)

export const getNearbyClosedTimes = createSelector(
  [ getActiveServiceScheduleAndHolidays, getActiveTimezone, getEarliestStart, getLatestEnd ],
  (schedules, activeTimezone, earliestStart, latestEnd) => {
    if(!schedules)
      return [];

    let nearbyClosedTimes = [];
    const bookingRange = moment.range(
      moment(earliestStart).subtract(3, "days"),
      moment(latestEnd).add(3, "days")
    );
    let bookingRangeDays = Array.from(bookingRange.by("day"));

    // Prevent overloading timeline
    if(bookingRangeDays.length > 14)
      bookingRangeDays = bookingRangeDays.slice(0, 6).concat(bookingRangeDays.slice(-6));

    bookingRangeDays.forEach(day => {
      const { regular, holiday } = getRelevantServiceSchedules(day, schedules);
      const closedTimesToShow = holiday ? holiday.closed : regular.closed;
      const dayName = getDayName(day);

      if (!closedTimesToShow[dayName])
        return;

      closedTimesToShow[dayName].forEach(closedRange => {
        const dateStr = day.format("YYYY-MM-DD");
        nearbyClosedTimes = [...nearbyClosedTimes, {
          start: moment.tz(`${dateStr} ${closedRange.from}`, activeTimezone),
          end: moment.tz(`${dateStr} ${closedRange.to}`, activeTimezone),
          type: "background",
          content: holiday ? holiday.description : "",
          className: holiday ? "holiday" : "closed"
        }];
      });
    });

    return nearbyClosedTimes;
  }
)

export const getClosingTimeToday = createSelector(
  [ getActiveServiceSchedule, getActiveTimezone ],
  (schedule, activeTimezone) => {
    if(!schedule)
      return moment().endOf("day");

    const todaysName = getDayName(moment.tz(moment(), activeTimezone)).toLowerCase()
    const closedToday = schedule.closed[todaysName];
    const lastClosedPeriod = closedToday[closedToday.length -1];
    let closingTimeToday;
    if(lastClosedPeriod.to !== "23:59:59")
      closingTimeToday = "23:59:59";
    else
      closingTimeToday = lastClosedPeriod.from;
    return moment.tz(`${moment().format("YYYY-MM-DD")} ${closingTimeToday}`, activeTimezone);
  }
)

// A time handle is used to adjust start/end times of all booking items at once.
// For items w/ different end times, it should equal the latest one.
export const getTimeHandles = createSelector(
  [getActiveBooking, getActiveBookedResources, getEarliestStart, getLatestEnd, getMode],
  (activeBooking, activeBookedResources, earliestAdjustedStart, latestAdjustedEnd, mode) => {

  // Show time handles only if there are resources in the active booking
  let timeHandles = {};
  if(activeBooking && !activeBookedResources.isEmpty())
  {
    // Retrieve latest unadjusted end (bookedEnd or end fields)
    const latestEnd = activeBookedResources.reduce((latest, bRes) => {
      return bRes.end.isAfter(latest) ? bRes.end : latest;
    }, moment(0));

    if(mode === "activate")
    {
      // Start time is always the same across resources
      const earliestStart = activeBookedResources.first().bookedStart;
      timeHandles.bookingOriginalStart = earliestStart;
      timeHandles.bookingNewStart = earliestAdjustedStart;
      timeHandles.bookingOriginalEnd = latestEnd;
      timeHandles.bookingNewEnd = latestAdjustedEnd;
    }
    else if(mode === "return")
    {
      const bookingLocalStatus = activeBooking.getLocalStatus();
      const bookedResourceLocalStatuses = activeBookedResources.map(br => br.getLocalStatus(bookingLocalStatus, mode).status);
      const hasOverdueResources = bookedResourceLocalStatuses.some(status => status === "overdue");

      let bookingNewEnd = latestAdjustedEnd;
      if(hasOverdueResources && latestAdjustedEnd.isBefore(moment()))
      {
        bookingNewEnd = moment();
      }

      timeHandles.bookingOriginalEnd = latestEnd;
      timeHandles.bookingNewEnd = bookingNewEnd;
    }
    else timeHandles.activeBookingEnd = latestAdjustedEnd;
  }
  return timeHandles;
});

// getBookingBounds returns object { start, end }, where each field is:
//  * null if no Resources are found in booking
//  * moment if respective time matches for all resources (always so for start)
//  * false if no items have different times
export const getBookingBounds = createSelector(
  [ getActiveBookedResources ],
  (activeBookedResources) => {
    if(activeBookedResources.isEmpty())
      return {start: null, end: null};
    else 
    {
      const firstBookedRes = activeBookedResources.first();
      const start = firstBookedRes.adjustedStart || firstBookedRes.start;

      const end = activeBookedResources.reduce((singleTime, bookedRes) => {
        const bookedResTime = bookedRes.adjustedEnd || bookedRes.end;
        if(singleTime === null)
          return bookedResTime;
        else if(singleTime === false)
          return false;
        // Booking offset threshold, remove when we store current time in state
        else if(Math.abs(singleTime.diff(bookedResTime, "minutes")) <= 1)
          return singleTime;
        else
          return false;
      }, null);
      
      return {start, end};
    }
  }
);

export const withoutDeleted = (items) => items.filter(item => !item.deletedAt)
