import { Notification } from "../records.js";
import moment from "moment";

/*
There are three places to setup notifs:
1. For simple messages directly linked to a dispatched action, add them to
  `notificationMap` below
2. For messages with some logic that only need data passed along with dispatched
  action, handle them in `generateNotification` below
3. For messages with more complex logic that need to make API calls or collect
  data from calls up the promise chain, dispatch a NOTIFY_* action when needed,
  and handle them in `generateNotification`
*/

const notificationMap = {
  NOTIFY_BOOKING_ACTIVE: {
    message: "Booking is now active. Please hand the items to the user.",
    color: "success"
  },
  ACTIVATE_BOOKING_FULFILLED: {
    message: "Booking is now active. Please hand the items to the user.",
    color: "success"
  },
  RENEW_BOOKING_FULFILLED: {
    message: "The booking has been renewed successfully.",
    color: "success"
  },
  RENEW_RESOURCE_FULFILLED: {
    message: "The resource has been renewed successfully.",
    color: "success"
  },
  UPDATE_BOOKING_FULFILLED: {
    message: "The booking has been updated successfully.",
    color: "success"
  },
  CANCEL_BOOKING_FULFILLED: {
    message: "The booking has been canceled successfully.",
    color: "success"
  },
  UPDATE_SERVICE_SCHEDULE_FULFILLED: {
    message: "The service schedule has been updated successfully.",
    color: "success"
  },
  SERVER_ERROR: {
    message: "An error occurred on the server",
    color: "danger"
  }
}

const getLateBookedResources = (bookedResources) => {
  return bookedResources.reduce((late, bRes) => {
    const diff = moment().diff(bRes.bookedEnd, "minutes");
    const resWasReturnedNow = moment().diff(bRes.end, "minutes") <= 1;
    if(diff > 0 && resWasReturnedNow)
    {
      // lateBy is a user-readable string
      late.push({
        bookedResource: bRes,
        lateBy: moment(bRes.bookedEnd).toNow(true)
      })
    }
    return late;
  }, []);
}

const getLateMessages = (lateResourceObjs) => {
  return lateResourceObjs.map(obj =>
    `${obj.bookedResource.id} was late by ${obj.lateBy}.`
  ).join(" ");
}

const generateNotification = (action) =>
{
  let notifSettings;
  switch(action.type)
  {
    case 'RETURN_RESOURCE_FULFILLED':
    {
      const { bookedResources } = action.payload;
      const notReturned = bookedResources.filter(bRes =>
        bRes.status !== "returned"
      );
      const lateOnes = getLateBookedResources(bookedResources);

      let mainMessage = "", returnMessage = "", lateMessage = "";
      if(notReturned.length > 0)
      {
        mainMessage = "Resource has been returned.";
        const plural = notReturned.length > 1;
        returnMessage = `${notReturned.length} resource${plural ? "s" : ""} ${plural ? "are" : "is"} still checked out.`;
      }
      else mainMessage = "All resources have been returned";

      if(lateOnes.length > 0)
        lateMessage = getLateMessages(lateOnes);

      notifSettings = {
        message: `${mainMessage} ${returnMessage} ${lateMessage}`,
        color: notReturned.length === 0 ? "success" : "info"
      };
    }
    break;

    case 'RETURN_BOOKING_FULFILLED':
    {
      const lateOnes = getLateBookedResources(action.payload.bookedResources);
      const lateMsg = lateOnes.length > 0 ? getLateMessages(lateOnes) : "";;
      notifSettings = {
        message: "All resources have been returned successfully." + lateMsg,
        color: lateOnes.length > 0 ? "warning" : "success"
      };
    }
    break;

    case 'NOTIFY_BOOKING_RESERVED':
    {
      const date = moment(action.bookedResources[0].bookedStart);
      notifSettings = {
        message: `Booking has been reserved, and can be picked up ${date.fromNow()}. An e-mail reminder will be sent 30 minutes before.`,
        color: "success"
      };
    }
    break;

    default:
      notifSettings = notificationMap[action.type];
  }

  if(notifSettings)
    return new Notification(notifSettings);
  else
    return false;
}

export default generateNotification;
