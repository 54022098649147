import React from "react";
import moment from "moment";
import { Card, CardBody, ListGroup } from "reactstrap";

import NearestBooking from "./NearestBooking";
import Separator from "./Separator";
import { displayTime, displayTimeFormatted, bookingRowColor } from "./utils";

const ReservationsTab = ({ bookings, activeTimezone, loadBooking, onRapidAction }) => {
	if (!bookings.size) {
		return (
			<Card className="nearestBookings" body>
				<div className="panelStatusMessage">No upcoming or late reservations</div>
			</Card>
		)	
	}

	const extras = [
	  (bookings.some(nb => displayTime(nb) >= moment())) && "upcoming",
	  (bookings.some(nb => displayTime(nb) < moment())) && "overdue"
	].filter(x => x);

	return (
		<Card className="nearestBookings">
		  <ListGroup flush>
		    {
		    	bookings
		    	.concat(extras)
		    	.sort((a, b) => displayTime(a) > displayTime(b) ? 1 : -1)
		      .map(booking => 
		        typeof booking === "string"
		        	? <Separator key={booking} text={booking} />
		        	: <NearestBooking
		        			key={booking.id}
			            userName={booking.Keeper.name}
			            time={displayTimeFormatted(booking, activeTimezone)}
			            color={bookingRowColor(displayTime(booking), booking.status)}
			            bookedResources={booking.BookedResources}
			            action={booking.status === "active" ? "return" : "checkout"}
			            onSelectBooking={() => loadBooking(booking)}
			            onRapidAction={() => onRapidAction(booking)}
			          />
		      )
		    }
		  </ListGroup>
		</Card>
	)
};

export default ReservationsTab;
