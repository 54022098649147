import { Map, Record } from "immutable";

export const CategoryRecord = Record({
  id: -1, 
  name: "", 
  ParentId: -1,
  open: false,
  collapsed: false,
  Categories: [],
  DepartmentId: null
});

export class Category extends CategoryRecord {
  constructor(obj) {
    super(obj);
  }

  static makeRecord(rawCat, allCats=[]) {
    return new Category({
      ...rawCat,
      Categories: Category.makeRecords(
        allCats.filter(cat => cat.ParentId === rawCat.id),
        allCats
      )
    });
  }

  static makeRecords(rawCats, allCats=[]) {
    if(allCats.length === 0)
      allCats = rawCats;

    let immutableMap = Map();
    for(let i = 0; i < rawCats.length; i++)
    {
      immutableMap = immutableMap.set(
        rawCats[i].id,
        Category.makeRecord(rawCats[i], allCats)
      );
    }
    return immutableMap;
  }

  static newCategory() {
    return {...new Category().toJS(), id: undefined};
  }
}
