import { Map } from "immutable";

import { Category } from "../records.js";

export const categories = (state=Map({}), action) => {
  switch(action.type) {
    case 'FETCH_CATEGORIES_FULFILLED':
      const categories = action.payload;
      return Category.makeRecords(categories);
    case 'TOGGLE_PARENT_CATEGORY':
      return state.update(action.catID, cat => cat.set("open", !cat.open));
    default:
      return state;
  }
}

export const selectedCategory = (state=-1, action) => {
  switch(action.type) {
    case 'SELECT_CATEGORY':
      return action.catID;
    case 'ENTER_QUERY':
      return -1;
    default:
      return state;
  }
}
