import moment from "moment";

export const displayTime = listItem => {
  if (listItem === "upcoming") return moment();
  if (listItem === "overdue") return moment().subtract(999, "d");
  const timeFields = { active: "bookedEnd", reserved: "bookedStart" };
  const timeField = timeFields[listItem.status] || "bookedEnd";
  const timeValue = listItem.BookedResources[0][timeField];
  const bookingTime = moment(timeValue);
  return bookingTime;
}

export const displayTimeFormatted = (booking, activeTimezone) => {
  const time = displayTime(booking);
  if(moment().isSame(time, "day"))
    return moment.tz(time, activeTimezone).format("h:mm a");
  else 
    return moment.tz(time, activeTimezone).format("ddd h:mm a");
}

export const generateBookingLink = booking => 
  `/app/${booking.status == "reserved" ? "activate" : "return"}/
${booking.KeeperId}/${booking.id}/-1`;

export const generateBookingAction = booking => ({
  type: "APP_BOOKING",
  payload: {
    mode: booking.status == "reserved" ? "activate" : "return",
    userId: booking.KeeperId,
    bookingId: booking.id,
    bookedResourceId: -1
  }
});

export const bookingRowColor = (time, status) => {
  const late = moment() > time;
  if(status === "reserved" && late)
    return "warning";
  else if(status === "active" && late)
    return "danger";
  return "light";
}
