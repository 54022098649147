import React from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import { createBooking } from '../../../actions';
import { getEarliestStart } from '../../../selectors';
import BookingControlButton from './BookingControlButton'

const ConfirmBookingButton = ({ user, booking, bookedResources, dispatch }) => (
  <BookingControlButton
    bsStyle="primary" icon="checkmark" caption="Confirm"
    disabled={bookedResources.isEmpty() || user.id === -1}
    clickHandler={() => 
      dispatch(createBooking(booking, bookedResources))}
  />
)

const ConfirmBookingButtonContainer = connect()(ConfirmBookingButton);

export default ConfirmBookingButtonContainer;
