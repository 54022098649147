import React from 'react';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';

import { getActiveTimezone } from "../../selectors";
import moment from "moment-timezone";

import { Card, CardHeader, CardBody, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter }
  from "reactstrap";

import CategorySelect from "./CategorySelect.jsx";

const dateTimeFormat = 'MMM Do YYYY, h:mm a';

class MoveResourcesModal extends React.Component {
  state = { category: null };
  setCategory = (event, category) => {
    this.setState({ category: event.target.value });
  }
  render() {
    const { moveResourcesModalShowing, toggleMoveResourcesModal,
      moveAllResourcesInCategoryTo, categories, selfCategory } = this.props;
    return (
      <Modal isOpen={moveResourcesModalShowing} toggle={toggleMoveResourcesModal}>
        <ModalHeader>Move resources to</ModalHeader>
        <ModalBody>
          <CategorySelect label="Category" onChange={this.setCategory}
            disableParents categories={categories} disabledCategories={[selfCategory]} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={!this.state.category}
            onClick={() => {
              toggleMoveResourcesModal();
              moveAllResourcesInCategoryTo(this.state.category);
            }}>Move</Button>&nbsp;
          <Button color="secondary" onClick={toggleMoveResourcesModal}>Cancel</Button>
        </ModalFooter>
      </Modal>);
  }
};

class CategoryResources extends React.Component
{
  state = {
    moveResourcesModalShowing: false
  }

  toggleMoveResourcesModal = () => {
    this.setState({ moveResourcesModalShowing: !this.state.moveResourcesModalShowing });
  }

  render()
  {
    let { category, categoryResources, categories, 
      moveAllResourcesInCategoryTo, deleteAllResourcesInCategory } = this.props;
    if(!category.id)
      return null;

    return (<Card>
      <CardHeader>Resources in {category.name}</CardHeader>
      <CardBody>
      {
        categoryResources && categoryResources.length
        ? <div>
          <span><Button color="info" onClick={this.toggleMoveResourcesModal}>
            Move resources to another category</Button></span>
          &nbsp;
          <span><Button color="danger" onClick={deleteAllResourcesInCategory}>
            Delete resources</Button></span>
          <br/><br/>
          <MoveResourcesModal
            toggleMoveResourcesModal={this.toggleMoveResourcesModal}
            moveResourcesModalShowing={this.state.moveResourcesModalShowing}
            moveAllResourcesInCategoryTo={moveAllResourcesInCategoryTo}
            categories={categories}
            selfCategory={category.id}
          />
          <Table>
              <thead>
                <tr>
                  <th>Resource</th>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
              { categoryResources.map(categoryResource => (
                    <tr key={categoryResource.id}>
                      <td>{categoryResource.name}</td>
                      <td>{categoryResource.type}</td>
                      <td>{categoryResource.description}</td>
                      <td>{categoryResource.notes}</td>
                    </tr>)
                  )
              }
              </tbody>
          </Table>
        </div>
        : <i>No resources in {category.name}</i> 
      }
      </CardBody>
    </Card>)
  }
}

const mapStateToProps = (state) => {
  return {
    DepartmentId: state.selectedDepartment,
    activeTimezone: getActiveTimezone(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

const CategoryResourcesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryResources)


export default CategoryResourcesContainer;
