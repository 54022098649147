import React from "react";
import { Label } from "reactstrap";

import styles from "./step.scss";
import classNames from "classnames";

const Step = ({ number, text = null, children = null, done = false }) => {
    if (text !== null && children !== null) {
        console.warn("Both text and children were passed to Step, only showing children");
    }

    return (
        <div className="d-flex align-items-center mt-2 mb-3">
            <span className={classNames(styles.resetStepNumber, { [styles.done]: done })}>{number}</span>
            {
                children
                ? children
                : <Label className={classNames(styles.resetStepText, { [styles.done]: done })}>{text}</Label>
            }
        </div>
    );
}

export default Step;