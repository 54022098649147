import React from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import classNames from "classnames";

class ValidatingDateTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      isValid: this.isValidValue(props.value)
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value,
      isValid: this.isValidValue(nextProps.value)
    });
  }

  isValidValue(value) {
    const { additionalValidValues = [] } = this.props;
    return additionalValidValues.includes(value) || this.isValidDate(value);
  }

  isValidDate(date) {
    return Boolean(date && date.isValid && date.isValid());
  }

  validateAndChangeDate(date) {
    if (this.isValidDate(date)) {
      // react-datetime returns dates in UTC (and using displayTimezone breaks
      // keyboard entry), so we convert to the active timezone manually.
      const dateInActiveTimezone = date.clone();
      dateInActiveTimezone.tz(this.props.timezone, true);
      this.props.onChange(dateInActiveTimezone);
      this.setState({
        isValid: true
      });
    } else {
      this.setState({
        isValid: false
      });
    }
  }

  render() {
    const { className = "", timezone } = this.props;
    const { value } = this.state;
    return (
      <Datetime
        value={value}
        onChange={date => this.validateAndChangeDate(date)}
        inputProps={{
          className: classNames(
            "form-control", this.props.className,
            { "is-invalid": !this.state.isValid }
          )
        }}
      />
    );
  }
}

export default ValidatingDateTime;
