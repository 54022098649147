import { combineReducers } from "redux";
import randomstring from 'randomstring';
import { Map, List, fromJS } from "immutable";
import moment from "moment";

import { getClosingTimeToday } from "../selectors";

import { Booking, successfulBookingActions } from "../records.js";

// The placeholder booking is always present for when a new, tentative booking
// is created
const placeholderBooking = Booking.makeRecord();
const placeholderBookingMap = Map().set(-1, placeholderBooking);

export const bookings = (state=placeholderBookingMap, action) => {
  if(successfulBookingActions.includes(action.type))
  {
    const { booking } = action.payload;
    // use plural `makeRecords` to generate mergeable map, not just record
    return state.merge(Booking.makeRecords([booking]));
  }

  switch(action.type)
  {
    case 'FETCH_USER_FULFILLED':
      const user = action.payload;
      return state.setIn([-1, "KeeperId"], user.id);

    case 'SET_OPERATOR':
      return state.setIn([-1, "ServicedById"], action.user.id);

    case 'INITIALIZE_PLACEHOLDER_BOOKING':
      return state.set(-1, Booking.makeRecord(action.booking));

    case 'FETCH_BOOKINGS_FULFILLED':
      const bookings = action.payload;
      const adjustedPlaceholder = state.get(-1);
      return Booking.makeRecords(bookings).set(-1, adjustedPlaceholder);

    case 'DISCARD_BOOKINGS': {
      const adjustedPlaceholder = state.get(-1);
      return placeholderBookingMap.set(-1, adjustedPlaceholder);
    }

    case 'SET_BOOKING_NOTES':
      return state.setIn([action.bookingID, "notes"], action.notes);

    default:
      return state;
  }
}


const defaultSelectedBooking = -1;
export const selectedBooking = (state=defaultSelectedBooking, action) => {
  if(successfulBookingActions.includes(action.type))
    return defaultSelectedBooking;

  switch(action.type) {
    case 'SELECT_BOOKING_RESOURCE':
      return (action.bookingID ? action.bookingID : defaultSelectedBooking);
    case 'SET_MODE':
    case 'DISCARD_ACTIVE_BOOKING':
    case 'DISCARD_BOOKINGS':
      return defaultSelectedBooking;
    default:
      return state;
  }
}

export const nearestBookings = (state=List(), action) => {
  switch(action.type)
  {
    case "FETCH_NEAREST_BOOKINGS_FULFILLED":
      return List(Array.isArray(action.payload) ? action.payload : []);
    default:
      return state;
  }
}

