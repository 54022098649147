import React from 'react';
import { connect } from 'react-redux';

import { Category } from '../../records.js';
import CardOverlayLoader from "../CardOverlayLoader.jsx";
import CategorySelect from "./CategorySelect.jsx";
import FormElement from "./FormElement.jsx";
import Icon from "../Icon.jsx";

import { Card, CardHeader, CardBody, Input, Label, FormGroup, Button, ButtonGroup}
  from "reactstrap";

class CategoryEditor extends React.Component
{
  constructor(props)
  {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  state = {
    category: this.props.category || Category.newCategory(),
  }

  componentWillReceiveProps(nextProps)
  {
    if(nextProps.category)
      this.setState({ category: {
        ...nextProps.category,
        DepartmentId: nextProps.DepartmentId
      }});
  }


  getElementValue = (element) => {
    if(element.type === "checkbox" || element.type === "radio")
      return element.checked;
    else if(element.type === "number")
      return parseInt(element.value);
    else return element.value;
  }

  handleFieldChange(event)
  {
    this.setState({
      category: {
        ...this.state.category, 
        [event.target.name]: this.getElementValue(event.target)
      }
    });
  }

  render()
  {
    const { category } = this.state;
    const headerText = category.id ? `${category.name}` : " Creating a new category";
    return (
      <Card className="scroll-card">
        <CardHeader className="d-flex">
          {headerText}
          <ButtonGroup className="ml-auto">
            <Button size="sm" onClick={this.props.clearCategory}>
              <Icon icon="redo2" />&nbsp;Reset
            </Button>
            { category.id &&
              <Button onClick={() => this.props.deleteCategory(category)} size="sm" color="danger">
                <Icon icon="cross" />&nbsp;Delete
              </Button>
            }
            <Button size="sm" color="success"
              onClick={() => this.props.saveCategory(category)}>
              <Icon icon="checkmark" />&nbsp;Save
            </Button>
          </ButtonGroup>
        </CardHeader>
        {this.props.pending && <CardOverlayLoader />}
        <CardBody>
          <FormElement name="name" label="Name" type="text"
            value={category.name} onChange={this.handleFieldChange} />
          <CategorySelect name="ParentId" label="Parent Category" categories={this.props.categories}
            value={category.ParentId || -1} onChange={this.handleFieldChange} />
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    DepartmentId: state.selectedDepartment
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

const CategoryEditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryEditor)

export default CategoryEditorContainer;
