import React from 'react';
import { connect } from 'react-redux';
import { redirect } from "redux-first-router";

import Moment from "moment-timezone";
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardHeader, CardBody, ListGroup, ListGroupItem, Container, Nav, NavItem, NavLink, TabContent, TabPane }
  from "reactstrap";
import classNames from "classnames";

import API from "../../api.js";
import { getActiveTimezone } from "../../selectors.js";
import { generateBookingAction, flatToNested } from "./utils";

import Icon from "../Icon.jsx";
import SummaryTab from "./SummaryTab";
import BookingsTab from "./BookingsTab";
import ReservationsTab from "./ReservationsTab";
import GenericSpinner from "../GenericSpinner.jsx";
import { RapidReturn, RapidActivate } from "../RapidActions.jsx";

import "./index.global.scss";

class OwnBookings extends React.Component {
  state = {
    activeTab: "summary"
  }

  onChangeActiveTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render()
  {
    API.log("rendering", "nearest bookings");

    const { bookings: allBookings, activeTimezone, pending, loadBooking } = this.props;
    const { rapidReturnBooking, rapidActivateBooking } = this.state;

    const bookings = allBookings.filter(booking => booking.status !== "reserved");
    const reservations = allBookings.filter(booking => booking.status === "reserved");

    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: this.state.activeTab === "summary" })}
              onClick={() => this.onChangeActiveTab("summary")}
            >
              Summary
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: this.state.activeTab === "bookings" })}
              onClick={() => this.onChangeActiveTab("bookings")}
            >
              Bookings <small className="count">{bookings.size}</small>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: this.state.activeTab === "reservations" })}
              onClick={() => this.onChangeActiveTab("reservations")}
            >
              Reservations <small className="count">{reservations.size}</small>
            </NavLink>
          </NavItem>
          {/*<NavItem className="ml-auto">
            <NavLink><Icon icon="cog" color="dark" height={22} /></NavLink>
          </NavItem>*/}
        </Nav>
        <TabContent className="pt-0" activeTab={this.state.activeTab}>
          <TabPane tabId="summary">
          {
            pending
              ? <GenericSpinner />
              : <SummaryTab
                  bookings={bookings}
                  reservations={reservations}
                />
          }
          </TabPane>
          <TabPane tabId="bookings">
          {
            pending
              ? <GenericSpinner />
              : <BookingsTab
                  bookings={bookings}
                  activeTimezone={activeTimezone}
                  loadBooking={loadBooking}
                />
          }
          </TabPane>
          <TabPane tabId="reservations">
          {
            pending
              ? <GenericSpinner />
              : <ReservationsTab
                  bookings={reservations}
                  activeTimezone={activeTimezone}
                  loadBooking={loadBooking}
                />
          }
          </TabPane>
        </TabContent>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTimezone: getActiveTimezone(state),
    pending: state.pending.userWithBookings || state.pending.bookings || state.pending.bookedResources || state.pending.resources || state.pending.user,
    bookings: flatToNested(state.bookings, state.resources, state.bookedResources, state.user, state.selectedDepartment)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
     loadBooking: booking => dispatch(generateBookingAction(booking))
  }
}

const OwnBookingsContainer = connect(mapStateToProps, mapDispatchToProps)(OwnBookings)

export default OwnBookingsContainer;
