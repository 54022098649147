import React from "react";
import { connect } from "react-redux";
import { push } from 'redux-first-router';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup,
  ListGroupItem } from "reactstrap";

import API from "../../api";
import GenericSpinner from "../GenericSpinner";

import { setDepartment, discardBookings, fetchNearestBookings }
  from "../../actions.js";
import styles from "./index.scss";

class DepartmentSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: !this.isDepartmentValid(props.selectedDepartmentId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedDepartmentId !== this.props.selectedDepartmentId) {
      this.setState({
        modalOpen: !this.isDepartmentValid(nextProps.selectedDepartmentId)
      });
    }
  }

  isDepartmentValid(departmentId) {
    return departmentId !== -1;
  }

  setDepartmentAndCloseModal = departmentId => {
    this.props.setDepartment(departmentId);
    this.setState({
      modalOpen: false
    });
  };

  getFilteredDepartments = () => this.props.departments
    .filter(department => department.config.enableUserPortal);

  renderCardBody() {
    const { pending } = this.props;
    const departments = this.getFilteredDepartments();
    if (pending) {
      return (
        <GenericSpinner />
      );
    } else if (departments.size > 0) {
      return (
        <ListGroup flush>
        {
          departments
            .valueSeq()
            .map(department =>
              <ListGroupItem
                action
                key={department.id}
                className={styles.departmentListItem}
                onClick={() => this.setDepartmentAndCloseModal(department.id)}
              >
                {department.name}
              </ListGroupItem>
            )
        }
        </ListGroup>
      );
    } else {
      return (
        <div className={styles.unavailableMessage}>
          <h5>No departments available.</h5>
          <p>This is likely because none of the departments you have access to have online reservations enabled. To make bookings, please visit the checkout counter in person.</p>
        </div>
      ); 
    }
  }

  render() {
    const { modalOpen } = this.state;
    const { departments, setDepartment, pending } = this.props;
    return (
      <Modal isOpen={modalOpen} centered>
        <ModalHeader>Select department</ModalHeader>
        <ModalBody className={styles.modalBody}>
        {
          this.renderCardBody()
        }
        </ModalBody>
        <ModalFooter>
        {
          this.getFilteredDepartments().size === 0 &&
          !pending && (
            <Button onClick={() => push("/logout")}>
              Logout
            </Button>
          )
        }
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  departments: state.departments,
  selectedDepartmentId: state.selectedDepartment,
  pending: state.pending.departments
});

const mapDispatchToProps = dispatch => ({
  setDepartment: (department) => {
    dispatch(discardBookings());
    dispatch(setDepartment(department));
    dispatch(fetchNearestBookings());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentSelector);
