import { redirect } from 'redux-first-router';
import API from "./api.js";

const protectedPath = pathName => ({
  path: pathName,
  thunk: (dispatch, getState) => {
    const isAuthenticated = API.isAuthenticated();
    if (!isAuthenticated) {
      console.log()
      dispatch(redirect({ type: "LOGIN" }));
    }
  }
});

const routesMap = { 
  FRONT: {
    path: '/',
    thunk: (dispatch, getState) => {
      const isAuthenticated = API.isAuthenticated();
      if (!isAuthenticated) {
        dispatch(redirect({ type: "LOGIN" }));
      } else {
        dispatch(redirect({ type: "APP" }));
      }
    }
  },
  LOGIN: '/login',
  LOGIN_FORWARDED: '/login/:payload',
  LOGOUT: {
    path: "/logout",
    thunk: (dispatch, getState) => {
      API.logout();
      dispatch(redirect({ type: "LOGIN" }));
    }
  },
  RESET_ACCOUNT: '/account/reset/:token?',
  APP: protectedPath("/app"),
  APP_BOOKING: protectedPath("/app/:mode/:userId/:bookingId/:bookedResourceId"),
  MANAGE: protectedPath("/manage"),
  MANAGE_MODEL: protectedPath("/manage/:model"),
  MANAGE_MODEL_ID: protectedPath("/manage/:model/:id"),
  ERROR: '/error/:errorId'
}

export default routesMap;
