import { Record } from "immutable";

// Pending state
export const PendingState = Record({
  // true when saving a single booking
  booking: false,
  // true when fetching multiple bookings of a user
  bookings: false,
  user: false,
  users: false,
  userWithBookings: false,
  categories: false,
  departments: false,
  resources: false,
  bookedResources: false,
  application: false
})

//TODO: store on the client/department
export const roleConfigs = {
  admin: {
    forceSelfAsUser: false,
    modes: ["home", "create", "activate", "return"],
    omniboxSearches: ["users", "resources", "bookedResources"],
    enableNearestBookings: true,
    hideDisabledDepartments: false,
    allowCheckout: true,
    reservationOffset: 10, //min
    allowManage: true,
    anonymize: false,
    showTour: false,
    showOwnBookings: false,
    showBookingActors: true
  },
  staff: {
    forceSelfAsUser: false,
    modes: ["home", "create", "activate", "return"],
    omniboxSearches: ["users", "resources", "bookedResources"],
    enableNearestBookings: true,
    hideDisabledDepartments: false,
    allowCheckout: true,
    reservationOffset: 10, //min
    allowManage: true,
    anonymize: false,
    showTour: false,
    showOwnBookings: false,
    showBookingActors: true
  },
  patron: {
    forceSelfAsUser: true,
    modes: ["create", "activate"],
    omniboxSearches: ["resources"],
    enableNearestBookings: false,
    hideDisabledDepartments: true,
    allowCheckout: false,
    reservationOffset: 10, //min
    allowManage: false,
    anonymize: true,
    showTour: true,
    showOwnBookings: true,
    showBookingActors: false
  }
}

export const tourSteps =  [
  {
    target: '.controlPanel',
    content: "Welcome to Manta! This is where you'll see the status of your booking.",
    placement: 'right',
  },
  {
    target: '.omnibox',
    content: "You can search for resources using the Omnibox. They will appear in the browser on the right.",
    placement: "bottom",
  },
  {
    target: '.resourceBrowser',
    content: 'You can also browse through categories and pick items here directly.',
    placement: 'left',
  },
  {
    target: '.resourceTimeline',
    content: 'View and adjust your booking on the timeline - drag it to pan and scroll to change the scale.',
    placement: 'top',
  },
  {
    target: '.controls',
    content: "Confirm the booking when you're ready, or discard it to start over.",
    placement: "bottom",
  },
  {
    target: '.activate',
    content: "You can make changes to existing bookings by clicking this button and selecting them on the timeline.",
    placement: 'bottom',
  },
  {
    target: ".operatorTools",
    content: "When you're done, don't forget to log out! To access this tour again, just select 'Show Tour' from this user menu.",
    placement: 'bottom',
  },
];
