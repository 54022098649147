import moment from "moment";

import { unserialize } from "../serializers.js";
import API from "../api";
import { getActiveBooking, getActiveBookedResources, getActiveBookingID, getBarcodeResource,
  getNextBookedResourceID, getLatestEnd, getEarliestStart, getNearbyClosedTimes,
  getClosingTimeToday, getAutoCirculatingResources } from "../selectors";

import { selectBookingResource, preValidateBooking, fetchBookedPeers, showNotification } from "../actions.js";

export const fetchResources = (withConstraints=false) => ({
  type: "FETCH_RESOURCES",
  payload: 
    API.get("resources", {includeDeleted: true, associations: withConstraints ? "Constraint" : ""})
    .then(resources => unserialize(resources))
})

export const addResourceToBookingById = (resourceId) => {
  return (dispatch, getState) => {
    const state = getState();
    const resource = state.resources.get(resourceId);
    return dispatch(addResourceToBooking(resource));
  }
}

// Active booking and current user implied
export const addResourceToBooking = (resource) => {
  return (dispatch, getState) => {
    let state = getState();
    const mode = state.mode;
    const booking = getActiveBooking(state);
    const bookedResources = getActiveBookedResources(state);
    const autoCirculatingResources = getAutoCirculatingResources(state);

    // Can't add resources to tentative booking while in activate/return mode
    // or to any booking in return mode.
    if((booking.id === -1 && mode !== "create") || mode === "return")
    {
      dispatch(showNotification({
        message: "Please select 'New Booking' to create a new booking."
      }));
      return;
    }

    // If auto-circulating resources present, disallow adding regular ones,
    // and vice versa
    if((!autoCirculatingResources.isEmpty() && !resource.meta.autoCirculating)
      || (!bookedResources.isEmpty() && autoCirculatingResources.isEmpty()
          && resource.meta.autoCirculating))
    {
      dispatch(showNotification({
        message: "Regular resources and auto-circulating resources may not be combined in the same booking. Please create separate bookings for each kind."
      }));
      return;
    }

    // We can add many non-serialized resources, but just one of each serialized
    const resourceIDList = bookedResources.map(bRes => bRes.ResourceId);
    const bookingHasResource = resourceIDList.includes(resource.id);
    if((resource.serialized && !bookingHasResource) || !resource.serialized)
    {
      let startDate = moment(), endDate = moment();

      // Force reservations if needed
      if(!state.config.allowCheckout)
        startDate = startDate.add(state.config.reservationOffset, "minute");

      // Check if some resources are already added; if so, base start on them
      if(bookedResources.size > 0)
      {
        const firstOne = bookedResources.first();
        startDate = firstOne.adjustedStart || firstOne.bookedStart;
      }
      // TODO: Have a global defaultBookingTime in settings in case resource one
      // is not set
      // bookingTime is measured in minutes; special values are:
      // -1: 30 min before today's service schedule end
      const defaultBookingTime = resource.defaultBookingTime || 60*24;
      if(defaultBookingTime === -1)
        endDate = moment(getClosingTimeToday(state)).subtract(30, "minutes");
      else
        endDate = startDate.clone().add(defaultBookingTime, "minutes");

      if(endDate.isBefore(moment()))
        endDate = startDate.clone().add(60*24, "minutes")

      const bookedResourceID = getNextBookedResourceID(state);
      dispatch({
        type: 'ADD_RESOURCE_TO_BOOKING',
        resource,
        startDate,
        endDate,
        bookedResourceID,
        bookingID: booking.id,
        activeUser: state.user
      })

      dispatch(selectBookingResource(booking.id, bookedResourceID));
      dispatch(preValidateBooking());
      dispatch(fetchBookedPeers(resource.id))
    }
    else if(bookingHasResource)
    {
      // If already added, re-select existing one
      const existingResource = bookedResources.find(bRes =>
        bRes.ResourceId === resource.id);
      dispatch(selectBookingResource(booking.id, existingResource.id));
    }
  }
}

export const fetchResourcesUnavailable = () => {
  return {
    type: "FETCH_RESOURCES_UNAVAILABLE",
    payload: API.get("resourcesUnavailable")
  };
}
