import React from 'react';
import { connect } from 'react-redux';

import {
  addResourceToBooking, toggleParentCategory, selectCategory, enterQuery
} from '../../actions';

import {
  Button, Card, CardHeader, CardBlock, Collapse, ListGroup, ListGroupItem
} from "reactstrap";
import { Parallax } from "react-spring";

import Icon from "../Icon";
import styles from "./index.scss";

const RESOURCE = "RESOURCE";
const CATEGORY = "CATEGORY";

class SinglePaneResourceBrowser extends React.Component {
  constructor(props) {
    super(props);

    const { categories, selectedDepartment } = props;
    const topLevelCategories = props.categories
      .toList()
      .filter(cat => !cat.ParentId && cat.DepartmentId == selectedDepartment)
      .sort((a, b) => a.name.localeCompare(b.name));
    const initialScreen = this.createScreen(
      "All", topLevelCategories, CATEGORY, 0
    );
    this.state = {
      screens: [initialScreen],
      screenIndex: 0
    };
  }

  createScreen = (name, items, type, offset) => {
    if (![RESOURCE, CATEGORY].includes(type)) {
      throw new Error(
        "Invalid type passed to SinglePaneResourceBrowser.createScreen"
      );
    }
    return <Parallax.Layer data-name={name} key={offset} offset={offset}>
      <ListGroup flush className={styles.listGroup}>
        {
          items.map(item =>
            <ListGroupItem
              action
              key={item.id}
              onClick={() => {
                if (type === CATEGORY) {
                  this.props.selectCategory(item.id)
                  if (item.Categories.size > 0) {
                    this.pushScreenAndAdvance(
                      item.name, item.Categories, CATEGORY
                    );
                  } else {
                    this.pushScreenAndAdvance(
                      item.name,
                      this.props.resources.filter(resource =>
                        resource.CategoryId === item.id
                      ),
                      RESOURCE
                    );
                  }
                } else {
                  this.props.addResourceToBooking(item)
                }
              }}
            >
              {item.name}
            </ListGroupItem>
          )
        }
        </ListGroup>
      </Parallax.Layer>;
  }

  popScreen = () => {
    // `scroll` does not have callback, so we delay slicing for smooth animation
    this.scroll(this.state.screenIndex - 1);
    setTimeout(() => {
      this.setState({
        screens: this.state.screens.slice(0, -1),
        screenIndex: this.state.screenIndex - 1
      });
    }, 750);
  }

  truncateScreensTo = (cutoffIndex) => {
    this.scroll(cutoffIndex);
    setTimeout(() => {
      this.setState({
        screens: this.state.screens.slice(0, cutoffIndex + 1),
        screenIndex: cutoffIndex
      });
    }, 750);
  }

  pushScreenAndAdvance = (name, items, type) => {
    this.setState({
      screens: [
        ...this.state.screens,
        this.createScreen(
          name,
          items.toList(),
          type,
          this.state.screenIndex + 1
        )
      ]
    }, () => {
      this.setState({
        screenIndex: this.state.screenIndex + 1
      });
      this.scroll(this.state.screenIndex + 1)
    });
  }

  scroll = to => this.refs.parallax.scrollTo(to)

  render() {
    const { resources, categories, selectedDepartment } = this.props;
    const { screens, screenIndex } = this.state;
    return (
      <Card className={styles.browserCard}>
      {
        screens.length > 1 &&
        <CardHeader>
          <span className="d-flex align-items-center flex-wrap">
          {
            screens.map((screen, index) =>
              <React.Fragment key={`${screen.props["data-name"]}-${index}`}>
                <a onClick={() => this.truncateScreensTo(index)}>
                  {screen.props["data-name"]}
                </a>
                {
                  this.state.screens.length - index > 1 &&
                  <Icon icon="chevron-right" color="dark" height={20} width={20} />
                }
              </React.Fragment>
            )
          }
          </span>
        </CardHeader>
      }
        <div className={styles.viewport}>
          <Parallax
            ref="parallax"
            pages={screens.length}
            horizontal
            scrolling={false}
          >
            {screens}
          </Parallax>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    resources: state.resources,
    selectedCategory: state.selectedCategory,
    selectedDepartment: state.selectedDepartment,
    pending: state.pending.categories || state.pending.resources
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectCategory: (categoryId) => dispatch(selectCategory(categoryId)),
    addResourceToBooking: (resource) => dispatch(addResourceToBooking(resource))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SinglePaneResourceBrowser);
