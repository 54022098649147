import React from 'react';
import { connect } from 'react-redux';
import API from "../../api.js";

import { getActiveBookedResources } from "../../selectors";
import Icon from "../Icon.jsx";

const BookingStatus = ({ user, bookedResources, mode, errors, pending }) => {
  API.log("rendering", "booking status");
  const { message, icon, iconColor } = getStatus(user, bookedResources,
    mode, errors);
  return (
    <span>
      <Choose>
        <When condition={pending}>
          <Icon icon="spinner2" color="dark" className="loader" spinning />
        </When>
        <Otherwise>
          <Icon icon={icon} color={iconColor} />&nbsp;
            <span className="statusMessage">{message}</span>
        </Otherwise>
      </Choose>
    </span>
  )
}

const getStatus = (user, bookedResources, mode, errors) => {
  let message, icon, iconColor;
  let maxSeverity = 0;

  if(bookedResources.isEmpty() || user.id === -1)
  {
    icon = "info";
    iconColor = "info";
    // f*ck if statements!!1
    message = bookedResources.isEmpty()
      ? (mode === "create"
          ? "Select resources to continue."
          : "Select a booking to continue."
        )
      : "Select a user to continue.";
    return { message, icon, iconColor };
  }

  maxSeverity = errors.reduce((max, error) => {
    if(error.severity > max)
      max = error.severity;
    return max;
  }, 0);

  if(maxSeverity === 0)
  {
    icon = "checkmark";
    iconColor = "success";
    message = "Ready.";
  }
  else if(maxSeverity === 1)
  {
    icon = "checkmark";
    iconColor = "warning";
    message = "Ready with warnings.";
  }
  else
  {
    icon = "cross";
    iconColor = "danger";
    message = "Please resolve errors to continue.";
  }

  return { message, icon, iconColor };
}

export default connect(
  (state) => ({
    pending: state.pending.booking,
    errors: state.errors,
    user: state.user,
    mode: state.mode,
    bookedResources: getActiveBookedResources(state)
  })
)(BookingStatus)
