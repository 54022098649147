import API from "../api";
import BookingValidator from "../../utils/bookingValidator.js";

export const saveBookedResources = (bookingID, bookedResources) =>
  bookedResources.every(br => !br.id)
    ? API.post(`booking/${bookingID}/resources`, bookedResources)
    : API.put(`booking/${bookingID}/resources`, bookedResources);

export const prepareBooking = (booking, bookedResources) => {
  return booking.prepareForBookedResources(bookedResources);
}

export const prepareBookedResources = (booking, bookedResources) => {
  return bookedResources.map(bookedRes => bookedRes.prepareForBooking(booking))
    .valueSeq().toJS();
}

export const validateBooking = (preparedBooking, preparedBookedResources) => 
  BookingValidator
    .validate(preparedBooking, preparedBookedResources)
    .then(errors => ({ errors, preparedBooking, preparedBookedResources }))

const saveBooking = booking =>
  booking.id > 0
    ? API.put(`booking/${booking.id}`, booking)
    : API.post("bookings", booking)

export const saveBookingWithResources = (booking, bookedResources) => {
  const preparedBooking = prepareBooking(booking, bookedResources);
  const preparedBookedResources = prepareBookedResources(preparedBooking, bookedResources);

  return validateBooking(preparedBooking, preparedBookedResources)
  .then(({ errors, preparedBooking, preparedBookedResources }) => {
    const severeErrors = errors.filter(err => err.severity > 1);
    if(severeErrors.length > 0)
      return Promise.reject({ errors });
    else
      return saveBooking(preparedBooking);
  })
  .then(booking => {
    const bookedResources = preparedBookedResources.map(br =>
    {
      br.BookingId = booking.id;
      return br;
    });
    return Promise.all([
      booking,
      saveBookedResources(booking.id, bookedResources)
    ])
    .then(([booking, bookedResources]) => ({
      booking,
      bookedResources
    }));
  });
}
