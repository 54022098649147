import React from "react";
import { Card, CardBody } from "reactstrap";
import moment from "moment";

import { displayTime } from "./utils";

const infoText = (amount, singularNoun) => {
	if (amount === 0) {
		return <span>are no {singularNoun}s</span>;
	} else if (amount === 1) {
		return <span>is <strong>{amount}</strong> {singularNoun}</span>;
	} else {
		return <span>are <strong>{amount}</strong> {singularNoun}s</span>; 
	}
}

const Summary = ({ overdueBookings, upcomingBookings, overdueReservations, upcomingReservations }) => {
	const totalCount = overdueBookings.size + upcomingBookings.size + overdueReservations.size + upcomingReservations.size;
	if (totalCount === 0) {
		return <p>All clear! No upcoming or overdue bookings and reservations.</p>;
	}

	const bookingCount = overdueBookings.size + upcomingBookings.size;
	const reservationCount = overdueReservations.size + upcomingReservations.size;
	return <React.Fragment>
		{
			bookingCount === 0
				? <p>No upcoming or overdue bookings.</p>
				: <React.Fragment>
						<p>There {infoText(overdueBookings.size, "booking")} overdue{/*by over 15 minutes*/}.</p>
						<p>There {infoText(upcomingBookings.size, "booking")} due to be returned in the next {/*30 minutes*/} 2 days.</p>
					</React.Fragment>
		}
		{
			reservationCount === 0
				? <p>No upcoming or overdue reservations.</p>
				: <React.Fragment>
						<p>There {infoText(overdueReservations.size, "reservation")} overdue for activation.</p>
						<p>There {infoText(upcomingReservations.size, "reservation")} due to be activated in the next {/*30 minutes*/} 2 days.</p>
					</React.Fragment>
		}
	</React.Fragment>
}

const SummaryTab = ({ bookings, reservations }) => {
	const overdueBookings = bookings.filter(booking => displayTime(booking) < moment());
	const upcomingBookings = bookings.filter(booking => displayTime(booking) >= moment());
	const overdueReservations = reservations.filter(reservation => displayTime(reservation) < moment());
	const upcomingReservations = reservations.filter(reservation => displayTime(reservation) >= moment());
	return (
		<Card className="nearestBookings summary">
			<CardBody>
				<Summary
					overdueBookings={overdueBookings}
					upcomingBookings={upcomingBookings}
					overdueReservations={overdueReservations}
					upcomingReservations={upcomingReservations}
				/>
				<p><i>Pick a user or resources to start a new booking.</i></p>
			</CardBody>
		</Card>
	);
}

export default SummaryTab;
