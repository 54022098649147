import React from 'react';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';

import getBookedResourceStatusText from "./getBookedResourceStatusText";
import { getActiveTimezone } from "../../selectors";
import moment from "moment-timezone";

import { Card, CardHeader, CardBody, Button, Table }
  from "reactstrap";

const dateTimeFormat = 'MMM Do YYYY, h:mm a';

const generateBookingLink = bookedResource => 
  `app/${bookedResource.Booking.status == "reserved" ? "activate" : "return"}/
${bookedResource.Booking.Keeper.id}/${bookedResource.Booking.id}/${bookedResource.id}`;

const generateUserLink = bookedResource => `/manage/users/${bookedResource.Booking.Keeper.id}`;

const BookedResourceRow = ({ bookedResource, activeTimezone }) => {
  const status = getBookedResourceStatusText(
    bookedResource, bookedResource.Booking, false, activeTimezone);

  let userName = "Unknown";
  let userLink = undefined;
  let bookingLink = undefined;
  if(bookedResource.Booking && bookedResource.Booking.Keeper)
  {
    userName = bookedResource.Booking.Keeper.name;
    userLink = generateUserLink(bookedResource);
    bookingLink = generateBookingLink(bookedResource);
  }

  return (
    <tr>
      <td>{userLink ? <Link to={userLink}>{userName}</Link> : "Deleted user"}</td>
      <td>{moment.tz(bookedResource.bookedStart, activeTimezone).format(dateTimeFormat)}</td>
      <td>{moment.tz(bookedResource.bookedEnd, activeTimezone).format(dateTimeFormat)}</td>
      <td>{status}</td>
      <td>{bookingLink && <Link to={bookingLink} target="blank">Open booking</Link>}</td>
    </tr>
  );
};

class ResourceBookings extends React.Component
{
  render()
  {
    let { resource, bookedResources } = this.props;
    let headerText = "Recent bookings";
    if(bookedResources)
      bookedResources = bookedResources.filter(bookedResource => bookedResource.Booking);
    return (bookedResources && <Card>
      <CardHeader>{headerText}</CardHeader>
      <CardBody>
      {
        bookedResources.length
        ? <Table>
          <thead>
            <tr>
              <th>User</th>
              <th>Booked at</th>
              <th>Due</th>
              <th>Status</th>
              <th>Tools</th>
            </tr>
          </thead>
          <tbody>
          {
            bookedResources
            .sort((a, b) => a.bookedStart < b.bookedStart ? 1 : -1)
            .map(bookedResource =>
              <BookedResourceRow
                key={bookedResource.id}
                bookedResource={bookedResource}
                activeTimezone={this.props.activeTimezone}
              />
            )
          }
          </tbody>
        </Table>
        : <i>No recent bookings for {resource.name}</i> 
      }
      </CardBody>
    </Card>)
  }
}

const mapStateToProps = (state) => {
  return {
    DepartmentId: state.selectedDepartment,
    activeTimezone: getActiveTimezone(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

const ResourceBookingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceBookings)


export default ResourceBookingsContainer;
