import React from 'react';
import { connect } from 'react-redux';
import { push, go, replace } from 'redux-first-router';
import Loadable from "react-loadable";

import Icon from "../Icon.jsx";
import Omnibox from '../Omnibox/';
import OperatorTools from "../OperatorTools/";
import UserEditor from "./UserEditor.jsx";
import Notifications from "../Notifications.jsx";
import GenericSpinner from "../GenericSpinner.jsx";

import ResourcesSection from "./ResourcesSection.jsx";
import CategoriesSection from "./CategoriesSection.jsx";
import UsersSection from "./UsersSection.jsx";
import GroupsSection from "./GroupsSection.jsx";

const AnalyticsSection = Loadable({
  loader: () => import(/* webpackChunkName: "manager-analytics" */ "./AnalyticsSection.jsx"),
  loading: GenericSpinner
});
const PenaltiesSection = Loadable({
  loader: () => import(/* webpackChunkName: "manager-penalties" */ "./PenaltiesSection.jsx"),
  loading: GenericSpinner
});
const ServiceSchedulesSection = Loadable({
  loader: () => import(/* webpackChunkName: "manager-service-schedules" */ "./ServiceSchedulesSection.jsx"),
  loading: GenericSpinner
});

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle,
  CardText, Container, Row, Col, ListGroup, ListGroupItem, Collapse }
  from "reactstrap";

import { fetchSettings, fetchCategories, fetchDepartments, fetchResources,
  initialize, setOperator, enterQuery, autoSelectDepartment }
  from '../../actions';
import { User, Resource } from '../../records';

const tabMap = {users: "User", resources: "Resource", categories: "Category"};

class MantaManager extends React.Component
{
  constructor(props)
  {
    super(props);

    Promise.all([
      this.props.dispatch(initialize()),
    ]).then(() => Promise.all([
      this.props.dispatch(fetchCategories()),
      this.props.dispatch(fetchDepartments()),
      this.props.dispatch(fetchResources(true)),
      this.props.dispatch(fetchSettings()),
      this.props.dispatch(setOperator())
    ])).then(() => {
      this.props.dispatch(autoSelectDepartment());
    });
  }

  componentDidMount()
  {
    const { model, id } = this.props;
    this.loadModel(model, id);
  }
  
  componentWillReceiveProps(props) {
    const { model, id } = props;
    this.loadModel(model, id);
  }

  loadModel = (model, id) => {
    if(model && id)
    {
      this.setState({ activeTab: model, [`load${tabMap[model]}`]: id });
    }
    else if(model)
    {
      if(this.state.activeTab !== model)
        this.setState({ activeTab: model });
      if(model === "resources")
        this.props.dispatch(enterQuery(""));
    }
  }

  state = {
    activeTab: "resources",
    loadResource: undefined,
    loadUser: undefined,
    loadCategory: undefined,
  }

  switchTab = (tab) =>
  {
    //API.transaction.mark("Opened " + tab);
    push("/manage/" + tab);
  }

  render()
  {
    if(!this.props.initialized)
      return null; // TODO: return generic loading...

    if(!this.props.config.allowManage)
    {
      push("/app");
      return null;
    }

    const isAdmin = this.props.operator.role == "admin";
    const { dispatch, categories } = this.props;
    const { loadResource, loadCategory, loadUser } = this.state;
    return (
      <Container fluid id="manta-manager">
        <Notifications />
        <Row id="actionBar" className="d-flex justify-content-between flex-nowrap">
          <Button className="navButton btn-back"
            onClick={() => { push("/app") }}>
            Back to Application
          </Button>
          <div id="logo" />
          <div className="d-flex justify-content-end">
            <OperatorTools />
          </div>
        </Row>
        <Row id="manta-manager-container">
          <Col md="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "resources" ? "active" : ""}
                  onClick={() => { this.switchTab("resources"); }}
                >
                  Resources
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "categories" ? "active" : ""}
                  onClick={() => { this.switchTab("categories"); }}
                >
                  Categories
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "users" ? "active" : ""}
                  onClick={() => { this.switchTab("users"); }}
                >
                  Users
                </NavLink>
              </NavItem>
              {isAdmin && 
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "groups" ? "active" : ""}
                  onClick={() => { this.switchTab("groups"); }}
                >
                  Groups
                </NavLink>
              </NavItem>
              }
              {isAdmin && 
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "penalties" ? "active" : ""}
                  onClick={() => { this.switchTab("penalties"); }}
                >
                  Fines
                </NavLink>
              </NavItem>
              }
              {isAdmin &&
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "serviceSchedules" ? "active" : ""}
                  onClick={() => { this.switchTab("serviceSchedules"); }}
                >
                  Service Hours
                </NavLink>
              </NavItem>
              }
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "analytics" ? "active" : ""}
                  onClick={() => { this.switchTab("analytics"); }}
                >
                  Analytics
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="resources" id="resources">
                <ResourcesSection dispatch={dispatch}/>
              </TabPane>
              <TabPane tabId="categories" id="categories">
                <CategoriesSection dispatch={dispatch} categories={categories}/>
              </TabPane>
              <TabPane tabId="users" id="users">
                <UsersSection loadUser={loadUser} dispatch={dispatch} />
              </TabPane>
              <TabPane tabId="groups" id="groups">
                <GroupsSection dispatch={dispatch} />
              </TabPane>
              <TabPane tabId="serviceSchedules" id="serviceSchedules">
                <ServiceSchedulesSection dispatch={dispatch} DepartmentId={this.props.DepartmentId} />
              </TabPane>
              <TabPane tabId="penalties" id="penalties">
                <PenaltiesSection dispatch={dispatch} DepartmentId={this.props.DepartmentId} />
              </TabPane>
              <TabPane tabId="analytics" id="analytics">
                <AnalyticsSection dispatch={dispatch} DepartmentId={this.props.DepartmentId} />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialized: state.initialized,
    operator: state.operator,
    config: state.config,
    DepartmentId: state.selectedDepartment,
    categories: state.categories
  }
}

export default connect(
  mapStateToProps,
  null
)(MantaManager);
