import React from "react";
import { connect } from 'react-redux';
import moment from "moment-timezone";

import { adjustResourceTime } from "../../actions";
import { getActiveTimezone } from "../../selectors";

import ValidatingDateTime from "../ValidatingDateTime";

const BookingTimeControls = ({ mode, activeTimezone, bookingID, bookedResourceID, start, end, dispatch }) => {
  return (
    <div className="bookingTimeControls">
      <ValidatingDateTime
        timezone={activeTimezone}
        value={moment.tz(start, activeTimezone)}
        onChange={start => dispatch(adjustResourceTime(bookedResourceID, start, null))}
      />
      <ValidatingDateTime
        timezone={activeTimezone}
        value={moment.tz(end, activeTimezone)}
        onChange={end => dispatch(adjustResourceTime(bookedResourceID, null, end))}
      />
      <div className="clear" />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    mode: state.mode,
    activeTimezone: getActiveTimezone(state)
  }
}

export default connect(
  mapStateToProps
)(BookingTimeControls)
