import React from "react";
import { connect } from 'react-redux';

import { getActiveBooking, getActiveBookedResources } from "../../selectors";

import { ButtonGroup } from 'reactstrap';

import ConfirmBookingButton from './buttons/ConfirmBookingButton';
import DiscardBookingButton from './buttons/DiscardBookingButton';
import CancelBookingButton from './buttons/CancelBookingButton';
import ActivateBookingButton from './buttons/ActivateBookingButton';
import UpdateBookingButton from './buttons/UpdateBookingButton';
import ReturnAllButton from './buttons/ReturnAllButton';
import RenewBookingButton from './buttons/RenewBookingButton';

// Control buttons are split into presentational and container components;
// modular inc. but with boilerplate overload a la Dan Abramov, inspired by
// http://stackoverflow.com/questions/35623656/

const buttonsByMode = {
  "create": [ConfirmBookingButton, DiscardBookingButton],
  "activate": [ActivateBookingButton, UpdateBookingButton, CancelBookingButton],
  "return": [ReturnAllButton, RenewBookingButton]
}

const BookingControls = ({ config, mode, user, booking, bookedResources }) => {
  if(!mode)
  {
    return null;
  }
  return (
    <ButtonGroup className="btn-group-justified">
      {
        buttonsByMode[mode].map((SpecificButton, i) => {
          if(!config.allowCheckout && SpecificButton === ActivateBookingButton)
            return;
          return <SpecificButton key={mode + "_btn" + i}
            mode={mode}
            user={user}
            booking={booking}
            bookedResources={bookedResources}
          />
        })
      }
    </ButtonGroup>
  )
}

export default connect(
  (state) => {
    return {
      config: state.config,
      mode: state.mode,
      user: state.user,
      booking: getActiveBooking(state),
      bookedResources: getActiveBookedResources(state)
    }
  }
)(BookingControls)
