import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class MessageBox extends React.Component {
  render() {
    const { title="Message", body=null, onOk, okText="Ok",
      showCancel=true, onCancel, cancelText="Cancel", toggle, showing } = this.props;
    return (
      <Modal isOpen={showing} toggle={toggle} >
        <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
        <ModalBody>
          {body}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onOk || toggle}>{okText}</Button>{' '}
          { showCancel && <Button color="secondary" onClick={onCancel || toggle}>{cancelText}</Button> }
        </ModalFooter>
      </Modal>
    );
  }
}

export default MessageBox;
