import React from "react";
import FormElement from "./FormElement.jsx";

const indentation = 4;
const topLevel = cat => !cat.ParentId;

const renderOption = (categories, category, level, disableParents, disabledCategories=[]) => 
{
  const hasChildren = category.Categories.size > 0;
  const parent = (
    <option key={category.id} value={category.id}
      style={topLevel(category) ? {fontWeight: "bold"} : {}}
      disabled={disabledCategories.includes(category.id) || (disableParents && hasChildren)}>
      {`${'\u00A0'.repeat(level)} ${category.name}`}
    </option>
  );

  let children = [];
  if(hasChildren)
    children = category.Categories
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(category =>
      renderOption(categories, category, level + indentation, disableParents, disabledCategories)
    );

  return [parent, ...children];
}

const CategorySelect = (props) => {
  if(!props.categories)
    return null;
  const disableParents = props.disableParents || false;
  return (
    <FormElement {...props} type="select">
      <option key={-1} value={-1}>
        {disableParents ? "Select one..." : "None (Top level)"}
      </option>
      {
        props.categories
          .filter(topLevel)
          .sort((a, b) => a.name.localeCompare(b.name))
          .valueSeq()
          .map(category =>
            renderOption(props.categories, category, 0, disableParents, props.disabledCategories)
          )
      }
    </FormElement>
  );
}

export default CategorySelect;
