import { Map } from "immutable";
import { successfulBookingActions, BookedResource } from "../records.js";

export const defaultSelectedBookedResource = 0;
const defaultSelectedBooking = -1;

export const selectedBookedResource = (state=defaultSelectedBookedResource, action) => {
  if(successfulBookingActions.includes(action.type))
    return defaultSelectedBooking;

  switch(action.type) {
    case 'SELECT_BOOKING_RESOURCE':
      if(action.bookedResourceID)
      {
        return action.bookedResourceID;
      }
      else
      {
        return (state === defaultSelectedBookedResource ? null : defaultSelectedBookedResource);
      }
      return (action.bookedResourceID
        ? action.bookedResourceID
        : defaultSelectedBookedResource);
    case 'REMOVE_RESOURCE_FROM_BOOKING':
      if(state === action.bookedResourceID)
        return defaultSelectedBookedResource;
      else return state;
    case 'SET_MODE':
    case 'DISCARD_ACTIVE_BOOKING':
    case 'DISCARD_BOOKINGS':
      return defaultSelectedBookedResource;
    default:
      return state;
  }
}

export const bookedResources = (state=Map(), action) => {
  if(successfulBookingActions.includes(action.type))
  {
    const { bookedResources } = action.payload;
    const updatedBookedResources = BookedResource.makeRecords(bookedResources);
    return state
      .merge(updatedBookedResources)
      .filter(bookedResource => bookedResource.id > 0);
  }

  switch(action.type) {
    // Reset before fetching new booked resources (otherwise timeline will
    // attempt to be redrawn with new bookings, but old bookedResources)
    case 'DISCARD_BOOKINGS':
      return Map();

    case 'DISCARD_ACTIVE_BOOKING':
      return state.filter(bRes => bRes.id >= 0);

    case "SET_MODE":
      // Use deleteAll when we upgrade to ImmutableJS 4
      const tentativeIDs = state.filter(bRes => bRes.id < 0).map(bRes => bRes.id);
      return tentativeIDs.reduce((map, key) => map.delete(key), state);

    case 'FETCH_BOOKINGS_FULFILLED':
    {
      const tentativeBookedResources = state.filter(bRes => bRes.id < 0);
      return tentativeBookedResources;
    }
    case 'FETCH_BOOKED_RESOURCES_FULFILLED':
    {
      const bookedResources = action.payload;
      const tentativeBookedResources = state.filter(bRes => bRes.id < 0);
      return BookedResource.makeRecords(bookedResources)
        .merge(tentativeBookedResources);
    }

    case 'ADD_RESOURCE_TO_BOOKING':
      const newBookedResource = new BookedResource({
        id: action.bookedResourceID,
        start: action.startDate,
        end: action.endDate,
        bookedStart: action.startDate,
        bookedEnd: action.endDate,
        status: "active",
        BookingId: action.bookingID,
        ResourceId: action.resource.id
       });
      return state.set(action.bookedResourceID, newBookedResource);

    case 'REMOVE_RESOURCE_FROM_BOOKING':
      return state.delete(action.bookedResourceID);

    case 'ADJUST_RESOURCE_TIME':
      return state.updateIn([action.bookedResourceID], bookedRes => {
        let updBookedRes = bookedRes;
        if(action.start)
          updBookedRes = updBookedRes.set("adjustedStart", action.start);
        if(action.end)
          updBookedRes = updBookedRes.set("adjustedEnd", action.end);
        return updBookedRes;
      })

    case 'ADJUST_ALL_RESOURCE_TIME':
      const bookingLocalStatus = action.booking.getLocalStatus();
      return state.map(bRes => {
        if(bRes.BookingId === action.booking.id)
        {
          const status = bRes.getLocalStatus(bookingLocalStatus, action.mode);
          if(status.isActive)
          {
            if(action.start)
              bRes = bRes.set("adjustedStart", action.start);
            if(action.end)
              bRes = bRes.set("adjustedEnd", action.end);
          }
        }
        return bRes;
      });

    default:
      return state;
  }
}

export const defaultBookedPeers = Map();
export const bookedPeers = (state=defaultBookedPeers, action) => {
  switch(action.type)
  {
    case "FETCH_BOOKED_PEERS_FULFILLED":
      const peers = action.payload;
      return state.merge(BookedResource.makeRecords(peers));
    case "REMOVE_BOOKED_PEERS":
      return state.filter(peer => peer.ResourceId !== action.resourceID);
    case "DISCARD_ACTIVE_BOOKING":
    case "DISCARD_BOOKINGS":
    case 'SET_MODE':
      return defaultBookedPeers;
    default:
      return state;
  }
}
