
function resolveGroupConstraints(userGroups, resource, constraintSeverity)
{
  const checkGroup = (constraint) => userGroups.find(group => group.id == constraint.GroupId);

  const groupConstraints = resource.Constraints.filter(c => c.type === "group");
  const necessary = groupConstraints.filter(c => !((c.definition || {}).sufficient));
  const sufficient = groupConstraints.filter(c => (c.definition || {}).sufficient);

  // Satisfies (c1 AND c2 AND c3) OR c4 OR c5
  // where c123 are necessary, c45 are sufficient
  
  // if any sufficient conditions are fulfilled, pass
  if(sufficient.map(checkGroup).some(Boolean))
    return [];

  // start with sufficient errors
  let errors = sufficient.map(constraint => Promise.resolve({
        code: "CONSTRAINT_VIOLATED",
        data: { resource, constraint },
        severity: constraintSeverity
      }));
  
  // if no necessary conditions exist, at least one sufficient must be fulfilled
  if(necessary.length === 0 && sufficient.length > 0)
    return errors;

  // finally, all necessary conditions must be filled
  necessary.forEach(constraint => {
    if(!checkGroup(constraint))
      errors.push(Promise.resolve({
        code: "CONSTRAINT_VIOLATED",
        data: { resource, constraint },
        severity: constraintSeverity
      }));
  });

  return errors;
}

module.exports = { resolveGroupConstraints };
