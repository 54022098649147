import React from 'react';

import { Resource } from '../../records';
import ResourceEditor from "./ResourceEditor/";
import ResourceBookings from "./ResourceBookings.jsx";
import Omnibox from '../Omnibox/';
import FilteredResources from "../filteredResources.jsx";

import { serialize } from "../../serializers.js";

import { showNotification, fetchResources } from '../../actions';

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle,
  CardText, Container, Row, Col } from "reactstrap";

class ResourcesSection extends React.Component
{
  state = {
    pending: false,
    editedResource: Resource.newResource(),
    editedResourceBookings: null
  }


  loadResourceInEditor = (resource) => {
    const editedResource = {...resource.toJS(), Constraints: serialize(resource.Constraints)};
    this.setState({ editedResource } , () => {
      API.get("bookedResourceWithExtras", {ResourceId: resource.id}).then(bookings => {
        this.setState({ editedResourceBookings: bookings });
      });
    });
  }

  clear = () => {
    this.setState({ editedResource: Resource.newResource(), editedResourceBookings: null });
  }

  sanitizeConstraints = constraints => {
    // Remove negative ids so the backend creates new resources
    constraints.forEach(constraint => {
      if(constraint.id < 0)
        delete constraint.id;
    });
    //Remove group constraints not assigned to groups
    constraints = constraints.filter(constraint => constraint.type !== "group" || constraint.GroupId);
    return constraints;
  }

  saveResource = (resource) => {
    this.setState({ editedResource: resource, pending: true });
    const existingResource = resource && resource.id && resource.id > 0;

    // Remove category id
    if(resource.CategoryId == -1)
      resource.CategoryId = null;

    resource.Constraints = this.sanitizeConstraints(resource.Constraints);

    return (() => {
      if(existingResource)
        return API.put("resource/" + resource.id, resource);
      else
        return API.post("resources", resource);
    })()
    .then((response) => {
      if(!existingResource)
        this.clear();
      else
        this.loadResourceInEditor(Resource.makeRecord(resource));

      this.setState({ pending: false });

      if(response.id)
      {
        this.props.dispatch(showNotification({
          message: `Resource ${resource.name} has been saved`
        }))
      }
      else
      {
        const errors = response.errors.map(err => `${err.message}`).join(", ");
        this.props.dispatch(showNotification({
          message: `Error saving resource. ${response.message}: ${errors}`,
          color: "danger",
          dismiss: 10000
        }))
      }
      return this.props.dispatch(fetchResources(true))
        .then(() => Promise.resolve());
    })
    .catch(response => {
      this.props.dispatch(showNotification({
        message: response.message,
        color: "danger",
        dismiss: 10000
      }));
      this.setState({ pending: false });
      return Promise.reject({ error: response.message });
    });
  }

  deleteResource = (resource) => {
    if(!confirm("Are you sure you want to delete this resource?"))
      return;
    return API.delete("resource/" + resource.id)
    .then(() => {
      this.setState({ editedResource: Resource.newResource(), editedResourceBookings: null });
      this.props.dispatch(showNotification({
        message: `Resource ${resource.name} has been deleted`
      }));
      this.props.dispatch(fetchResources(true));
    });
  }

  render() {
    return (
      <Row className="manager-row">
        <Col md={4} className="d-flex flex-column align-items-stretch justify-content-start">
          <Omnibox placeholder="Search resources"/>
          <FilteredResources onSelectResource={this.loadResourceInEditor} showInactives={true} />
        </Col>
        <Col md={8} className="scroll-col">
          <ResourceEditor
            pending={this.state.pending}
            resource={this.state.editedResource}
            saveResource={this.saveResource}
            deleteResource={this.deleteResource}
            onClear={this.clear}
          />
          <br/>
          <ResourceBookings resource={this.state.editedResource} bookedResources={this.state.editedResourceBookings} />
        </Col>
      </Row>
    );
  }
}

export default ResourcesSection;
