import moment from "moment";

import { runSaveSequence, raiseError, preValidateBooking, addResourceToBooking,
  clearErrors } from "../actions.js";
import { getActiveBooking, getActiveBookedResources, getActiveBookingID, getBarcodeResource,
  getNextBookedResourceID, getLatestEnd, getEarliestStart, getNearbyClosedTimes,
  getClosingTimeToday, getAutoCirculatingResources } from "../selectors";
import API from "../api";

export const fetchBookedResources = (bookingIDs) => ({
  type: "FETCH_BOOKED_RESOURCES",
  payload: API.get("bookedResources", { BookingId: bookingIDs })
})

export const fetchBookedPeers = (resourceID) => ({
  type: "FETCH_BOOKED_PEERS",
  payload: API.get("bookedPeers/", {
    id: resourceID,
    referenceDate: moment().toISOString()
  })
})

export const removeBookedPeers = (resourceID) => {
  return { type: "REMOVE_BOOKED_PEERS", resourceID }
}

// Booking flow actions
export const adjustResourceTime = (bookedResourceID, start, end) => {
  return (dispatch, getState) => {
    // Prevent moving active items in the past if in create or activate modes
    const now = moment();
    const state = getState();
    const bookedResource = state.bookedResources.get(bookedResourceID);

    // Force reservations if needed
    if(!state.config.allowCheckout && start && start.diff(now, "minutes") < 5)
      start = now.clone().add(state.config.reservationOffset, "minute");

    // 5 minutes considered timeout; later we should use global currentTime
    if(start && now.diff(start, "minutes") > 5 && state.mode !== "return")
    {
      const bookedDuration = bookedResource.getDuration();
      start = now;
      end = now.clone().add(bookedDuration);
    }
    else if(start && end && start.isAfter(end))
    {
      // not min booking time, but enough for bar not to be miniscule
      start = end.clone().subtract(30, "minutes"); 
    }

    dispatch({ type: 'ADJUST_RESOURCE_TIME', bookedResourceID, start, end });
    dispatch(adjustAllResourceTime(bookedResource.BookingId, start, null));
  }
}

export const adjustAllResourceTime = (bookingID, start, end) => {
  return (dispatch, getState) => {
    const now = moment();
    const state = getState();
    const booking = state.bookings.get(bookingID);
    const mode = state.mode;

    const latestEnd = getLatestEnd(state);
    const earliestStart = getEarliestStart(state);

    if(start && now.diff(start, "minutes") > 5 && state.mode !== "return")
      start = now.clone();
    else if(start && !end && !start.isBefore(latestEnd))
      end = start.clone().add(30, "minutes")
      // start = latestEnd.clone().subtract(30, "minutes");

    if(end && !start && !end.isAfter(earliestStart))
      end = earliestStart.clone().add(30, "minutes");

    dispatch({ type: 'ADJUST_ALL_RESOURCE_TIME', booking, mode, start, end });
    dispatch(preValidateBooking());
  }
}

export const removeResourceFromBooking = (bookedResourceID) => {
  return (dispatch, getState) => {
    const state = getState();
    const mode = state.mode;

    const bookedResource = state.bookedResources.get(bookedResourceID);
    const resourceID = bookedResource.ResourceId;
    const bookingID = bookedResource.BookingId;
    const allBookingResources = state.bookedResources.filter(bRes =>
      bRes.BookingId === bookingID);

    // If we're removing all resources, clear errors
    // TODO: establish mapping between errors and resources; clear only those
    // errors associated with certain resource
    if(allBookingResources.size === 1)
    {
      dispatch(clearErrors());
    }

    dispatch({ type: 'REMOVE_RESOURCE_FROM_BOOKING', bookedResourceID });
    dispatch(preValidateBooking());
    dispatch(removeBookedPeers(resourceID))
  }
}

// Two different functions exist to select resources for convenience:
//   * selectBookingResource(bookingID, bookedResourceID)
//     -- to do it programmatically
//   * selectTimelineBookingResource(bookedResourceID)
//     -- to handle UI clicks easier; invokes selectBookingResource, assumes
//        active booking
export const selectBookingResource = (bookingID, bookedResourceID) => {
  return (dispatch, getState) => {

    let isCurrentlySelected = false;
    if(bookingID && bookedResourceID)
    {
      const state = getState();

      isCurrentlySelected = (
        state.selectedBookedResource === bookedResourceID
        && state.selectedBooking === bookingID
      );
      const bookingStatus = state.bookings.getIn([bookingID, "status"]);
      const isBookingTentative = (bookingStatus === "tentative");
    }

    // Skip extra cycles if item is already selected
    if(!isCurrentlySelected)
      return dispatch({ type: 'SELECT_BOOKING_RESOURCE', bookingID, bookedResourceID });
    // else
      // return dispatch({});
  }
}

export const selectBarcodeResource = () => {
  return (dispatch, getState) => {
    const resource = getBarcodeResource(getState());
    if(resource) {
      return dispatch(addResourceToBooking(resource))
    }
  }
}

export const selectTimelineBookingResource = (bookedResourceID) => {
  return (dispatch, getState) => {
    if(bookedResourceID)
    {
      // Check if this item is meant to be selected in current mode
      const state = getState();
      const bookedResource = state.bookedResources.get(bookedResourceID);
      const booking = state.bookings.get(bookedResource.BookingId);
      const bookingLocalStatus = booking.getLocalStatus();
      const localStatus = bookedResource.getLocalStatus(bookingLocalStatus,
        state.mode);

      if(!localStatus.classList.includes("irrelevant"))
        dispatch(selectBookingResource(booking.id, bookedResourceID));
      //else {maybe show message saying operator needs to select different mode}
    }
    else
    {
      dispatch(selectBookingResource(null, null));
    }
  }
}

// returnBookingOrResource will return one resource if [resource] is passed.
// If resource is not passed it will return all resources and hence the booking. 
export const returnBookingOrResource = (booking, bookedResourceID=null) => {
  return (dispatch, getState) => {
    const state = getState();
    let bookedResources = state.bookedResources
      .filter(bRes => bRes.BookingId === booking.id);

    return dispatch(returnBookingOrResourcesRaw(
      booking,
      bookedResources,
      bookedResourceID ? [bookedResourceID] : null
    ));
  }
}

export const returnBookingOrResourcesRaw = (booking, bookedResources, bookedResourceIDs) => {
  let bookingHasUnreturnedResources = false;
  bookedResources = bookedResources.map(bookedRes => {
    if(!bookedResourceIDs || bookedResourceIDs.includes(bookedRes.id))
    {
      if(bookedRes.status !== "returned")
      {
        bookedRes = bookedRes.merge({
          status: "returned",
          end: moment()
        });
      }
    }

    bookingHasUnreturnedResources = bookingHasUnreturnedResources
      || bookedRes.status !== "returned";
    return bookedRes;
  });

  if(!bookedResourceIDs || !bookingHasUnreturnedResources)
  {
    booking = booking.set("status", "complete");
  }

  const returnType = (bookedResourceIDs === null || !bookingHasUnreturnedResources)
    ? "RETURN_BOOKING"
    : "RETURN_RESOURCE";

  return runSaveSequence(
    returnType,
    booking,
    bookedResources
  );
}

export const renewBookingOrResource = (booking, bookedResourceID=null) => {
  return (dispatch, getState) => {
    const state = getState();
    let timeAdjusted = false;
    let bookedResources = state.bookedResources
      .filter(bRes => bRes.BookingId === booking.id);

    bookedResources = bookedResources.map(bookedRes => {
      if(!bookedResourceID || bookedRes.id === bookedResourceID)
      {
        if(bookedRes.status !== "returned" && bookedRes.adjustedEnd)
        {
          bookedRes = bookedRes.applyAdjustments();
          bookedRes = bookedRes.merge({ renewals: bookedRes.renewals + 1 });
          timeAdjusted = true;
        }
      }
      return bookedRes;
    });

    if(timeAdjusted)
    {
      const renewType = (bookedResourceID === null)
        ? "RENEW_BOOKING"
        : "RENEW_RESOURCE";

      return dispatch(runSaveSequence(
        renewType,
        booking,
        bookedResources
      ));
    }
    else
    {
      // TODO: vai sim vajadzetu but validator logika?
      return dispatch(raiseError(
        "UNADJUSTED_RENEW",
        "No new return time was provided",
        bookedResourceID ? bookedResources.get(bookedResourceID) : bookedResources,
        1
      ));
    }
  }
}
