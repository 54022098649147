import React from "react";
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Label, Input, Form,
  FormGroup, FormFeedback, FormText, Container, Alert } from "reactstrap";
import Step from "./Step";

import classNames from "classnames";
import styles from "./index.scss";

class SendRecoveryLinkStep extends React.Component {
  SSO_AUTH = "sso"
  BASIC_AUTH = "basic"

  state = {
    email: "",
    maskedEmail: "",
    emailInvalid: false,
    formDisabled: true,
    formErrored: false,
    formErrorText: "",
    recaptchaMounted: false,
    actionButtonText: "Preparing..."
  }

  componentDidMount() {
    if (this.props.done) {
      return;
    }

    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => reject("Timed out while preparing account reset page."), 10000);
    });
    const recaptchaMountedPromise = new Promise((resolve) => {
      const checkIfMounted = () => {
        try {
          if (grecaptcha !== undefined) {
            grecaptcha.ready(function() {
              grecaptcha.render("recaptcha-container",{
                "sitekey": "6Ldl0KgUAAAAABge-bBHMB0RAG5gWvYeISGdUiWg"
              });
              resolve();
              return;
            });
          } else {
            setTimeout(checkIfMounted, 250);
          }
        } catch(e) {}
      }
      setTimeout(checkIfMounted, 250);
    });

    Promise.race([recaptchaMountedPromise, timeoutPromise])
      .then(() => {
        this.setState({
          formDisabled: false,
          actionButtonText: "Send recovery email"
        });
      })
      .catch(e => {
        console.error(e);
        this.setState({
          formErrored: true,
          formErrorText: `${e} Please refresh the page and try again.`,
          actionButtonText: "Error"
        });
      });
  }

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value
    });
  }

  decomposeEmail(email) {
    const emailRegex = /^(\S+)@(\S+)$/g;
    let lookup, matches;
    while ((lookup = emailRegex.exec(email)) !== null) {
      matches = lookup;
    }
    return matches || [];
  }

  handleRecover = (event) => {
    event.preventDefault();

    const emailFieldValue = this.state.email;
    const [email, username, domain] = this.decomposeEmail(emailFieldValue);
    if (!email || !username || !domain) {
      this.setState({ emailInvalid: true });
      return;
    } else {
      this.setState({ emailInvalid: false });  
    }

    this.setState({ formDisabled: true, formErrored: false });

    API.get("loginParams", {domain})
    .then(({ loginMethod }) => {
      console.log(loginMethod)
      if (loginMethod == this.SSO_AUTH) {
        this.props.setError("This account belongs to an institution that uses single sign-on and its password cannot be reset via Manta. Please use your institution's password reset functionality, or contact its IT Solutions team for assistance.");
      } else if (loginMethod == this.BASIC_AUTH) {
        const maskedEmail = username.length > 2
          ? `${username.substr(0,2)}${Array(username.length-2).fill("*").join("")}@${domain}`
          : email;
        this.setState({ maskedEmail });
        const recaptchaResponse = grecaptcha.getResponse();
        if (recaptchaResponse) {
          this.props.handleSendRecoveryLink({
            email,
            recaptchaResponse
          });
        } else {
          this.setState({
            formDisabled: false,
            formErrored: true,
            formErrorText: "Please complete the reCaptcha dialog."
          });
        }
      } else {
        this.props.setError("An unknown error occurred. Please refresh and try again.");
      }
    });
  }

  render() {
    const { handleSendRecoveryLink, done = false } = this.props;
    if (done) {
      return (
        <Step done={true} number={1} text={"A password recovery link was sent to " + (this.state.maskedEmail || "your e-mail address") + ". If the email belongs to a valid account, you should receive a recovery e-mail within 15 minutes."} />
      );
    }

    return (
      <Form onSubmit={() => handleSendRecoveryLink(this.state.email)} className="mt-3 mb-0">
        <FormGroup>
          <Step number={1} text="To reset your password, first enter the e-mail address of your account. We will then send a password recovery link to that address." />
          <Label for="email" className="sr-only">E-mail address</Label>
          <Input
            type="text"
            id="email"
            name="email"
            placeholder="Enter e-mail address"
            disabled={this.state.formDisabled}
            autoFocus={true}
            className="mt-3"
            onChange={this.handleEmailChange}
            invalid={this.state.emailInvalid} />
          <FormFeedback>Sorry, that doesn't look like a valid e-mail address</FormFeedback>
        </FormGroup>
        <div id="recaptcha-container" className="mb-3"></div>
        <FormGroup>
          <Label for="submitRecoveryLink" className="sr-only">Send recovery link</Label>
          <div className="d-flex align-items-center">
            <Input id="submitRecoveryLink" color="primary" type="submit"
              className={classNames(styles.submitRecoveryLinkButton, styles.primaryButton)}
              value={this.state.actionButtonText}
              disabled={this.state.formDisabled}
              onClick={this.handleRecover}
              invalid={this.state.formErrored} />
            <FormFeedback className="text-left ml-3">{this.state.formErrorText}</FormFeedback>
          </div>
        </FormGroup>
      </Form>
    );
  } 
}

export default SendRecoveryLinkStep;
