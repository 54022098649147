import React from "react";
import { Collapse, ListGroup, ListGroupItem } from "reactstrap";
import classNames from "classnames";

import Icon from '../Icon.jsx';

class ControlPanelWidget extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = { open: props.open || false };
  }

  toggleDropdown()
  {
    this.setState({open : !this.state.open});
  }

  render()
  {
    let classList = "controlPanelWidget";
    classList += (this.state.open ? " open " : " ");
    classList += (this.props.className || "");

    return (
      <ListGroup>
        <ListGroupItem action={!this.props.static} 
          onClick={() => {
            if(!this.props.static)
              this.toggleDropdown();
          }}
          className={classNames({
              controlPanelWidget: true,
              open: this.state.open
            }, this.props.className)
          }
          id={this.props.id || ""}
        >
          <Icon icon={this.props.icon} color="dark" />
          <span>{this.props.text}</span>
          <Choose>
            <When condition={!this.props.static}>
              <span className={"caret mid ml-auto" + (this.state.open ? " open" : "")}></span>
            </When>
            <When condition={this.props.static && this.props.compact}>
              <span className={classNames(this.props.childClassName, "ml-auto")}>
                {this.props.children}
              </span>
            </When>
            <When condition={this.props.static}>
              <div className={classNames(this.props.childClassName)}>
                {this.props.children}
              </div>
            </When>
          </Choose>
        </ListGroupItem>
        <If condition={!this.props.static}>
          <Collapse isOpen={this.state.open}>
            <ListGroupItem className={classNames(this.props.childClassName)}>
              {this.props.children}
            </ListGroupItem>
          </Collapse>
        </If>
      </ListGroup>
    )
  }
}

export default ControlPanelWidget;
