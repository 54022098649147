import React from 'react';
import { connect } from 'react-redux';
import { Map, List } from "immutable";
import moment from "moment";

import API from "../../api.js";

import { getActiveBooking, getActiveBookedResources, getActiveTimezone }
  from "../../selectors";
import { selectBookingResource } from "../../actions";

import Icon from '../Icon';
import { ListGroup, ListGroupItem, Badge, UncontrolledTooltip } from 'reactstrap';

import ResourceTools from './ResourceTools';
import BookingTimeControls from './BookingTimeControls';
import ResourceImageIcon from './ResourceImageIcon';

const BookedResourceList = ({activeBooking, activeBookedResources, selectedBookedResource, selectBookingResource, resources, mode, activeTimezone }) => {
  API.log("rendering", "booked resource list");

  const bookingID = activeBooking.id;
  const bookingLocalStatus = activeBooking.getLocalStatus();

  return (
    <Choose>
      <When condition={activeBooking && !activeBookedResources.isEmpty()}>
        <ListGroup flush>
        {
          activeBookedResources.valueSeq().map(bRes => {
            const isResSelected = (bRes.id === selectedBookedResource);
            const resStatus = bRes.getLocalStatus(bookingLocalStatus, mode);
            const resActive = resStatus.isActive;

            const startDateTime = moment.tz(bRes.adjustedStart || bRes.start,
              activeTimezone);
            const endDateTime = moment.tz(bRes.adjustedEnd || bRes.end,
              activeTimezone);

            const resource = resources.get(bRes.ResourceId);

            return (
              <ListGroupItem
                action
                tag="div"
                key={bRes.id}
                className={"resourceWidget" + (isResSelected ? " selected" : "")
                            + (!resActive ? " inactive" : "")}
                onClick={() => {
                  if(resActive)
                  {
                    selectBookingResource(bookingID, bRes.id)
                  }
                }}
              >
                <div className="name">
                  <span>
                    { resource.image && <ResourceImageIcon resource={resource} /> }
                    { resource.name } <small>({ resStatus.name })</small>
                  </span>
                </div>
                <ResourceTools
                  booking={activeBooking}
                  bookedResource={bRes}
                  isActive={resActive}
                />
                <If condition={ isResSelected && resActive }>
                  <BookingTimeControls bookingID={bookingID} bookedResourceID={bRes.id}
                    start={startDateTime} end={endDateTime} />
                </If>
              </ListGroupItem>
            );
          })
        }
        </ListGroup>
      </When>
      <Otherwise>
        <div className="panelStatusMessage mt-auto">
          No resources selected
        </div>
      </Otherwise>
    </Choose>
  )
}

const mapStateToProps = (state) => {
  return {
    mode: state.mode,
    resources: state.resources,
    activeBooking: getActiveBooking(state),
    activeBookedResources: getActiveBookedResources(state),
    selectedBookedResource: state.selectedBookedResource,
    activeTimezone: getActiveTimezone(state)
  }
}

const BookedResourceListContainer = connect(
  mapStateToProps,
  { selectBookingResource }
)(BookedResourceList)

export default BookedResourceListContainer;
