import React from 'react';
import { connect } from 'react-redux';
import { push } from 'redux-first-router';

import classNames from "classnames";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem }
  from "reactstrap";

import Icon from '../Icon.jsx'
import { setDepartment, reset } from "../../actions.js";

import styles from "./index.scss";

const OperatorToolsPresentational = ({config, mode, setMode, operator, departments, selectedDepartment, setDepartment, onStartTour}) => (
  <UncontrolledDropdown>
    <DropdownToggle className={styles.dropdownToggle} caret color="neutral">
      <Icon icon="user-tie" color="dark" size={20} />
      {" "}
      <span>
        <strong>{operator.get("name")}</strong>
        {" "}
        <small>
          {departments.getIn([selectedDepartment, "name"], "...")}
        </small>
      </span>
    </DropdownToggle>
    <DropdownMenu>
      <DropdownItem header>Departments</DropdownItem>
      {
        departments.valueSeq()
        .filter(d => !config.hideDisabledDepartments || d.config.enableUserPortal)
        .map(department => 
          <DropdownItem
            key={department.id}
            className={classNames({ "selected": selectedDepartment === department.id })}
            onClick={() => setDepartment(department.id)}
          >
            {department.name}
          </DropdownItem>
        )
      }
      <DropdownItem divider />
      {
        config.allowManage &&
        <DropdownItem onClick={() => { push("/manage") }}>
          Manage
        </DropdownItem>
      }
      {
        config.showTour &&
        <DropdownItem onClick={onStartTour}>
          Show Tour
        </DropdownItem>
      }
      <DropdownItem onClick={() => { push("/logout") }}>
        Logout
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const mapStateToProps = state => {
  return {
    config: state.config,
    mode: state.mode,
    operator: state.operator,
    departments: state.departments,
    selectedDepartment: state.selectedDepartment
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setDepartment: (department) => {
      dispatch(setDepartment(department));
      dispatch(reset());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperatorToolsPresentational)
