import React from 'react';
import { connect } from 'react-redux';

import { Booking, BookedResource } from "../records.js";
import { returnBookingOrResourcesRaw, activateBooking } from "../actions.js";

import MessageBox from "./MessageBox.jsx";

const mapDispatchToProps = {
  returnResources: (booking) => {
    return (dispatch) => {
      const bookedResourceIDs = booking.BookedResources.map(br => br.id);
      return API.get("booking/" + booking.id).then(booking => {
        return dispatch(returnBookingOrResourcesRaw(
          Booking.makeRecord(booking),
          BookedResource.makeRecords(booking.BookedResources),
          bookedResourceIDs
        ));
      });
    }
  },
  activateBooking: (booking) => {
    return (dispatch) => {
      return API.get("booking/" + booking.id).then(booking => {
        return dispatch(activateBooking(
          Booking.makeRecord(booking),
          BookedResource.makeRecords(booking.BookedResources),
        ));
      });
    }
  }
}

const listResources = booking => (
    <ul> {booking.BookedResources.map(br => <li key={br.id}>{br.Resource.name}</li>)} </ul>
)

export const RapidReturn = connect(null, mapDispatchToProps)(
  ({ booking, returnResources, toggle }) => (
  booking && <MessageBox 
    showing={!!booking} 
    title="Rapid return"
    body={
      <React.Fragment>
        <p>Please make sure these resources are being returned by {booking.Keeper.name}:</p>
        {listResources(booking)}
      </React.Fragment>
    }
    onOk={() => returnResources(booking).then(toggle)}
    okText="Return"
    toggle={toggle}
  />
));

export const RapidActivate = connect(null, mapDispatchToProps)(
  ({ booking, activateBooking, toggle }) => (
  booking && <MessageBox 
    showing={!!booking} 
    title="Rapid activate"
    body={
      <React.Fragment>
        <p>Please hand the following resources to {booking.Keeper.name}:</p>
        {listResources(booking)}
      </React.Fragment>
    }
    onOk={() => activateBooking(booking).then(toggle)}
    okText="Activate"
    toggle={toggle}
  />
));

