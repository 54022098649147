import React from 'react';
import Link from 'redux-first-router-link';
import { ListGroupItem } from "reactstrap";

import Icon from "../Icon";

const stopPropagation = clickHandler => event => {
	event.stopPropagation();
	clickHandler(event);
}

const NearestBooking = ({userName, time, color, bookedResources, action, bookingLink, onSelectBooking, onRapidAction }) => {
	const resourceList = bookedResources.length > 2
		? bookedResources.slice(0, 2).map(br => br.Resource.name).join(", ").concat(` and ${bookedResources.length - 2} more`)
		: bookedResources.map(br => br.Resource.name).join(", ");
	return (
	  <ListGroupItem
	  	action
	  	color={color}
	  	className="nearestBooking"
	  	onClick={onSelectBooking}
	  >
	    <div className="nearestBookingTime">{time}</div>
	    <div className="nearestBookingUser">
	      <strong>{userName}</strong>
	      {" "}
	      (<small>{resourceList}</small>)
	    </div>
	    <div className="nearestBookingControls">
		    {
		    	action === "return"
		    		? <a title="Return" onClick={stopPropagation(onRapidAction)}><Icon icon="reply" className="returnResource" /></a>
		      	: <a title="Activate" onClick={stopPropagation(onRapidAction)}><Icon icon="redo2" className="activateResource" /></a>
		    }
	    </div>
	  </ListGroupItem>
	);
}

export default NearestBooking;
