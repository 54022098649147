import { getNearbyClosedTimes } from "../selectors";

export const clearErrors = () => {
  return { type: 'CLEAR_ERRORS' };
}

export const handleValidationErrors = (errors) => {
  return dispatch => {
    dispatch(clearErrors());
    for(let i = 0; i < errors.length; i++)
    {
      const e = errors[i];
      dispatch(raiseError(e.code, e.message, e.data, e.severity));
    }
  }
}

export const raiseError = (code, message, data, severity) => {
  return (dispatch, getState) => {
    const error = { code, message, data, severity };
    const state = getState();
    let conflictData = {};

    switch(code)
    {
      case "BOOKING_OUTSIDE_SERVICE_HOURS":
        conflictData = { nearbyClosedTimes: getNearbyClosedTimes(state) };
      break;
      case "RESOURCE_NOT_AVAILABLE":
        conflictData = { unavailableResource: data.unavailableResource };
      break;
      case "BOOKING_CLASHES":
        conflictData = { unavailableResource: data.clashingResource };
      break;
      default:
        conflictData = {};
    }

    return dispatch({ type: 'RAISE_ERROR', error, conflictData });
  }
}

// pass null for resourceID to attach warning to entire booking
export const addBookingWarning = (bookingID, bookedResourceID, warningCode) => {
  return { type: 'ADD_BOOKING_WARNING', bookingID, bookedResourceID, warningCode }
}

export const removeBookingWarning = (bookingID, bookedResourceID, warningCode) => {
  return { type: 'REMOVE_BOOKING_WARNING', bookingID, bookedResourceID, warningCode }
}
