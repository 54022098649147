import React from "react";
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Label, Input, Form,
  FormGroup, FormFeedback, FormText, Container } from "reactstrap";
import Step from "./Step";

import classNames from "classnames";
import styles from "./index.scss";

class ChangePasswordStep extends React.Component {
  state = {
    formData: {
      password: "",
      passwordConfirm: ""
    },
    passwordInvalid: false,
    passwordConfirmInvalid: false,
    formDisabled: false
  }

  handleFieldChange = (event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value
      }
    });
  }

  validatePassword(password) {
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?!.*[\s])(?=.{8,50})/
    return Boolean(password.match(regex));
  }

  handleChangePassword = (event) => {
    event.preventDefault();

    const { password, passwordConfirm } = this.state.formData;
    if (!this.validatePassword(password)) {
      return this.setState({
        passwordInvalid: true,
        passwordConfirmInvalid: false
      });
    }
    if (password !== passwordConfirm) {
      return this.setState({
        passwordInvalid: false,
        passwordConfirmInvalid: true
      });
    }

    this.setState({
      formDisabled: true,
      passwordInvalid: false,
      passwordConfirmInvalid: false
    });
    this.props.handleChangePassword(password);
  }

  render() {
    const { handleSendRecoveryLink, done = false } = this.props;
    if (done) {
      return (
        <Step done={true} number={2} children={
          <React.Fragment>
            <Label className="mb-0">Your password was changed successfully. Head on to the login page to <a href="/login">log in</a> with your new credentials.</Label>
          </React.Fragment>
        } />
      );
    }

    return (
      <Form onSubmit={() => handleSendRecoveryLink(this.state.email)} className="mt-3 mb-0">
        <Step number={2} text="Enter your new password, and then once more to confirm." />
        <FormGroup>
          <Label for="password" className="sr-only">E-mail address</Label>
          <Input
            type="password"
            id="password"
            name="password"
            placeholder="Enter new password"
            disabled={this.state.formDisabled}
            autoFocus={true}
            className="mt-3"
            onChange={this.handleFieldChange}
            invalid={this.state.passwordInvalid} />
          <FormFeedback>That password doesn't look strong enough. It must be 8-50 characters with no spaces, but with at least 1 number and special character.</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="passwordConfirm" className="sr-only">E-mail address</Label>
          <Input
            type="password"
            id="passwordConfirm"
            name="passwordConfirm"
            placeholder="Confirm new password"
            disabled={this.state.formDisabled}
            className="mt-3"
            onChange={this.handleFieldChange}
            invalid={this.state.passwordConfirmInvalid} />
          <FormFeedback>That password doesn't match the first one.</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="submitChangePassword" className="sr-only">Reset password</Label>
          <Input id="submitChangePassword" type="submit"
            value="Reset password"
            className={styles.primaryButton}
            disabled={this.state.formDisabled}
            onClick={this.handleChangePassword} />
        </FormGroup>
      </Form>
    );
  } 
}

export default ChangePasswordStep;
