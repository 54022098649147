import React from 'react';
import { connect } from 'react-redux';

import { removeResourceFromBooking, returnBookingOrResource,
  renewBookingOrResource } from "../../actions";

import { UncontrolledTooltip } from 'reactstrap';
import Icon from '../Icon.jsx';

class ResourceTool extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    forceDisable: false
  }

  handleOnClick = (event, onClick) => {
    event.stopPropagation();
    this.setState({ forceDisable: true });
    Promise.resolve(onClick())
    .then(() => this.setState({ forceDisable: false }))
  }

  render() {
    const { onClick, icon, iconClassName, disabled } = this.props;
    return (
      <a className={disabled || this.state.forceDisable ? "disabled" : ""}
        onClick={event => this.handleOnClick(event, onClick)}>
        <Icon icon={icon} className={iconClassName} />
      </a>
    );
  }
}

const ResourceTools = ({ booking, bookedResource, isActive, mode, dispatch }) => {
  return (
    <div className="tools">
      <Choose>
        <When condition={ !isActive }>
          { /* output no tools for past/unknown status resources */ }
        </When>
        <When condition={mode === "create" || mode === "activate"}>
          <ResourceTool
            icon="cross"
            iconClassName="removeResource"
            onClick={() =>
              dispatch(removeResourceFromBooking(bookedResource.id))
            }
          />
        </When>
        <Otherwise>
          <ResourceTool
            icon="reply"
            iconClassName="returnResource"
            onClick={() =>
              dispatch(returnBookingOrResource(booking, bookedResource.id))
            }
          />
          <ResourceTool
            icon="loop2"
            iconClassName="renewResource"
            disabled={!bookedResource.isAdjusted()}
            onClick={() =>
              dispatch(renewBookingOrResource(booking, bookedResource.id))
            }
          />
        </Otherwise>
      </Choose>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    mode: state.mode
  }
}

const ResourceToolsContainer = connect(
  mapStateToProps,
)(ResourceTools)

export default ResourceToolsContainer;