import moment from "moment";
import { push } from 'redux-first-router';

import API from "../api";
import { saveBookedResources } from "../actions.js";

import { getActiveBooking, getActiveBookedResources, getActiveBookingID, getBarcodeResource,
  getNextBookedResourceID, getLatestEnd, getEarliestStart, getNearbyClosedTimes,
  getClosingTimeToday, getAutoCirculatingResources } from "../selectors";


import { handleValidationErrors, fetchResourcesUnavailable, fetchUsers, showNotification, raiseError } from "../actions.js";
import { prepareBookedResources, prepareBooking, validateBooking, saveBookingWithResources }
  from "./booking.tools.js";

/*

Booking logic flow:

1. Business-logic specific preparation (e.g. status = "active" for activate)
2. Preflight preparation (for all actions; e.g. set KeeperId)
3. Validation (checks may depend on action type, e.g. ignore clashes for return)
4a Save to DB if passes
4b Show validation errors if fails

*/

export const fetchBookings = (userID) => ({
  type: "FETCH_BOOKINGS",
  payload: API.get("bookings", {KeeperId: userID})
});

export const fetchBookingActors = (bookings) => {
  const actorIds = bookings.reduce((actors, booking) => {
    if (booking.KeeperId && !actors.includes(booking.KeeperId)) {
      actors.push(booking.KeeperId);
    }
    if (booking.ServicedById && !actors.includes(booking.ServicedById)) {
      actors.push(booking.ServicedById);
    }
    return actors;
  }, []);
  return fetchUsers({ id: actorIds });
};

export const initializePlaceholderBooking = () => {
  return (dispatch, getState) => {
    const state = getState();
    const booking = { KeeperId: state.user.id, ServicedById: state.operator.id };
    return dispatch({ type: "INITIALIZE_PLACEHOLDER_BOOKING", booking });
  }
}

export const runSaveSequence = (actionType, booking, bookedResources) => dispatch =>
  dispatch({
    type: actionType,
    payload: saveBookingWithResources(booking, bookedResources)
  })
  .then(result => {
    push("/app");
    dispatch(initializePlaceholderBooking());
    dispatch(fetchResourcesUnavailable());
    dispatch(fetchNearestBookings());
    return result;
  })
  .catch(result => {
    dispatch(showNotification({
      message: "Errors occurred while trying to save booking"
    }));

    if(result.errors)
      dispatch(handleValidationErrors(result.errors));

    return result;
  });

// Validates booking when changes are made (move start/end, add/remove resource)
export const preValidateBooking = () => {
  return (dispatch, getState) => {
    const state = getState();
    const booking = getActiveBooking(state);
    const bookedResources = getActiveBookedResources(state);
    const preparedBooking = prepareBooking(booking, bookedResources);
    const preparedBookedResources = prepareBookedResources(preparedBooking, bookedResources);

    dispatch({
      type: "PREVALIDATE_BOOKING",
      payload: validateBooking(preparedBooking, preparedBookedResources)
        .then(({ errors }) => dispatch(handleValidationErrors(errors)))
    });
  }
}


export const createBooking = (booking, bookedResources) => (dispatch, getState) => {
  const { allowCheckout, reservationOffset } = getState().config;
  if(!allowCheckout && !booking.isReservation(bookedResources))
  {
    dispatch(raiseError("CHECKOUTS_FORBIDDEN", `Only reservations are allowed - make sure
        all resources are booked at least ${reservationOffset} minutes ahead of now`, null, 2));
    return;
  }

  return dispatch(runSaveSequence("CREATE_BOOKING", booking, bookedResources))
  .then(result => {
    if(result.errors)
      return result.errors;
    dispatch({
      type: result.action.payload.booking.status === "reserved"
        ? "NOTIFY_BOOKING_RESERVED"
        : "NOTIFY_BOOKING_ACTIVE",
      booking: result.action.payload.booking,
      bookedResources: result.action.payload.bookedResources
    });
    return result;
  });
}

export const activateBooking = (booking, bookedResources) => {
  bookedResources = bookedResources.map(bookedRes =>
    bookedRes.merge({
      id: bookedRes.id < 0 ? undefined : bookedRes.id,
      start: moment()
    })
  );
  booking = booking.set("status", "active");

  return dispatch => dispatch(runSaveSequence(
    "ACTIVATE_BOOKING",
    booking,
    bookedResources
  ));
}

export const cancelBooking = (booking, bookedResources) => {
  booking = booking.set("status", "canceled");
  return dispatch => dispatch(runSaveSequence(
    "CANCEL_BOOKING",
    booking,
    bookedResources
  ));
}

export const updateBooking = (booking, bookedResources) => dispatch => 
  dispatch(runSaveSequence(
    "UPDATE_BOOKING",
    booking,
    bookedResources
  ));

export const setBookingNotes = (bookingID, notes) => {
  return { type: 'SET_BOOKING_NOTES', bookingID, notes } 
}

export const discardActiveBooking = () => {
  return { type: 'DISCARD_ACTIVE_BOOKING' }
}

export const discardBookings = () => {
  return { type: 'DISCARD_BOOKINGS' }
}

export const fetchNearestBookings = () => {
  return (dispatch, getState) =>
    getState().config.enableNearestBookings ? dispatch({
      type: "FETCH_NEAREST_BOOKINGS",
      payload: API.get("nearestBookings", {
        DepartmentId: getState().selectedDepartment,
        time: moment().toISOString()
      })
  }) : null;
}
