import React from 'react';
import { connect } from 'react-redux';
import Dropzone from "react-dropzone";
import classNames from "classnames";
import _ from "underscore";

import API from "../../../api.js";
import { showNotification } from '../../../actions';
import { Resource } from '../../../records.js';
import CardOverlayLoader from "../../CardOverlayLoader.jsx";
import MetaFieldEditor from "../MetaFieldEditor.jsx";
import CategorySelect from "../CategorySelect.jsx";
import FormElement from "../FormElement.jsx";
import Icon from "../../Icon.jsx";
import { ConstraintsPane } from "../ResourceConstraints";
import ImageTools from "./imageUtils";
import { postForm } from "./uploadUtils";

import styles from "./index.scss";

import { Card, CardHeader, CardBody, Button, ButtonGroup, 
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  ListGroup, ListGroupItem, Badge, FormGroup, Label, Col,
  FormText } from "reactstrap";


const resourceMetaMap = [
  {
    key: "autoCirculating",
    name: "Auto-circulating",
    type: "boolean"
  }
];

class ResourceEditor extends React.Component
{
  constructor(props)
  {
    super(props);
  }

  state = {
    resource: this.props.resource,
    resourceModified: false,
    imageUploadInProgress: false
  }

  componentWillReceiveProps(nextProps)
  {
    const { DepartmentId, resource } = nextProps;
    resource.DepartmentId = DepartmentId;
    this.setState({
      resource,
      resourceModified: false
    });
  }

  stripResource(resource)
  {
    return _.pick(resource, "id", "name", "barcode", "status", "CategoryId",
        "description", "defaultBookingTime", "instructions", "notes",
        "serialized", "meta");
  }

  getElementValue = (element) => {
    if(element.type === "checkbox" || element.type === "radio")
      return element.checked;
    else if(element.type === "number")
      return parseInt(element.value);
    else return element.value;
  }

  addConstraint = (constraint) => {
    this.setResourceConstraints([...this.state.resource.Constraints, constraint]);
  }

  updateConstraint = (constraint) => {
    const constraints = this.state.resource.Constraints.map(c => c.id == constraint.id ? constraint : c);
    this.setResourceConstraints(constraints);
  }

  deleteConstraint = (constraint) => {
    const constraints = this.state.resource.Constraints.filter(c => c.id != constraint.id);
    this.setResourceConstraints(constraints);
  }

  setResourceConstraints = (constraints) => {
    this.setState({
      resourceModified: true,
      resource: {
        ...this.state.resource,
        Constraints: constraints
      }
    });
  }

  handleFieldChange = (event) => {
    this.setState({
      resourceModified: true,
      resource: {
        ...this.state.resource, 
        [event.target.name]: this.getElementValue(event.target)
      }
    });
  }

  handleMetaChange = (event) => {
    this.setState({
      resourceModified: true,
      resource: {
        ...this.state.resource,
        meta: {
          ...this.state.resource.meta,
          [event.target.name]: this.getElementValue(event.target)
        }
      }
    });
  }

  handleUploadImage = (files) => {
    // TODO: show progress
    if (files.length > 1) {
      return this.props.dispatch(showNotification({
        message: "More than one file received. Please upload one image per resource only.",
        color: "danger"
      }));
    } else if (files.length == 0) {
      return this.props.dispatch(showNotification({
        message: "No files were selected. Please upload exactly one image per resource.",
        color: "danger"
      }));
    }

    if (files[0].size > 3 * 1024 * 1024) {
      return this.props.dispatch(showNotification({
        message: "The image selected is larger than 3MB. Please upload a smaller image.",
        color: "danger"
      }));
    }

    this.setState({
      imageUploadInProgress: true,
      resourceModified: true
    });

    const imageFile = files[0];
    const imageSizeConfigs = [
      { size: "original" },
      { size: "large", maxWidth: 1200, maxHeight: 900 },
      { size: "small", maxWidth: 600, maxHeight: 450 },
      { size: "thumb", maxWidth: 250, maxHeight: 250 }
    ];
    const imageTools = new ImageTools();

    const processImagePromises = imageSizeConfigs.map(config => {
      if (!config.maxWidth && !config.maxHeight) {
        return Promise.resolve({
          size: config.size,
          name: imageFile.name,
          blob: imageFile
        });
      }

      return imageTools.resize(imageFile, { width: config.maxWidth, height: config.maxHeight })
        .then(blob => ({
          size: config.size,
          name: imageFile.name,
          blob
        }));
    });

    return Promise.all(processImagePromises)
      .then(resizedImages => {
        const imageObjects = resizedImages.map(image => ({ name: image.name, size: image.size }));
        return API.post("images", { images: imageObjects })
          .then(presignedPostRequests =>
            Promise.all(presignedPostRequests.map((request, i) =>
              postForm(API, request.url, { file: resizedImages[i].blob, ...request.fields })
                .then(awsResponse => ({
                  awsResponse,
                  generatedImageKey: request.fields.key
                }))
            ))
          );
      })
      .then(responses => {
        const invalidResponses = responses.filter(({ awsResponse }) => !awsResponse.ok)
        if (invalidResponses.length > 0) {
          this.props.dispatch(showNotification({
            message: `Error: ${invalidResponses}`,
            color: "danger"
          }));
          return;
        }

        const genericImageKey = responses[0].generatedImageKey.split("/")[1];
        this.setState({
          resource: {
            ...this.state.resource,
            image: genericImageKey,
          },
          imageUploadInProgress: false
        })
      })
      .catch(e => {
        this.props.dispatch(showNotification({
          message: `Error: ${e.message || e.error}`,
          color: "danger"
        }));
        this.setState({
          imageUploadInProgress: false
        });
      });
  }

  saveResource = () => {
    return this.props.saveResource(this.state.resource)
      .catch(e => {
        this.setState({ resourceModified: true })
      });
  }

  render()
  {
    const { resource, resourceModified, imageUploadInProgress } = this.state;
    const headerText = resource.id ? resource.name : "Creating a new resource";

    return <Card>
      <CardHeader className="d-flex align-items-center">
        {headerText}
        <div className="ml-auto">
          <small className="mr-3">{resourceModified && "Unsaved changes"}</small>
          <ButtonGroup>
            <Button size="sm" onClick={this.props.onClear}>
              <Icon icon="redo2" />&nbsp;Reset
            </Button>
            { resource.id &&
              <Button onClick={() => this.props.deleteResource(resource)} size="sm" color="danger">
                <Icon icon="cross" />&nbsp;Delete
              </Button>
            }
            <Button size="sm" color="success" onClick={this.saveResource}>
              <Icon icon="checkmark" />&nbsp;Save
            </Button>
          </ButtonGroup>
        </div>
      </CardHeader>
      {this.props.pending && <CardOverlayLoader />}
      <CardBody>
        <FormElement name="name" label="Name" type="text" value={resource.name}
          onChange={this.handleFieldChange} />

        <FormGroup row>
          <Label for="image" sm={3}>Image</Label>
          <Col sm={9}>
            {
              resource.image
                ? <div
                  className={styles.uploadedImage}
                  style={{backgroundImage: `url(${API.generateLink("images/" + resource.image,
                    { size: "small" }, true)})`}}
                />
                : <p>No image set</p>
            }
            <Dropzone onDrop={this.handleUploadImage} disabled={imageUploadInProgress}>
              {({getRootProps, getInputProps, isDragActive}) => {
                return (
                  <div
                    {...getRootProps()}
                    className={classNames(styles.dropZone, {[styles.dropZoneDragActive]: isDragActive, [styles.dropZoneDisabled]: imageUploadInProgress})}
                  >
                    <input {...getInputProps()} />
                    {
                      this.state.imageUploadInProgress
                        ? <span>Uploading image, please wait...</span>
                        : <span>Drop image here to {resource.image ? "replace existing" : "upload"}{isDragActive ? "..." : ", or click to select from files"}</span>
                    }
                  </div>
                )
              }}
            </Dropzone>
            <FormText className="align-self-start" color="muted">
              Allowed formats: JPEG, PNG. Max size: 3MB. Images at least 1200x900px in size with a white or transparent background work best.
            </FormText>
          </Col>
        </FormGroup>

        <FormElement name="barcode" label="Barcode" type="text"
          value={resource.barcode}
          onChange={this.handleFieldChange} />

        <CategorySelect name="CategoryId" label="Category" disableParents
          categories={this.props.categories} 
          value={resource.CategoryId}
          onChange={this.handleFieldChange} />

        <FormElement name="status" label="Status" type="select"
          value={resource.status}
          onChange={this.handleFieldChange}
        >
          <option key="1" value="active">Active</option>
          <option key="2" value="inactive">Inactive</option>
        </FormElement>

        <FormElement name="description" label="Description" type="text"
          value={resource.description}
          onChange={this.handleFieldChange} />

        <FormElement name="instructions" label="Instructions" type="textarea"
          value={resource.instructions}
          onChange={this.handleFieldChange} />

        <FormElement name="notes" label="Notes" type="textarea"
          value={resource.notes}
          onChange={this.handleFieldChange} />

        <FormElement name="defaultBookingTime"
          label="Default booking time" type="number"
          value={resource.defaultBookingTime}
          instructions="Indicate in minutes. Enter -1 for end of day."
          onChange={this.handleFieldChange} />

        <FormElement name="serialized" label="Serialized" type="checkbox"
          value={resource.serialized} onChange={this.handleFieldChange} />

        <ConstraintsPane 
          constraints={resource.Constraints} 
          onAdd={this.addConstraint}
          onChange={this.updateConstraint}
          onDelete={this.deleteConstraint}
        />
        <MetaFieldEditor entity={resource} metaMap={resourceMetaMap}
          handleMetaChange={this.handleMetaChange} />
      </CardBody>
    </Card>
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.categories.filter(cat =>
      cat.DepartmentId === state.selectedDepartment
    ),
    DepartmentId: state.selectedDepartment
  }
}

const ResourceEditorContainer = connect(
  mapStateToProps
)(ResourceEditor)


export default ResourceEditorContainer;
