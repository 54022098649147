import moment from "moment";

export const displayTime = listItem => {
  if (listItem === "overdue") return moment().add(999, "d");
  if (listItem === "active" || listItem === "completed") return moment();
  if (listItem === "upcoming" || listItem === "complete") return moment();
  if (listItem === "overdue for pickup") return moment().subtract(999, "d");
  const timeField = {
    active: "bookedEnd",
    complete: "bookedEnd",
    canceled: "bookedStart",
    noshow: "bookedStart",
    reserved: "bookedStart"
  };
  const timeValue = listItem.BookedResources[0][timeField[listItem.status]];
  const bookingTime = moment(timeValue);
  return bookingTime;
}

export const displayTimeFormatted = (booking, activeTimezone) => {
  const time = displayTime(booking);
  if(moment().isSame(time, "day"))
    return moment.tz(time, activeTimezone).format("h:mm a");
  else 
    return moment.tz(time, activeTimezone).format("ddd h:mm a");
}

export const generateBookingLink = booking => 
  `/app/${booking.status == "reserved" ? "activate" : "return"}/
${booking.KeeperId}/${booking.id}/-1`;

export const generateBookingAction = booking => ({
  type: "APP_BOOKING",
  payload: {
    mode: booking.status == "reserved" ? "activate" : "return",
    userId: booking.KeeperId,
    bookingId: booking.id,
    bookedResourceId: -1
  }
});

export const bookingRowColor = (time, status) => {
  const late = moment() > time;
  if(status === "reserved" && late)
    return "warning";
  else if(status === "active" && late)
    return "danger";
  return "light";
}

export const bookingRowTextColor = (time, status) => {
  if (status === "complete" || status === "canceled" || status === "noshow") {
    return "dim";
  }
  return "default";
}


export const flatToNested = (bookings, resources, bookedResources, user, selectedDepartmentId) => {
  return bookings.toList()
    .map(booking => {
      booking = booking.toJS();
      booking.Keeper = user.toJS();
      booking.BookedResources = bookedResources
        .filter(bookedResource => bookedResource.BookingId === booking.id)
        .map(bookedResource => bookedResource.set(
          "Resource",
          resources.find(resource => resource.id === bookedResource.ResourceId))
        )
        .filter(bookedResource => bookedResource.Resource && bookedResource.Resource.DepartmentId === selectedDepartmentId)
        .valueSeq()
        .toJS();
      if (booking.BookedResources.length === 0) {
        return undefined;
      }
      return booking;
    })
    .filter(booking => booking && booking.status !== "tentative");
}

export const getReferenceTime = booking => {
  const timeField = {
    active: "bookedEnd",
    complete: "bookedEnd",
    canceled: "bookedStart",
    noshow: "bookedStart",
    reserved: "bookedStart"
  };
  const timeValue = booking.BookedResources[0][timeField[booking.status]];
  const bookingTime = moment(timeValue);
  return bookingTime;
}

export const sortBookingsDescending = (bookingA, bookingB) =>
  getReferenceTime(bookingA) > getReferenceTime(bookingB)
    ? -1
    : 1;

export const sortBookingsAscending = (bookingA, bookingB) => 
  getReferenceTime(bookingA) > getReferenceTime(bookingB)
    ? -1
    : 1;  

export const sortGroupedBookings = groupedBookings =>
  Object.entries(groupedBookings).reduce((sortedBookings, [group, bookings]) => {
    sortedBookings[group] = bookings.sort(sortBookingsDescending);
    return sortedBookings;
  }, {});
