import React from "react";
import { connect } from 'react-redux';
import { activateBooking } from '../../../actions';
import { getActiveBooking } from '../../../selectors';
import BookingControlButton from './BookingControlButton'

const ActivateBookingButton = ({ user, booking, bookedResources, dispatch }) => (
  <BookingControlButton
    bsStyle="success" icon="checkmark" caption="Activate"
    disabled={bookedResources.size === 0 || user.id === -1}
    clickHandler={() => dispatch(activateBooking(booking, bookedResources))}
  />
)

const ActivateBookingButtonContainer = connect()(ActivateBookingButton)

export default ActivateBookingButtonContainer;
