import React from 'react';

import { connect } from 'react-redux';
import { toggleParentCategory, selectCategory } from '../actions';

import Icon from './Icon.jsx';
import GenericSpinner from "./GenericSpinner.jsx";
import { Card, CardHeader, CardBody, Collapse, ListGroup, ListGroupItem }
  from "reactstrap";

const Category = ({item, selected, select, toggle, parent, className}) => (
  <ListGroupItem
    onClick={() => {
      if(parent) toggle(item.id)
      else select(item.id)
    }}
    tag="div"
    className={"d-flex justify-content-start " + className}
    active={item.id === selected}
    action
  >
    <span>{/*<Icon icon="camera" /> */}{item.name}</span>
    <If condition={toggle}>
      <div className="ml-auto">
        {parent && 
          <span className={"caret mid" + (item.open ? " open" : "")}></span>
        }
      </div>
    </If>
  </ListGroupItem>
)

const CategorySubgroup = ({allCategories, subgroup, open, selected, select, toggle, depth}) => (
  <Collapse isOpen={open}>
    <ListGroup flush>
    {
      subgroup.sort((a, b) => a.name.localeCompare(b.name))
      .valueSeq()
      .map(category => ( 
        <CategoryContainer
          allCategories={allCategories}
          category={category}
          key={"cat-" + category.id}
          selected={selected}
          select={select}
          toggle={toggle}
          depth={depth}
        />
      ))
    }
    </ListGroup>
  </Collapse>
)

const CategoryContainer = ({allCategories, category, selectedCategory, select, toggle, depth}) => {
  const isOpen = allCategories ? allCategories.get(category.id).open : false;
  return (
    category.Categories.size > 0
    ? <ListGroup key={"subgroup-" + category.id} flush>
      <Category parent item={category} selected={selectedCategory}
        open={isOpen} select={select} toggle={toggle}
        className={"cat-depth-" + depth} />
      <CategorySubgroup
        subgroup={category.Categories}
        open={isOpen}
        selected={selectedCategory}
        allCategories={allCategories}
        select={select} toggle={toggle} depth={depth+1}
      />
    </ListGroup>
    : <Category key={"cat-" + category.id} className={"cat-depth-" + depth}
      item={category} selected={selectedCategory} select={select} />
  );
}

const ResourceCategories = ({categories, pending, selectedCategory, selectedDepartment, toggle, select}) => (
  <Card className="resourceCategories">
    <CardHeader>Categories</CardHeader>
    <Choose>
      <When condition={pending}>
        <GenericSpinner />
      </When>
      <Otherwise>
        <ListGroup flush>
        {
          categories.toList()
          .filter(cat => !cat.ParentId && cat.DepartmentId == selectedDepartment)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(category => 
            <CategoryContainer
              key={"cat-" + category.id}
              allCategories={categories}
              category={category}
              selectedCategory={selectedCategory}
              select={select}
              toggle={toggle}
              depth={1}
            />
          )
        }
        </ListGroup>
      </Otherwise>
    </Choose>
  </Card>
)

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    selectedCategory: state.selectedCategory,
    selectedDepartment: state.selectedDepartment,
    pending: state.pending.categories
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // toggle is for parent categories only
    toggle: (catItem) => ownProps.toggle ? ownProps.toggle(catItem) : dispatch(toggleParentCategory(catItem)),
    select: (catItem) => ownProps.select ? ownProps.select(catItem) : dispatch(selectCategory(catItem))
  }
}

const ResourceCategoriesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceCategories)

export default ResourceCategoriesContainer;
