const moment = require('moment-timezone');
const _ = require("lodash");
const { outsideAnyServiceSchedule } = require("./scheduleUtils");
const { resolveGroupConstraints } = require("./constraintUtils");

const flatten = arr => arr.reduce(
  (a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []
);

const getServiceSchedulesAPI = API => API.get("serviceSchedules");
const getServiceSchedulesDB = db => () => db.ServiceSchedule.findAll()
  .then(serviceSchedules => serviceSchedules.map(ss =>
    ss.get({plain: true})
  ));

const getDepartmentsAPI = API => API.get("departments");
const getDepartmentsDB = db => () => db.Department.findAll()
  .then(departments => departments.map(department =>
    department.get({plain: true})
  ));

const getDepartmentIdAPI = API => (booking, bookedResources) => 
  API.get(`resource/${bookedResources[0].ResourceId}`, {includeDeleted: true})
  .then(resource => resource.DepartmentId);
const getDepartmentIdDB = db => (booking, bookedResources) => 
  db.Resource.findById(bookedResources[0].ResourceId, {paranoid: false})
  .then(resource => resource.DepartmentId);

const checkResourceAvailableAPI = (API) => (bookedResource, time) => 
{
  return API.get("resourceAvailable", {
    resourceId: bookedResource.ResourceId,
    thisId: bookedResource.id,
    time,
  });
}
const checkResourceAvailableDB = (db) => {
  return (bookedResource, time) => {
    return db.BookedResource.takenBy(bookedResource.ResourceId, bookedResource.id, time);
  };
}

const resourcesDeletedAPI = (API) => (bookedResources) => 
{
  const ids = bookedResources.map(br => br.ResourceId);
  return API.get("resourcesDeleted", { resources: ids });

}
const resourcesDeletedDB = (db) => (bookedResources) => 
{
  const ids = bookedResources.map(br => br.ResourceId);
  return db.Resource.findAll({ paranoid: false, where: { id: {$in: ids}, deletedAt: { $ne: null } } });
}

const getClashesAPI = (API) => 
{
  return (bookedResource) => 
  {
    return API.get("clashes", {
      start: moment(bookedResource.start).toISOString(),
      end: moment(bookedResource.end).toISOString(),
      resourceId: bookedResource.ResourceId
    });
  }
}
const getClashesDB = (db) => 
{
  return (bookedResource) => 
  {
    let {start, end, ResourceId} = bookedResource;
    return db.BookedResource.getClashes(start, end, ResourceId)
    .then(clashes => clashes.map(clash => clash.get({plain: true})));
  }
}


//TODO: one db query
const getUserGroupsDB = (db) => UserId => {
  return db.User.findById(UserId).then(user => user.getGroups());
}

const getUserDB = (db) => UserId => {
  return db.User.findById(UserId);
}

//
const isTypeIn = (type, constraintsList) => constraintsList.find(c => c.type === type);
const combineTypes = (higher, lower) => [...higher.filter(c => !isTypeIn(c.type, lower)), ...lower];

const getResourcesWithConstraintsDB = (db) => bookedResources => {
  const constraintInclude = as => ({ model: db.Constraint, include: [db.Group], as });
  return Promise.all(
    bookedResources.map(bookedResource =>
      db.Resource.find({
        where: { id: bookedResource.ResourceId }, 
        include: [
          { model: db.Category, include: [constraintInclude("categoryConstraints")] },
          { model: db.Department, include: [constraintInclude("departmentConstraints")] },
          constraintInclude()
        ]
      })
      .then(resource => {
        resource = resource.get({ plain: true });
        const order = [
          resource.Department.departmentConstraints,
          resource.Category.categoryConstraints,
          resource.Constraints
        ];
        resource.Constraints = order.reduce(combineTypes);
        return resource;
      })
      .then(resource => ({ ...bookedResource, Resource: resource}))
    )
  );
}

const BookingValidator = new function()
{
  this.initialized = false,
  this.API = false,
  this.initialize = ({ db, API }) =>
  {
    if(db)
    {
      this.db = db;
      this.getClashes = getClashesDB(db);
      this.checkResourceAvailable = checkResourceAvailableDB(db);
      this.resourcesDeleted = resourcesDeletedDB(db);
      this.getDepartmentId = getDepartmentIdDB(db);
      this.getUserGroups = getUserGroupsDB(db);
      this.getUser = getUserDB(db);
      this.getResourcesWithConstraints = getResourcesWithConstraintsDB(db);
      this.getServiceSchedules = getServiceSchedulesDB(db);
      return Promise.all([
        getServiceSchedulesDB(db)(),
        getDepartmentsDB(db)()
      ]).then(([serviceSchedules, departments]) => {
        this.serviceSchedules = serviceSchedules;
        this.departments = departments;
        this.initialized = true;
      });
    }
    else if(API)
    {
      this.initialized = true;
      this.API = API;
      /*
      this.getClashes = getClashesAPI(API);
      this.checkResourceAvailable = checkResourceAvailableAPI(API);
      this.resourcesDeleted = resourcesDeletedAPI(API);
      this.getDepartmentId = getDepartmentIdAPI(API);
      return Promise.all([
        getServiceSchedulesAPI(API),
        getDepartmentsAPI(API)
      ]).then(([serviceSchedules, departments]) => {
        this.serviceSchedules = serviceSchedules;
        this.departments = departments;
      })
      */
    }
  },

  this.refreshServiceSchedules = () => {
    return this.getServiceSchedules().then((serviceSchedules) => {
      this.serviceSchedules = serviceSchedules;
    });
  }

  this.checkConstraints = ({ booking, bookedResources, department: { timezone, config }, operator }) => {
    if(booking.KeeperId && booking.KeeperId > -1)
    {
      let errors = [];
      const defaultConstraintSeverity = {
        "admin": {
          "group": 1,
          "duration": 1,
          "time": 1,
          "frequency": 1
        },
        "staff": {
          "group": 2,
          "duration": 2,
          "time": 2,
          "frequency": 2
        },
        "patron": {
          "group": 2,
          "duration": 2,
          "time": 2,
          "frequency": 2
        }
      }
      const departmentConstraintSeverity = "constraintSeverity" in config ? config["constraintSeverity"] : {}
      const constraintSeverity = _.merge(defaultConstraintSeverity, departmentConstraintSeverity)
      const roleConstraintConfig = operator.role in constraintSeverity ? constraintSeverity[operator.role] : {};

      return this.getUserGroups(booking.KeeperId).then(userGroups => {
        bookedResources.forEach(br => {
          const resource = br.Resource;

          // resolve group constraints first
          errors = errors.concat(resolveGroupConstraints(userGroups, resource, roleConstraintConfig["group"]));

          br.Resource.Constraints.forEach(constraint => {
            switch(constraint.type)
            {
              case "duration":
                const { maxDuration, minDuration } = constraint.definition;
                const minActual = moment(br.bookedEnd).add(1, "minute").diff(br.bookedStart, minDuration.unit, true);
                const maxActual = moment(br.bookedEnd).diff(br.bookedStart, maxDuration.unit, true);
                const minViolated = minActual < minDuration.value;
                const maxViolated = maxActual > maxDuration.value;
                if(minViolated || maxViolated)
                  errors.push(Promise.resolve({
                    code: "CONSTRAINT_VIOLATED",
                    data: { resource, constraint, minViolated, maxViolated },
                    severity: roleConstraintConfig["duration"]
                  }));
              break;
              case "time":
                let { fromTime, toTime } = constraint.definition;
                const format = "h:mm a";
                const onlyTime = dateTime => 
                    moment({h: dateTime.hours(), m: dateTime.minutes()});

                fromTime = onlyTime(moment.tz(fromTime, format, timezone));
                toTime = onlyTime(moment.tz(toTime, format, timezone));

                const bookedStart = onlyTime(moment.tz(br.bookedStart, timezone));
                const bookedEnd = onlyTime(moment.tz(br.bookedEnd, timezone));

                if(!bookedStart.isBetween(fromTime, toTime, null, "[]")
                  || !bookedEnd.isBetween(fromTime, toTime, null, "[]")) 
                {
                  errors.push(Promise.resolve({
                    code: "CONSTRAINT_VIOLATED",
                    data: { resource, constraint },
                    severity: roleConstraintConfig["time"]
                  }));
                }
              break;
              case "frequency":
                let { n, per } = constraint.definition;
                const unit = per === "week" ? "isoweek" : per;
                const from = moment.tz(br.bookedStart, timezone).startOf(unit).toISOString();
                const to = moment.tz(br.bookedEnd, timezone).endOf(unit).toISOString();
                errors.push(this.db.BookedResource.count({
                  where: {
                    bookedStart: { $between: [from, to] },
                    ResourceId: br.ResourceId
                  },
                  include: {
                    model: this.db.Booking,
                    where: {
                      status: {
                        $not: "canceled"
                      },
                      KeeperId: booking.KeeperId
                    }
                  }
                }).then(count => {
                  const isUpdatingExistingBooking = booking.id !== undefined;
                  const frequencyViolation = isUpdatingExistingBooking
                    ? count > n
                    : count >= n;

                  if(frequencyViolation)
                    return {
                      code: "CONSTRAINT_VIOLATED",
                      data: { resource, constraint, count },
                      severity: roleConstraintConfig["frequency"]
                    };
                }));
              break;
            }
          });
        });
        return Promise.all(errors);
      });
    }
    return Promise.resolve([]);
  },

  this.checkUserActive = ({ booking, operator }) => {
    if(booking.KeeperId && booking.KeeperId > -1)
    {
      return this.getUser(booking.KeeperId)
        .then(keeper => {
          if (keeper.status !== "active") {
            return [{
              code: "USER_NOT_ACTIVE",
              data: { user: keeper },
              severity: 2
            }];
          }
          return [];
        })
    }
    else
      return Promise.resolve([]);
  },

  this.checkServiceHours = ({ booking, bookedResources, department, serviceSchedules, operator }) =>
  {
    if(!this.serviceSchedules)
      return [];

    const serviceHoursConstraint = 
      (constraints) => constraints.some(constraint => constraint.type === "service_h");

    const outsideServiceTests = bookedResources.map(bookedResource =>
    {
      const severity = (
        serviceHoursConstraint(bookedResource.Resource.Constraints) 
        || (operator && operator.role === "patron")
      ) ? 2 : 1;
      return [
        {
          position: 'start',
          name: bookedResource.Resource.name,
          result: outsideAnyServiceSchedule(
            bookedResource.start,
            serviceSchedules,
            department.timezone
          ),
          severity
        },
        {
          position: 'end',
          name: bookedResource.Resource.name,
          result: outsideAnyServiceSchedule(
            bookedResource.end,
            serviceSchedules,
            department.timezone
          ),
          severity
        }
      ];
    });

    let errors = [];
    outsideServiceTests.forEach(ost => {
      ost.forEach(test => {
        if(test.result)
          errors.push({
            code: "BOOKING_OUTSIDE_SERVICE_HOURS",
            data: { test },
            severity: test.severity
          });
      })
    })
    return errors;
  },

  this.checkClashes = ({ booking, bookedResources }) => 
  {
    const clashPromises = bookedResources
      .filter(bookedRes => bookedRes.status !== "returned")
      .map(this.getClashes);
    const selfIds = bookedResources.map(bRes => bRes.id);

    let clashErrors = clashPromises.map(cp =>
      cp.then(clashingBookings => {
        clashingBookings = clashingBookings.filter(cb => 
          selfIds.indexOf(cb.id) == -1
        );
        if(clashingBookings.length > 0)
        {
          return {
           code: "BOOKING_CLASHES",
           data: { clashingResource: clashingBookings[0] } ,
           severity: 2
          };
        }
      })
    );
    return Promise.all(clashErrors);
  },

  this.checkItemsAvailable = ({ booking, bookedResources }) => 
  {
    const time = this.time || moment.utc().toISOString();
    const checkerPromises = bookedResources
      .filter(bookedResource => bookedResource.status !== "returned")
      .map(bookedResource => 
        {
          return this.checkResourceAvailable(bookedResource, time)
          .then(results => {
            return results.map(unavailableItem => {
              return {
                code: "RESOURCE_NOT_AVAILABLE",
                data: { unavailableResource: unavailableItem },
                severity: 2
              };
            });
          })
        }
    );
    return Promise.all(checkerPromises);
  },

  this.checkItemsNotDeleted = ({ booking, bookedResources }) => 
  {
    return this.resourcesDeleted(bookedResources)
    .then(resources => resources.map(resource => ({
        code: "RESOURCE_DELETED",
        data: resource,
        severity: 1
      }))
    );
  },

  this.validate = (booking, bookedResources, operator) =>
  {
    if(!this.initialized)
      throw new Error("Booking validator not initialized");

    if(this.API)
      return this.API.post("validateBooking", { booking, bookedResources, time: moment.utc() });

    //TODO: is this needed?
    if(!booking || !bookedResources || !bookedResources.length)
      return Promise.resolve([]);

    const bookingChecks = [this.checkServiceHours];
    if(booking.status === "active")
      bookingChecks.push(this.checkItemsAvailable);
    if(booking.status === "active" || booking.status === "reserved")
    {
      bookingChecks.push(this.checkConstraints);
      bookingChecks.push(this.checkClashes);
      bookingChecks.push(this.checkItemsNotDeleted);
      bookingChecks.push(this.checkUserActive);
    }

    return this.refreshServiceSchedules()
    .then(() => Promise.all([
      this.getDepartmentId(booking, bookedResources),
      this.getResourcesWithConstraints(bookedResources)
    ]))
    .then(([departmentId, bookedResources]) => {
      const department = this.departments.find(d => d.id === departmentId);
      const serviceSchedules = this.serviceSchedules.filter(ss =>
        ss.DepartmentId === departmentId
      );
      return Promise.all(bookingChecks.map(check =>
        check({ booking, bookedResources, department, serviceSchedules, operator })
      ));
    })
    .then(errors => {
      errors = flatten(errors).filter(x => x);
      return errors;
    });
  }
};

module.exports = BookingValidator;
