import React from "react";
import Icon from "./Icon.jsx";

const Spinner = ({ style={}, loaderSize=16 }) =>
{
  return (
    <div className="spinnerLoader" style={style}>
      <Icon icon="spinner2" color="dark" className="loader" spinning
        size={loaderSize} />
    </div>
  )
}

export default Spinner;