import API from "../api";

export const fetchCategories = () => ({
  type: "FETCH_CATEGORIES",
  payload:
    API.get("categories")
    .then(cats =>
      cats.map(c => {
        c.collapsed = false;
        return c;
      })
    )
})

export const toggleParentCategory = (catID) => {
  return { type: 'TOGGLE_PARENT_CATEGORY', catID }
}
export const selectCategory = (catID) => {
  return { type: 'SELECT_CATEGORY', catID }
}
