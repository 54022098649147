/*

state sketch!
~ indicates unimplemented.
! indicates deprecated.
All Arrays and Objects are their Immutable counterparts (List and Map) unless
otherwise noted.
User, Booking, Resource, BookedResource, Category are Immutable records.

{
  mode: "create" || "activate" || "return",

  // Regular users are operators in the patron portal
  operator: User,

  // Currently loaded user for booking or inspection
  user: User,
  
  // All available resource categories
  categories: {1: Category, ...},

  // cats: -1 when no item is selected
  // bookings: -1 indicates tentative booking
  // bookedResources: 0 indicates no selection
  selectedCategory: ([0-9]+ || -1),
  selectedBooking: ([0-9]+ || -1),
  selectedBookedResource: (-?[0-9]+ || 0),

  // All available resources
  resources: {1: Resource, ...},

  // current search query
  query: String,

  // Background events, stored as visjs-timeline-compatible objects.
  ~!backgroundEvents: [
    {
      id: "vis.js timeline ID",
      content: "booking ID or otherwise useful info",
      className: "for styling",
      start: Date / String,
      end: Date / String,
      type: "background"
    }
  ],

  // Currently loaded bookings (usually those of `user`)
  bookings: {1: Booking, ...},

  // Booked resources of `bookings`, displayed on the timeline
  bookedResources: {1: BookedResource, ...}

  errors: [
    {
      code: "ERROR_CODE_STRING",
      message: "description string",
      data: {...},
      severity: (0|1|2|3|4),
        // 0 -> illegal user actions (e.g. move item in past)
        // 1 -> warnings; overridable (e.g. booking ends outside service hours)
        // 2 -> warnings; strict (e.g. booking clashes with another)
        // 3 -> errors; recoverable (e.g. faulty internet (can try again))
        // 4 -> fatal errors (redux crashed, must reload page)
      ~hooks: ?
    }, ...
  ],

  notifications: [
    {
      message: "desc string",
      dismiss: 3000,     // ms to auto-dismiss, 0 to not
      color: "success",  // string matching colors supported by Alert component
      closeable: true    // show x on right side
    }, ...
  ]

  // To indicate when a certain part of app is being processed, and hence 
  // prevent extra submissions, as well as show loaders, progress bars, etc.
  pending: PendingState({
    booking: Boolean,
    bookings: Boolean,
    user: Boolean,
    categories: Boolean,
    resources: Boolean,
    application: Boolean
  })

  // true when BookingValidator has been intialized
  initialized: Boolean
}
*/


export * from "./app/app.reducer.js"; 
export * from "./booking/booking.reducer.js"; 
export * from "./bookedResource/bookedResource.reducer.js"; 
export * from "./user/user.reducer.js"; 
export * from "./resource/resource.reducer.js"; 
export * from "./category/category.reducer.js"; 
export * from "./department/department.reducer.js"; 
export * from "./errors/errors.reducer.js"; 
export * from "./notification/notification.reducer.js"; 
