import { Record, Map } from "immutable";

export const ResourceRecord = Record({
  id: -1, 
  name: "", 
  barcode: null,
  description: null,
  instructions: null,
  image: null,
  type: null,
  serialized: true,
  defaultBookingTime: -1,
  notes: null,
  status: "active",
  ParentResourceId: -1,
  CategoryId: -1,
  DepartmentId: null,
  deletedAt: null,
  Constraints: [],
  meta: {}
});

export class Resource extends ResourceRecord {
  constructor(obj) {
    super(obj);
  }

  static makeRecord(resource) {
    return new Resource(resource);
  }

  static makeRecords(resources) {
    let immutableMap = Map();
    for(let key in resources)
      immutableMap = immutableMap.set(
        resources[key].id,
        Resource.makeRecord(resources[key])
      );
    return immutableMap;
  }
  
  // Defaults for a new user being created (manager)
  static newResource() {
    return {...new Resource().toJS(), id: null, CategoryId: -1, ParentResourceId: null};
  }
}
