import React from 'react';
import Link from 'redux-first-router-link';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import moment from "moment-timezone";

import API from "../../api.js";
import { getActiveTimezone } from "../../selectors";

import Icon from "../Icon.jsx";
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';

const dateTimeFormat = 'MMM Do YYYY, h:mm a';

const getErrorVisuals = (error) => {
  let icon, iconColor;
  if(error.severity === 0)
  {
    icon = "checkmark";
    iconColor = "success";
  }
  else if(error.severity === 1)
  {
    icon = "checkmark";
    iconColor = "warning";
  }
  else
  {
    icon = "cross";
    iconColor = "danger";
  }
  return { icon, iconColor };
}

const generateMessage = (error, timezone, config) => {
  if(error.message)
    return error.message;

  const generateBookingLink = bookedResource => 
    `/app/${bookedResource.Booking.status == "reserved" ? "activate" : "return"}/
${bookedResource.Booking.Keeper.id}/${bookedResource.Booking.id}/${bookedResource.id}`;

  const generateUserLink = bookedResource => `/manage/users/${bookedResource.Booking.Keeper.id}`;

  const Anonymous = ({ mask, children=null }) => <span> {config.anonymize ? mask : children}</span>;

  switch(error.code)
  {
    case "BOOKING_CLASHES":
      const { data: { clashingResource } } = error;
      const resourceName = clashingResource.Resource.name;
      const keeperName = clashingResource.Booking.Keeper.name;
      const {bookedStart, bookedEnd} = clashingResource;

      return (
        <span>
          <i>{resourceName}</i> is booked by 
          <Anonymous mask="another user">
            &nbsp;<Link to={generateUserLink(clashingResource)} >{keeperName}</Link>
            &nbsp;from {moment.tz(bookedStart, timezone).format(dateTimeFormat)} to {moment.tz(bookedEnd, timezone).format(dateTimeFormat)}
            &nbsp;(<Link to={generateBookingLink(clashingResource)} target="_blank">open booking</Link>)
          </Anonymous>
        </span>
      );

    case "RESOURCE_NOT_AVAILABLE":
      const { data: { unavailableResource } } = error;
      const offenderName = unavailableResource.Booking.Keeper.name;
      return (
        <span>
          <i>{unavailableResource.Resource.name}</i> hasn't been returned by
          <Anonymous mask="another user">
            &nbsp;<Link to={generateUserLink(unavailableResource)} >{offenderName}</Link>
            &nbsp;(<Link to={generateBookingLink(unavailableResource)} target="_blank">open booking</Link>)
          </Anonymous>
        </span>
      );

    case "RESOURCE_DELETED":
      const { data: deletedResource } = error;
      return (
        <span>
          <i>{deletedResource.name}</i> is deleted. Please check its availability.
        </span>
      );

    case "BOOKING_OUTSIDE_SERVICE_HOURS":
      const { data: { resources } } = error;
      let resourceList = resources.join(" and ");
      if(resources.length >= 3) {
        const otherResourceCount = resources.length - 2;
        resourceList = resources
          .slice(0, 2)
          .join(", ")
          .concat(` and ${otherResourceCount} other ${otherResourceCount > 1 ? "resources" : "resource"}`);
      }
      return <span><i>{resourceList}</i> {resources.length > 1 ? "fall" : "falls"} outside the service hours</span>;

    case "USER_NOT_ACTIVE":
      const { data: { user } } = error;
      return <span>User {user.name} is inactive</span>

    case "GROUP_CONSTRAINTS_VIOLATED": {
      const { data: { resource, constraints }} = error;
      const nullGet = (object, key) => (object || {})[key];
      const sufficientConstraints = constraints.filter(constraint => Boolean(nullGet(constraint.definition, "sufficient")));
      const insufficientConstraints = constraints.filter(constraint => !Boolean(nullGet(constraint.definition, "sufficient")));
      console.log(sufficientConstraints, insufficientConstraints);

      const makeGroupClause = (constraints, sufficient) => {
        const determiner = sufficient ? "any" : "all";
        const plural = constraints.length > 1;
        const groupPhrase = `${plural ? determiner + " of " : ""} group${plural ? "s" : ""}`;
        let groupNames = constraints.map(c => <i>{c.Group.name}</i>);
        if (groupNames.length == 1) {
          groupNames = groupNames[0]
        } else if (groupNames.length == 2) {
          groupNames = <span>{groupNames[0]}, {groupNames[1]}</span>;
        } else {
          const conjunction = sufficient ? "or" : "and";
          const ommitedGroupCount = groupNames.length - 2;
          groupNames = [
            <span>{groupNames[0]}, {groupNames[1]}</span>,
            <span> {conjunction} {ommitedGroupCount} other{ommitedGroupCount > 1 ? "s" : ""}</span>
          ];
        }
        return <span>{groupPhrase} {groupNames}</span>;
      }

      let groupClause = "";
      if (sufficientConstraints.length > 0 && insufficientConstraints.length == 0) {
        groupClause = makeGroupClause(sufficientConstraints, true);
      } else if (insufficientConstraints.length > 0 && sufficientConstraints.length == 0) {
        groupClause = makeGroupClause(insufficientConstraints, false);
      } else if (insufficientConstraints.length > 0 && sufficientConstraints.length > 0) {
        const insufficientClause = makeGroupClause(sufficientConstraints, true);
        const sufficientClause = makeGroupClause(insufficientConstraints, false);
        groupClause = <span>{sufficientClause} or in {insufficientClause}</span>;
      }
      return <span>User must be in {groupClause} to book <i>{resource.name}</i></span>;
    }

    case "CONSTRAINT_VIOLATED": {
      const { data: { resource, constraint }} = error;
      switch(constraint.type) {
        case "group":
          return (
            <span>
              User must be in group <i>{constraint.Group.name}</i> to book <i>{resource.name}</i>
            </span>
          );
        case "duration":
          let errorText = "";
          const { minDuration, maxDuration } = constraint.definition;
          if(error.data.minViolated)
            errorText += `at least ${minDuration.value} ${minDuration.unit}`;
          else if(error.data.maxViolated)
            errorText += `at most ${maxDuration.value} ${maxDuration.unit}`;
          return (
            <span>
              <i>{resource.name}</i> must be booked for {errorText}
            </span>
          );
        case "time":
          const { fromTime, toTime } = constraint.definition;
          return (
            <span>
              <i>{resource.name}</i> must be booked from {fromTime} to {toTime}
            </span>
          );
        case "frequency":
          const { n, per } = constraint.definition;
          return (
            <span>
              <i>{resource.name}</i> has reached its booking limit of {n} times per {per}
            </span>
          );
      }
    }
    break;
  }
}

const combineSimilarErrors = errors => {
  const errorMap = errors.reduce((combinedErrors, error, index) => {
    if (!["BOOKING_OUTSIDE_SERVICE_HOURS", "CONSTRAINT_VIOLATED"].includes(error.code)) {
      return combinedErrors.set(error.code + index, error);
    }

    if (error.code === "CONSTRAINT_VIOLATED") {
      if (error.data.constraint.type !== "group") {
        return combinedErrors.set(error.code + index, error);
      }

      const affinedErrorCode = `${error.code}_GROUP_${error.data.resource.id}`;
      const sameCodeError = combinedErrors.get(affinedErrorCode);
      if (sameCodeError) {
        return combinedErrors.set(affinedErrorCode, {
          ...sameCodeError,
          data: {
            ...sameCodeError.data,
            constraints: [
              ...sameCodeError.data.constraints,
              error.data.constraint
            ]
          }
        });
      } else {
        return combinedErrors.set(affinedErrorCode, {
          ...error,
          code: "GROUP_CONSTRAINTS_VIOLATED",
          data: {
            resource: error.data.resource,
            constraints: [error.data.constraint]
          }
        });
      }
    }

    const sameCodeError = combinedErrors.get(error.code);
    let resources = [];
    const resourceName = error.data.test
      ? error.data.test.name
      : error.data.resource;
    if (sameCodeError) {
      if (!sameCodeError.data.resources.find(existingName => existingName === resourceName)) {
        resources = [...sameCodeError.data.resources, resourceName];
      } else {
        resources = sameCodeError.data.resources;
      }
    } else {
      resources = [resourceName];
    }
    return combinedErrors.set(error.code, {
      ...error,
      data: {
        ...error.data,
        resources
      }
    });
  }, Map());
  return errorMap.valueSeq().toList();
}

const BookingErrors = ({ errors, config, activeTimezone }) => {
  API.log("rendering", "booking errors");
  const combinedErrors = combineSimilarErrors(errors);
  return (
    <div className="bookingErrors mt-auto">
      {
        errors.size > 0 &&
        <ListGroup flush>
        {
          combinedErrors.valueSeq().map((err, i) =>
          {
            const { icon, iconColor } = getErrorVisuals(err);
            return (
              <ListGroupItem key={`${err.message}-${i}`}>
                <span className="statusMessage">
                  <Icon icon={icon} color={iconColor} size={12} />&nbsp;
                  { generateMessage(err, activeTimezone, config) }
                </span>
              </ListGroupItem>
            );
          })
        }
        </ListGroup>
      }
    </div>
  )
}

export default connect(
  (state) => {
    return {
      errors: state.errors,
      config: state.config,
      pending: state.pending.booking,
      activeTimezone: getActiveTimezone(state)
    };
  }
)(BookingErrors)
