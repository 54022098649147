import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import { toggleParentCategory, selectCategory, enterQuery } from '../actions';
import { getFilteredResources } from "../selectors";

import Icon from './Icon.jsx';
import classNames from "classnames";
import GenericSpinner from "./GenericSpinner.jsx";
import { Button, Card, CardHeader, CardBlock, Collapse, ListGroup,
  ListGroupItem, UncontrolledTooltip } from "reactstrap";

const Category = ({item, open, selected, select, toggle, parent, className}) => (
  <ListGroupItem
    onClick={() => {
      if(parent) toggle(item.id)
      else select(item.id)
    }}
    tag="div"
    className={"d-flex justify-content-start " + className}
    active={selected}
    action
  >
    <span>{item.name}</span>
    <If condition={toggle}>
      <div className="ml-auto">
        {parent && 
          <span className={"caret mid" + (open ? " open" : "")}></span>
        }
      </div>
    </If>
  </ListGroupItem>
)

const CategorySubgroup = ({allCategories, subgroup, open, selectedCategory, select, toggle, depth}) => (
  <Collapse isOpen={open}>
    <ListGroup flush>
    {
      subgroup.sort((a, b) => a.name.localeCompare(b.name))
      .valueSeq()
      .map(category => ( 
        <CategoryContainer
          allCategories={allCategories}
          category={category}
          key={"cat-" + category.id}
          selectedCategory={selectedCategory}
          select={select}
          toggle={toggle}
          depth={depth}
        />
      ))
    }
    </ListGroup>
  </Collapse>
)

const CategoryContainer = ({allCategories, category, selectedCategory, select, toggle, depth}) => {
  const isOpen = allCategories ? allCategories.get(category.id).open : false;
  return (
    category.Categories.size > 0
    ? <ListGroup key={"subgroup-" + category.id} flush>
      <Category parent item={category}
        open={isOpen} toggle={toggle}
        className={"cat-depth-" + depth} />
      <CategorySubgroup
        subgroup={category.Categories}
        open={isOpen}
        selectedCategory={selectedCategory}
        allCategories={allCategories}
        select={select} toggle={toggle} depth={depth+1}
      />
    </ListGroup>
    : <Category key={"cat-" + category.id} className={"cat-depth-" + depth}
      item={category} selected={selectedCategory === category.id} select={select} />
  );
}

class ResourceBrowser extends React.Component {
  DISPLAY_CATALOG = "DISPLAY_CATALOG";
  DISPLAY_LIST = "DISPLAY_LIST"

  state = {
    resourceDisplayMode: this.DISPLAY_LIST
  }

  toggleResourceDisplayMode = () => {
    this.setState({
      resourceDisplayMode: this.state.resourceDisplayMode == this.DISPLAY_CATALOG
        ? this.DISPLAY_LIST
        : this.DISPLAY_CATALOG
    });
  }

  sortAndFilterResources = (filteredResources, showInactives) => {
    return filteredResources.toList()
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(res => showInactives || res.status == "active")
      .filter(res => !res.deletedAt)
  }

  render() {
    const { categories, resources, onSelectResource, filteredResources, resourcesUnavailable, emptyPlaceholder, pending, selectedCategory, showInactives=false, selectedDepartment, toggle, select, query, onClearFilter } = this.props;
    const { resourceDisplayMode } = this.state;
    const numFilteredResourcesAvailable = filteredResources.reduce(
      (numAvailable, resource) => numAvailable + Number(!resourcesUnavailable.includes(resource.id)),
      0
    );

    return (
      <Card>
        <CardHeader className="header d-flex align-items-center">
        {
          pending
            ? <span className="text-muted">Loading resource browser...</span>
            : <React.Fragment>
              <span className={classNames({ "text-muted": !query })}>
              {
                query
                  ? <span>{filteredResources.size} results for <strong>{query}</strong></span>
                  : <span>{numFilteredResourcesAvailable} / {filteredResources.size} available</span>
              }
              </span>
              {query && <Button color="link" className="p-0 ml-auto" onClick={onClearFilter}><small>Clear filters</small></Button>}
            </React.Fragment>
        }
          <a id="resourceDisplayModeToggle" onClick={this.toggleResourceDisplayMode}>
          {
            resourceDisplayMode == this.DISPLAY_LIST
              ? <Icon className="ml-1" color="muted" icon="images" size={18} />
              : <Icon className="ml-1" color="muted" icon="list" size={14} />
            
          }
          </a>
          {/*<UncontrolledTooltip placement="right" target="resourceDisplayModeToggle">
            Switch to {resourceDisplayMode == this.DISPLAY_LIST ? "Gallery view" : "List view"}
          </UncontrolledTooltip>*/}
        </CardHeader>
        {pending
          ? <GenericSpinner />
          : <div className="layoutContainer">
              <div className="categories">
                <ListGroup flush>
                {
                  categories.toList()
                  .filter(cat => !cat.ParentId && cat.DepartmentId == selectedDepartment)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(category => 
                    <CategoryContainer
                      key={"cat-" + category.id}
                      allCategories={categories}
                      category={category}
                      selectedCategory={selectedCategory}
                      select={select}
                      toggle={toggle}
                      depth={1}
                    />
                  )
                }
                </ListGroup>
              </div>
              <div className="resources">
              {
                resourceDisplayMode == this.DISPLAY_LIST
                  ? <ListGroup flush>
                  {
                    filteredResources.size === 0
                    ? emptyPlaceholder 
                    : this.sortAndFilterResources(filteredResources, showInactives).map(fr => (
                        <ListGroupItem
                          key={fr.id}
                          className={classNames("filtered-resource", { unavailable: resourcesUnavailable.includes(fr.id) })}
                          onClick={() => onSelectResource(fr)}
                          tag="div"
                          action
                        >
                          <div className="filtered-resource-name">
                            <strong>{fr.name}</strong>&nbsp;{fr.barcode ? <small>({fr.barcode})</small> : ''}
                          </div>
                          <div className="filtered-resource-icons">
                            {fr.meta.autoCirculating && <Icon icon="spinner10" color="dark" />}
                          </div>
                        </ListGroupItem>
                    ))
                  }
                  </ListGroup>
                  : <div className="resourcePhotoCatalog">
                  {
                    this.sortAndFilterResources(filteredResources, showInactives).map(fr => {
                      const thumbStyle = {};
                      if (fr.image) {
                        thumbStyle.backgroundImage = `url(${API.generateLink("images/" + fr.image,
                            { size: "thumb" }, true)})`
                      }
                      return (
                        <a key={fr.id}
                          className="resourceThumbContainer"
                          onClick={() => onSelectResource(fr)}
                        >
                          <div className={classNames("thumb", { unavailable: resourcesUnavailable.includes(fr.id) })}
                            style={thumbStyle}
                          >
                            {!fr.image && <Icon icon="images" color="dark" />}
                          </div>
                          <div className="name">
                            {fr.name.length > 35 ? `${fr.name.substr(0, 35)}...` : fr.name}
                            {fr.meta.autoCirculating && <Icon icon="spinner10" color="dark" size={12} />}
                          </div>
                        </a>
                      );
                    })
                  }
                  </div>
              }
              </div>
            </div>
        }
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    query: state.query,
    categories: state.categories,
    resources: state.resources,
    filteredResources: getFilteredResources(state),
    resourcesUnavailable: state.resourcesUnavailable,
    selectedCategory: state.selectedCategory,
    selectedDepartment: state.selectedDepartment,
    pending: state.pending.categories || state.pending.resources
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // toggle is for parent categories only
    toggle: (catItem) => ownProps.toggle ? ownProps.toggle(catItem) : dispatch(toggleParentCategory(catItem)),
    select: (catItem) => ownProps.select ? ownProps.select(catItem) : dispatch(selectCategory(catItem)),
    onClearFilter: () => dispatch(enterQuery(""))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceBrowser);
