import React from 'react';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';

import { ListGroupItem } from "reactstrap";

import Icon from "../Icon.jsx";
import UserBadge from '../userBadge.jsx'
import GenericSpinner from "../GenericSpinner.jsx";

import API from "../../api.js";

const UserWidget = ({user, pending, activeBookings, config}) => {
  API.log("rendering", "user widget");
  return (
  <Choose>
    <When condition={pending}>
      <GenericSpinner style={{height: 50}} />
    </When>
    <When condition={user.get("id") < 0}>
      <ListGroupItem className="panelStatusMessage">
        No user selected
      </ListGroupItem>
    </When>
    <Otherwise>
      <ListGroupItem className="userWidget">
        <div className="picture">
          <img src="/assets/img/userThumb.gif" />
        </div>
        <div className="name">
          <strong>{user.name}</strong>&nbsp;({user.email.split("@")[0]})
          <p className="badges">
          {
            user.get("badges").map(function(badge, i){
              return <UserBadge key={"badge_" + badge.get("type") + i}
                                name={badge.get("name")}
                                style={badge.get("style")} />
            })
          }
            { config.allowManage && 
            <small>
              <Link to={"/manage/users/" + user.id}>Manage</Link>{" | "}
              <a href={`mailto:${user.email}`}>Email</a>
            </small>
            }
          </p>
        </div>
      </ListGroupItem>
    </Otherwise>
  </Choose>
  )
}


const mapStateToProps = (state) => {
  return {
    user: state.user,
    pending: state.pending.user,
    config: state.config
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onConfirmBooking: (id) => {
      //dispatch(confirmBooking(id))
    }
  }
}

const UserWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserWidget)


export default UserWidgetContainer;
