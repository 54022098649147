// API
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { init as initApm } from '@elastic/apm-rum'

const ENV = process.env.ENV || "development";
console.info("Frontend environment:", ENV);

const apiPath = {
  "development": "http://localhost:8000/api/v1",
  "test":        "http://localhost:8000/api/v1",
  "demo":        "https://api.manta.equipment:82/api/v1",
  "staging":     "https://api.manta.equipment:81/api/v1",
  "production":  "https://api.manta.equipment/api/v1"
}[ENV];

// Singleton
let instance = null;

class API
{
  constructor()
  {
    if(!instance)
      instance = window.API = this;

    this.calls = 0;
    this.callbacks = [];
    this.resolveCallbacks = this.resolveCallbacks.bind(this);
    this.loggers = {};
    this.outputLoggers = [/*'booking', 'errors', 'rendering', 'actions'*/];
    this.sendToAPILoggers = ['errors', 'actions'];
    this.wait = this.wait.bind(this);

    this.federatedLoginPath = apiPath + "/login";
    this.ENV = ENV;
    this.cookiePrefix = ENV === "production" ? "" : (ENV + "_");

    this.cookieDomain = {
      "development": undefined,
      "test":        undefined,
      "demo":        document.location.hostname,
      "staging":     document.location.hostname,
      "production":  "manta.equipment"
    }[this.ENV];

    this.cookies = {
      set: (key, value) => Cookies.set(this.cookiePrefix + key, value, { cookieDomain: this.cookieDomain }),
      get: key => Cookies.get(this.cookiePrefix + key, { cookieDomain: this.cookieDomain }),
      remove: key => Cookies.remove(this.cookiePrefix + key, { cookieDomain: this.cookieDomain })
    };

    // Temp fix: manually remove all double-domain cookies
    const cookieMatches = document.cookie.match(/apiToken/g);
    if(cookieMatches && cookieMatches.length > 1)
    {
      console.log("Removing double domain cookies");
      document.cookie = "apiToken=; domain=.manta.equipment; expires=Thu, 01-Jan-1970 00:00:01 GMT;";
      document.cookie = "apiToken=; expires=Thu, 01-Jan-1970 00:00:01 GMT;";
      document.cookie = "client=; domain=.manta.equipment; expires=Thu, 01-Jan-1970 00:00:01 GMT;";
      document.cookie = "client=; expires=Thu, 01-Jan-1970 00:00:01 GMT;";
      document.cookie = "expiresAt=; domain=.manta.equipment; expires=Thu, 01-Jan-1970 00:00:01 GMT;";
      document.cookie = "expiresAt=; expires=Thu, 01-Jan-1970 00:00:01 GMT;";
      //This is equivalent of logout
    }

    this.expiryWindow = 30; //30 seconds
    this.serverTimeOffset = 0;
    this.timeSync();

    setInterval(() => this.sendLog(), 10000);

    //this.initializeAPM();

    return instance;
  }

  log(logger, ...messages)
  {
    if(!this.loggers[logger])
      this.loggers[logger] = [];

    const prettyLog = object => {
      let text = JSON.stringify(object, null, 2);
      if(text && text.length > 50) text = "\n" + text;
      return text;
    }

    let date = new Date().toISOString();
    let output = date + ' ' + logger + ': ' + messages.map(prettyLog).join(' ');
    this.loggers[logger].push({
      timestamp: date,
      data: messages.length > 1 ? messages : messages[0]
    });
    if(this.outputLoggers.includes(logger))
      console.log(output);
  }

  logError(error)
  {
    this.apm.captureError(error);
    this.log("errors", {
      message: error.message,
      stack: error.stack
    });
  }

  sendLog()
  {
    if(!this.isAuthenticated())
      return;

    let logPayload = [];
    for(let logger of this.sendToAPILoggers)
    {
      if(this.loggers[logger])
      {
        const entries = this.loggers[logger].map(entries => ({ logger, ...entries }))
        this.loggers[logger].length = 0;
        logPayload = logPayload.concat(entries);
      }
    }
    logPayload = logPayload.sort((a, b) => a.timestamp > b.timestamp);
    if(logPayload && logPayload.length > 0 && logPayload.length < 1024*1000) //1MB
      this.post("sendLog", logPayload);
  }

  logout()
  {
    this.cookies.remove("apiToken");
    this.cookies.remove("expiresAt");
  }

  loggedInUser()
  {
    return jwtDecode(this.cookies.get("apiToken")).user;
  }

  isAuthenticated()
  {
    return this.cookies.get("apiToken");
  }

  /*
   * Send authCode to the API server to exchange for a token, store it.
   * Returns: Promise
   */
  authWithServer(email, password) 
  {
    return fetch(apiPath + "/authenticate", {
      method: "POST",
      // unescape+encodeURIComponent is needed to allow unicode passwords
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa#Unicode_strings
      headers: {
        "authorization": "Basic " + btoa(email + ":" + unescape(encodeURIComponent(password)))
      }
    })
    .then(response => {
      if(response.status === 200)
        return response.json();
      else
        return response.json().then(err => Promise.reject(err));
    })
    .then(response => {
      this.setAuthCookies(response);
      this.client = response.client;
    });
  }

  /* Set authentication cookies from a payload (json object) */
  setAuthCookies(payload)
  {
    for(var [key, value] of Object.entries(payload))
      this.cookies.set(key, value);
  }

  /*
   * Refresh existing token - doesn't check expiry, just refreshes
   * Returns: Promise
   */
  refreshAPIToken()
  {
    let apiToken = this.cookies.get("apiToken");
    return fetch(apiPath + "/authenticate", {
      method: "POST",
      body: JSON.stringify({apiToken, refresh: true}),
      headers: {"Content-Type": "application/json"}
    })
    .then(response => response.json())
    .then(response => {
      this.setAuthCookies(response);
    });
  }
  
  //Headers for auth
  authHeaders()
  {
    return {"Authorization": "Bearer " + this.cookies.get("apiToken")}
  }

  /*
   * Checks if tokens are about to expire (expiryWindow) and tries 
   * to refresh them if so.
   *
   * Returns: Promise
   */
  ensureFreshTokens()
  {
    let delta = this.cookies.get("expiresAt") - new Date().getTime();
    delta /= 1000; //seconds
    if(delta < this.expiryWindow + this.serverTimeOffset) 
      return this.refreshAPIToken();
    else return Promise.resolve();
  }

  maybeJSON(data)
  {
    try { return data.json(); }
    catch(e) { return data; }
  }

  unwrapResponse = (response) =>
  {
    // TODO: .json() sometimes fails here if response is not json-like, all response unwraps need to be try/caught
    if(response.status >= 400 && response.status <= 600)
    {
      return response.json().then(unwrapped => {
        let errorMessage = unwrapped.message || unwrapped.error ||
          `${response.status}: ${response.statusText}`;
        if (unwrapped.message && unwrapped.errors &&
            Array.isArray(unwrapped.errors) && unwrapped.errors[0].message) {
          errorMessage += `: ${unwrapped.errors[0].message}`
        }
        throw new Error(errorMessage);
      });
    }
    // If .json() fails, `text()` can't be called on it, so we clone before to allow`
    const responseClone = response.clone();
    return response.json()
      .catch(e => responseClone);
  }

  static encodeParam = (value) => {
     if(Array.isArray(value)) return '[' + encodeURIComponent(value) + ']';
     else return encodeURIComponent(value)
  }

  generateLink(resource, filter, authorize=false)
  {
    if(authorize)
      filter = {...filter, ...this.authHeaders()};
    let filterParams = "";
    if(filter)
      filterParams = '?' + Object.keys(filter).map(key =>
        `${key}=${API.encodeParam(filter[key])}`).join('&');
    return apiPath + "/" + resource + filterParams;
  }

  //GET
  get(resource, filter)
  {
    this.calls++;

    const link = this.generateLink(resource, filter);

    return this.ensureFreshTokens()
      .then(() => fetch(link, { headers: this.authHeaders() }))
      .then(this.unwrapResponse)
      .then(this.resolveCallbacks)
      .catch(e => { this.logError(e); return e; });
  }

  //PUT
  put(resource, props)
  {
    return this.pRequest(resource, props, "PUT");
  }

  //POST
  post(resource, props)
  {
    return this.pRequest(resource, props, "POST");
  }
  
  //DELETE
  delete(resource)
  {
    return this.pRequest(resource, {}, "DELETE");
  }

  // Common code for PUT and POST requests
  pRequest(resource, props, method)
  {
    this.calls++;
    let headers = {"Content-Type": "application/json"};

    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => reject(`${resource} timed out`), 10000);
    });

    const fetchPromise = this.ensureFreshTokens()
      .then(() => fetch(apiPath + "/" + resource, {
          method: method,
          body: JSON.stringify(props),
          headers: Object.assign(headers, this.authHeaders())
        }))
      .then(this.unwrapResponse);

    return Promise.race([fetchPromise, timeoutPromise])
      .catch(e => {
        this.logError(e)
        return Promise.reject(e);
      });
  }

  // Decrement calls counter and notify listeners
  resolveCallbacks(data)
  {
    if(this.calls != 0)
      this.calls--;
    if(this.calls == 0)
      this.callbacks.forEach(callback => callback());
    return data;
  }

  // Returns a promise that resolves when all calls have finished
  wait()
  {
    if(this.calls == 0)
      return Promise.resolve();
    else
      return new Promise(resolve => this.callbacks.push(resolve));
  }
  
  // Call once to set serverTimeOffset
  timeSync = () =>
  {
    const start = Date.now()
    this.get("time")
    .then(serverTime => {
      const clientTime = start + (Date.now() - start) / 2;
      this.serverTimeOffset = (serverTime - clientTime) / 1000;
    });
  }

  initializeAPM = () => {
    this.apm = initApm({
      serviceName: "manta-front",
      serviceVersion: "1.0",
      serverUrl: "http://olafs.eu:8200", 
      distributedTracingOrigins: [apiPath]
    });
    this.apm.setInitialPageLoadName("Manta");
  }

  startTransaction = (name, type) => {
    if(this.apm)
      this.transaction = this.apm.startTransaction(name, type);
  }
}

export default new API();
