import React from 'react';
import { connect } from 'react-redux';
import { push, go, replace } from 'redux-first-router';

import { Category } from '../../records';
import { toggleParentCategory } from '../../actions';
import CategoryEditor from "./CategoryEditor.jsx";
import ResourceCategories from "../ResourceCategories.jsx";
import CategoryResources from "./CategoryResources.jsx";
import MessageBox from "../MessageBox.jsx";

import { showNotification, fetchCategories } from '../../actions';

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle,
  CardText, Container, Row, Col } from "reactstrap";

class CategoriesSection extends React.Component
{
  state = {
    pending: false,
    category: Category.newCategory(),
    categoryResources: null,
    deleteInfoModal: false
  }

  saveCategory = (category) => {
    this.setState({ pending: true });

    if(category.ParentId == -1)
      category.ParentId = null;
    category.DepartmentId = this.props.DepartmentId;

    if(category.ParentId != null && category.ParentId == category.id)
    {
      this.props.dispatch(showNotification({ color: "danger",
        message: `Circular relationship: category can't be its own parent` }));
      this.setState({ category, pending: false });
      return;
    }

    const existingCategory = category && category.id && category.id > 0;
    (() => {
      if(existingCategory)
        return API.put("category/" + category.id, category);
      else
        return API.post("categories", category);
    })()
    .then((category) => {
      if(!existingCategory)
        this.clearCategory();

      this.setState({ pending: false });
      this.props.dispatch(showNotification({ message: `Category ${category.name} has been saved` }));
      this.props.dispatch(fetchCategories());
    })
    .catch(response => {
      this.props.dispatch(showNotification({
        message: response.message,
        color: "danger",
        dismiss: 10000
      }));
      this.setState({ pending: false });
      return;
    });
  }

  deleteCategory = (category) => {

    if(this.state.categoryResources && this.state.categoryResources.length > 0)
    {
      this.setState({
        deleteInfoModal: true,
        deleteInfoTitle: "Category has resources",
        deleteInfoMessage: "Please delete or move all resources to another category before deleting this one"
      });
      return;
    }

    if(category.Categories && Object.keys(category.Categories).length > 0)
    {
      this.setState({
        deleteInfoModal: true,
        deleteInfoTitle: "Category has subcategories",
        deleteInfoMessage: "Please delete all subcategories before deleting this one"
      });
      return;
    }

    if(!confirm("Are you sure you want to delete this category?"))
      return;
    return API.delete("category/" + category.id)
    .then(() => {
      this.clearCategory();
      this.props.dispatch(showNotification({
        message: `Category ${category.name} has been deleted`
      }));
      this.props.dispatch(fetchCategories());
    });
  }

  deleteAllResourcesInCategory = () => {
    if(!confirm("Are you sure you want to delete all listed resources?"))
      return;
    const deletePromises = this.state.categoryResources.map(resource => {
      return API.delete("resource/" + resource.id);
    });
    return Promise.all(deletePromises)
    .then(() => {
      this.props.dispatch(showNotification({ color: "info",
        message: `Resources have been deleted` }));
      this.setState({ categoryResources: null });
    });
  }

  moveAllResourcesInCategoryTo = (category) => {
    const movePromises = this.state.categoryResources.map(resource => {
      return API.put("resource/" + resource.id, { CategoryId: category });
    });
    return Promise.all(movePromises)
    .then(() => {
      this.props.dispatch(showNotification({ color: "info",
        message: `Resources have been moved to the new category` }));
      this.setState({ categoryResources: null });
    });
  }

  selectCategory = id => {
    const { categories, dispatch } = this.props;
    const category = categories.find(category => category.id == id).toJS();

    if(Object.keys(category.Categories).length > 0)
      dispatch(toggleParentCategory(id));

    API.get("resources", {CategoryId: category.id})
    .then(categoryResources => {
      this.setState({ category, categoryResources });
    });
  }

  clearCategory = () => {
    this.setState({ category: Category.newCategory(), categoryResources: null });
  }

  render() {
    return (
      <Row className="manager-row">
        <Col md={4} className="d-flex flex-column align-items-stretch justify-content-start">
          <ResourceCategories toggle={this.selectCategory} select={this.selectCategory} />
        </Col>
        <Col md={8} className="scroll-col">
          <CategoryEditor
            pending={this.state.pending}
            category={this.state.category}
            categories={this.props.categories}
            saveCategory={this.saveCategory}
            deleteCategory={this.deleteCategory}
            clearCategory={this.clearCategory}
          />
          <br/>
          <CategoryResources
            category={this.state.category}
            categoryResources={this.state.categoryResources}
            categories={this.props.categories}
            deleteAllResourcesInCategory={this.deleteAllResourcesInCategory}
            moveAllResourcesInCategoryTo={this.moveAllResourcesInCategoryTo}
          />
          <MessageBox 
            showing={this.state.deleteInfoModal} 
            title={this.state.deleteInfoTitle}
            body={<p>{this.state.deleteInfoMessage}</p>}
            showCancel={false}
            toggle={() => this.setState({deleteInfoModal: false})} 
          />
        </Col>
      </Row>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    categories: state.categories.filter(cat =>
      cat.DepartmentId === state.selectedDepartment
    ),
    DepartmentId: state.selectedDepartment
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesSection);
