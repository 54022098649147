import moment from "moment";
import { push } from 'redux-first-router';

import API from "../api";
import BookingValidator from "../../utils/bookingValidator.js";
import { roleConfigs } from "../records.js";

import { discardActiveBooking, discardBookings, discardUser,
  initializePlaceholderBooking, fetchNearestBookings } from "../actions.js";

export const initialize = () => {
  return (dispatch, getState) => {
    BookingValidator.initialize({ API });

    const config = roleConfigs[API.loggedInUser().role];
    return dispatch({ type: 'INITIALIZED', config });
  }
}

export const reset = () => (dispatch, getState) => {
  if (getState().config.forceSelfAsUser) {
    dispatch(discardActiveBooking());
  } else {
    dispatch(discardUser());
    dispatch(discardBookings());
    dispatch(fetchNearestBookings());
  }
  dispatch(initializePlaceholderBooking());
  dispatch(setMode("create")); //default
  push("/app");
}

export const setMode = (mode) => {
  return { type: 'SET_MODE', mode }
}

export const enterQuery = (query) => {
  return { type: 'ENTER_QUERY', query }
}

export const fetchSettings = () => {
  return dispatch => {
    return API.get("serviceSchedules", { validThroughThreshold: moment().toISOString() })
      .then(serviceSchedules => {
        dispatch(loadSettings({ serviceSchedules }));
      });
  } 
}

export const loadSettings = (settings) => {
  return { type: 'LOAD_SETTINGS', settings }
}

export const saveServiceSchedule = (schedule) => {
  return (dispatch, getState) => {
    const state = getState();
    const departmentID = state.selectedDepartment;
    schedule.DepartmentID = departmentID;
    schedule.closed = JSON.stringify(schedule.closed);

    dispatch({
      type: "UPDATE_SERVICE_SCHEDULE",
      payload: API.put("serviceSchedule/" + schedule.id, schedule)
    })
  }
}

export const loadResourceInEditor = (resource) => {
  return { type: 'LOAD_RESOURCE_IN_EDITOR', resource }
}
