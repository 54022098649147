import React from "react";
import { connect } from 'react-redux';
import { returnBookingOrResource } from '../../../actions';
import BookingControlButton from './BookingControlButton'

const ReturnAllButton = ({ user, booking, bookedResources, dispatch }) => (
  <BookingControlButton
    bsStyle="warning" icon="reply"
    disabled={bookedResources.size === 0 || user.id === -1}
    caption="Return All"
    clickHandler={() => dispatch(returnBookingOrResource(booking))}
  />
)

export default connect()(ReturnAllButton)
