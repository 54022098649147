import React from "react";
import Icon from "../Icon"

import styles from "./index.scss";

const ErrorScreen = ({ error }) => {
  return (
    <div className={styles.errorBackground}>
      <div className={styles.errorContainer}>
        <h3><Icon icon="notification" color="dark" size={42} className="mr-2" />An error has occurred :(</h3>
        <h5>Unfortunately, Manta ran into some trouble. Please reload the page and try again.</h5>
        <p>If the error persists, please get in touch with our support team, and provide them with the following information:</p>
        <ol>
          <li>The time the error occurred;</li>
          <li>The last actions you were taking (e.g. loading a user), and any details (resources, booking times). Please provide as much detail as possible.</li>
          <li>Whether the error is persistent (always occurs when you take certain actions), or intermittent (only seems to happen during certain times of day, or randomly), and</li>
          {
            error &&
            <li>
              <p>The error information below:</p>
              {error.message && <pre>Error: {error.message}</pre>}
              {error.stack && <pre className="text-muted">{error.stack}</pre>}
            </li>
          }
        </ol>
      </div>
    </div>
  );
}

export default ErrorScreen;