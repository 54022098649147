import { User, UserBadge } from "../records.js";
import { Map } from "immutable";

export const defaultUser = new User();

export const user = (state=defaultUser, action) => {
  switch(action.type) {
    case 'FETCH_USER_FULFILLED':
      let user = action.payload;
      return User.makeRecord(user);
    case 'DISCARD_USER':
      return defaultUser;
    default:
      return state;
  }
}

export const operator = (state=defaultUser, action) => {
  switch(action.type) {
    case 'SET_OPERATOR':
      return User.makeRecord(action.user);
    default:
      return state;
  }
}

export const users = (state=Map(), action) => {
  switch(action.type) {
    case 'FETCH_USERS_FULFILLED':
      let users = action.payload;
      return User.makeRecords(users);
    default:
      return state;
  }
}
