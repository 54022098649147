import React from 'react';
import { connect } from 'react-redux';
import { redirect } from "redux-first-router";

import Moment from "moment-timezone";
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardHeader, CardBody, ListGroup, ListGroupItem, Container, Nav, NavItem, NavLink, TabContent, TabPane }
  from "reactstrap";
import classNames from "classnames";

import API from "../../api.js";
import { getActiveTimezone } from "../../selectors.js";
import { generateBookingAction } from "./utils";

import Icon from "../Icon.jsx";
import SummaryTab from "./SummaryTab";
import BookingsTab from "./BookingsTab";
import ReservationsTab from "./ReservationsTab";
import GenericSpinner from "../GenericSpinner.jsx";
import { RapidReturn, RapidActivate } from "../RapidActions.jsx";

import "./index.global.scss";

class NearestBookings extends React.Component {
  state = {
    activeTab: "summary",
    rapidReturnBooking: null,
    rapidActivateBooking: null
  }

  onChangeActiveTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  onRapidAction = (booking) => {
    return booking.status === "active" ?
      this.setState({ rapidReturnBooking: booking }) 
    : this.setState({ rapidActivateBooking: booking })
  }

  render()
  {
    API.log("rendering", "nearest bookings");

    const { nearestBookings, returnResources, activateBooking, activeTimezone, pending, loadBooking } = this.props;
    const { rapidReturnBooking, rapidActivateBooking } = this.state;

    const bookings = nearestBookings.filter(booking => booking.status !== "reserved");
    const reservations = nearestBookings.filter(booking => booking.status === "reserved");

    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: this.state.activeTab === "summary" })}
              onClick={() => this.onChangeActiveTab("summary")}
            >
              Summary
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: this.state.activeTab === "bookings" })}
              onClick={() => this.onChangeActiveTab("bookings")}
            >
              Bookings <small className="count">{bookings.size}</small>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: this.state.activeTab === "reservations" })}
              onClick={() => this.onChangeActiveTab("reservations")}
            >
              Reservations <small className="count">{reservations.size}</small>
            </NavLink>
          </NavItem>
          {/*<NavItem className="ml-auto">
            <NavLink><Icon icon="cog" color="dark" height={22} /></NavLink>
          </NavItem>*/}
        </Nav>
        <TabContent className="pt-0" activeTab={this.state.activeTab}>
          <TabPane tabId="summary">
          {
            pending
              ? <GenericSpinner />
              : <SummaryTab
                  bookings={bookings}
                  reservations={reservations}
                />
          }
          </TabPane>
          <TabPane tabId="bookings">
          {
            pending
              ? <GenericSpinner />
              : <BookingsTab
                  bookings={bookings}
                  activeTimezone={activeTimezone}
                  loadBooking={loadBooking}
                  onRapidAction={this.onRapidAction}
                />
          }
          </TabPane>
          <TabPane tabId="reservations">
          {
            pending
              ? <GenericSpinner />
              : <ReservationsTab
                  bookings={reservations}
                  activeTimezone={activeTimezone}
                  loadBooking={loadBooking}
                  onRapidAction={this.onRapidAction}
                />
          }
          </TabPane>
        </TabContent>
        <RapidActivate
          booking={rapidActivateBooking}
          toggle={() => this.setState({ rapidActivateBooking: null }) }
        />
        <RapidReturn
          booking={rapidReturnBooking}
          toggle={() => this.setState({ rapidReturnBooking: null }) }
        />
      </React.Fragment>
    );

    // return (
    //   <Card className="nearestBookings">
    //     <CardHeader>Bookings</CardHeader>
    //     <ListGroup flush>
    //       { [...nearestBookings, ...extras].sort((a, b) => displayTime(a) > displayTime(b) ? 1 : -1)
    //         .map(booking => 
    //           typeof booking === "string" ? <Separator key={`sep-${booking}`} text={booking} /> : (
    //             <NearestBooking
    //               key={booking.id}
    //               userName={booking.Keeper.name}
    //               time={displayTimeFormatted(booking, activeTimezone)}
    //               color={bookingRowColor(displayTime(booking), booking.status)}
    //               resources={booking.BookedResources.map(br => br.Resource.name).join(", ")}
    //               bookingLink={generateBookingLink(booking)}
    //               action={booking.status === "active" ? "return" : "checkout"}
    //               onAction={() => 
    //                 booking.status === "active" ?
    //                   this.setState({ rapidReturnBooking: booking }) 
    //                 : this.setState({ rapidActivateBooking: booking })
    //               }
    //             />
    //           )
    //         )
    //       }
    //     </ListGroup>
    //     <RapidActivate
    //       booking={rapidActivateBooking}
    //       toggle={() => this.setState({ rapidActivateBooking: null }) }
    //     />
    //     <RapidReturn
    //       booking={rapidReturnBooking}
    //       toggle={() => this.setState({ rapidReturnBooking: null }) }
    //     />
    //   </Card>
    // );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTimezone: getActiveTimezone(state),
    pending: state.pending.bookings || state.pending.resources || state.pending.bookedResources,
    nearestBookings: state.nearestBookings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
     loadBooking: booking => dispatch(generateBookingAction(booking))
  }
}


const NearestBookingsContainer = connect(mapStateToProps, mapDispatchToProps)(NearestBookings)

export default NearestBookingsContainer;
