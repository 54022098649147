import { List } from "immutable";

import { Notification } from "../records.js";
import generateNotification from "./notification.tools.js";

export const notifications = (state=List(), action) => {
  const newNotif = generateNotification(action);
  if(newNotif)
    return state.push(newNotif);
  else if(action.type == "SHOW_NOTIFICATION")
  {
    if(!state.some(notif => notif.message === action.notification.message))
    {
      return state.push(new Notification(action.notification));
    }
  }
  // TODO:
  // This is a rubbish way of doing it. assign ids to notifs and remove
  // explicitly. otherwise one notif can remove another one with timeouts
  else if(action.type == "REMOVE_OLDEST_NOTIFICATION")
    return state.shift();

  return state;
}
