import React from "react";
import { Card, CardBody } from "reactstrap";
import moment from "moment";

import { displayTime } from "./utils";

const infoText = (amount, singularNoun) => {
	if (amount === 0) {
		return <span>have no {singularNoun}s</span>;
	} else if (amount === 1) {
		return <span>have <strong>{amount}</strong> {singularNoun}</span>;
	} else {
		return <span>have <strong>{amount}</strong> {singularNoun}s</span>; 
	}
}

const Summary = ({ overdueBookings, upcomingBookings, overdueReservations, upcomingReservations }) => {
	const totalCount = overdueBookings.size + upcomingBookings.size + overdueReservations.size + upcomingReservations.size;
	if (totalCount === 0) {
		return <p>All clear! You have nothing upcoming or overdue.</p>;
	}

	const bookingCount = overdueBookings.size + upcomingBookings.size;
	const reservationCount = overdueReservations.size + upcomingReservations.size;
	return <React.Fragment>
		{
			bookingCount === 0
				? <p>No upcoming or overdue bookings.</p>
				: <React.Fragment>
						<p>You {infoText(overdueBookings.size, "booking")} overdue. Please return the items as soon as possible.</p>
						<p>You {infoText(upcomingBookings.size, "booking")} due to be returned in the next 30 minutes.</p>
					</React.Fragment>
		}
		{
			reservationCount === 0
				? <p>No upcoming or overdue reservations.</p>
				: <React.Fragment>
						<p>You {infoText(overdueReservations.size, "reservation")} overdue for activation.</p>
						<p>You {infoText(upcomingReservations.size, "reservation")} due to be activated in the next 30 minutes.</p>
					</React.Fragment>
		}
	</React.Fragment>
}

const SummaryTab = ({ bookings, reservations }) => {
	const overdueBookings = bookings.filter(booking => booking.status === "active" && displayTime(booking) < moment());
	const upcomingBookings = bookings.filter(booking => booking.status === "active" && displayTime(booking) >= moment());
	const overdueReservations = reservations.filter(reservation => reservation.status === "reserved" && displayTime(reservation) < moment());
	const upcomingReservations = reservations.filter(reservation => reservation.status === "reserved" && displayTime(reservation) >= moment());
	return (
		<Card className="nearestBookings summary">
			<CardBody>
				<Summary
					overdueBookings={overdueBookings}
					upcomingBookings={upcomingBookings}
					overdueReservations={overdueReservations}
					upcomingReservations={upcomingReservations}
				/>
				<p><i>Pick a resource to start a new booking.</i></p>
			</CardBody>
		</Card>
	);
}

export default SummaryTab;
