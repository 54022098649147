import { Map, List } from "immutable";

import { Resource } from "../records.js";

export const resources = (state=Map(), action) => {
  switch(action.type) {
    case 'FETCH_RESOURCES_FULFILLED':
      const resources = action.payload;
      return Resource.makeRecords(resources);
    default:
      return state;
  }
}

export const resourcesUnavailable = (state=List(), action) => {
  switch(action.type)
  {
    case "FETCH_RESOURCES_UNAVAILABLE_FULFILLED":
      return List(action.payload);
    default:
      return state;
  }
}
