import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";

import { removeOldestNotification } from "../actions";

import Icon from './Icon.jsx';
import { Alert } from "reactstrap";


class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      timeoutLength: props.dismiss,
      timeout: null
    }
  }

  componentDidMount() {
    if(this.state.timeoutLength > 0)
    {
      this.setState({
        timeout: setTimeout(this.dismiss, this.state.timeoutLength)
      });
    }
  }

  componentWillUnmount() {
      this.state.timeout && clearTimeout(this.state.timeout);
  }

  dismiss = () => {
    clearTimeout(this.state.timeout);
    this.setState({
        open: false,
        timeout: null
    });
    this.props.onDismiss();
  }

  render() {
    return (
      <Alert isOpen={this.state.open} color={this.props.color}
        toggle={this.props.closeable ? this.dismiss : false}>
        {this.props.children || null}
      </Alert>
    )
  }
}

class Notifications extends React.Component
{
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="notificationsPanel">
      {
        this.props.notifications.map((notif, index) => 
          <Notification
            key={notif.message + index}
            color={notif.color}
            dismiss={notif.dismiss}
            closeable={notif.closeable}
            onDismiss={this.props.removeOldestNotification}
          >
            {notif.message}
          </Notification>
        )
      }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
    errors: state.errors
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    removeOldestNotification: () => {
      dispatch(removeOldestNotification());
    }
  }
}

const NotificationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)


export default NotificationsContainer;
