import React from 'react';
import { connect } from 'react-redux';
import { push } from 'redux-first-router';

import classNames from "classnames";

import ModeSelector from '../ModeSelector';
import Logo from '../Logo';
import OperatorTools from '../OperatorTools';
import Omnibox from '../Omnibox';
import ControlPanel from '../ControlPanel';
import AddBookedResourcesButton from '../AddBookedResourcesButton';
import Notifications from '../Notifications.jsx';
import MantaTour from "../MantaTour";
import DepartmentSelector from "../DepartmentSelector";
import ResourceCategories from "../ResourceCategories.jsx";
import ResourceBrowser from "../ResourceBrowser.jsx";
import FilteredResources from "../filteredResources.jsx";
import ResourceTimeline from '../resourceTimeline.jsx';
import ErrorScreen from "../ErrorScreen";

import { addResourceToBooking, fetchCategories, fetchDepartments, 
  fetchResources, fetchSettings, setOperator, initialize, fetchUserWithBookings,
  selectBookingResource, selectBarcodeResource, setMode, enterQuery,
  discardActiveBooking, fetchResourcesUnavailable, fetchNearestBookings,
  autoSelectDepartment
} from '../../actions';
import API from "../../api.js";

import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

import styles from "./index.global.scss";

class MantaApp extends React.Component {  

  constructor(props) 
  {
    super(props);

    this.state = {
      showTour: API.cookies.get("tourComplete") ? false : true,
      errored: false,
      lastError: null
    };

    Promise.all([
      this.props.dispatch(initialize()),
    ]).then(() => Promise.all([
      this.props.dispatch(setOperator()),
      this.props.dispatch(fetchDepartments()),
      this.props.dispatch(fetchResources()),
      this.props.dispatch(fetchCategories()),
    ])).then(() => {
      this.props.dispatch(autoSelectDepartment());
      this.props.dispatch(fetchNearestBookings());

      this.props.dispatch(fetchSettings());
      this.props.dispatch(enterQuery(""));
      this.props.dispatch(fetchResourcesUnavailable());
    });
  }

  componentWillReceiveProps(nextProps)
  {
    //User, if needs to be loaded
    if(nextProps.config.forceSelfAsUser && !nextProps.selfLoaded)
      this.props.dispatch(fetchUserWithBookings(API.loggedInUser().id));

    //Booking props
    const bookingPropsExist = !!(nextProps.mode && nextProps.userId &&
      nextProps.bookingId && nextProps.bookedResourceId);
    const bookingPropsChanged = (this.props.mode != nextProps.mode ||
      this.props.userId != nextProps.userId || 
      this.props.bookingId != nextProps.bookingId || 
      this.props.bookedResourceId != nextProps.bookedResourceId);

    if(bookingPropsExist && bookingPropsChanged)
      this.loadBookedResource(nextProps.mode, nextProps.userId,
        nextProps.bookingId, nextProps.bookedResourceId);
    else if(!bookingPropsExist && bookingPropsChanged)
      this.props.dispatch(discardActiveBooking());
  }

  componentWillMount()
  {
    const { mode, userId, bookingId, bookedResourceId } = this.props;
    if(mode && userId && bookingId && bookedResourceId)
      this.loadBookedResource(mode, userId, bookingId, bookedResourceId);
  }

  componentDidMount()
  {
    this.checkResourcesUnavailableInterval = setInterval(() => {
      this.props.dispatch(fetchResourcesUnavailable());
    }, 60000);

    if(this.props.config && !this.props.config.forceSelfAsUser)
      this.fetchNearestBookingsInterval = setInterval(() => {
        this.props.dispatch(fetchNearestBookings());
      }, 60000);
  }

  componentWillUnmount()
  {
    clearInterval(this.checkResourcesUnavailableInterval);
    clearInterval(this.fetchNearestBookingsInterval);
  }

  static getDerivedStateFromError(error) {
    return {
      errored: true,
      lastError: error
    }
  }

  componentDidCatch(e)
  {
    console.error(e)
    // TODO: log to logz
  }

  startTour = () => this.setState({ showTour: true })
  endTour = () => this.setState({ showTour: false })

  loadBookedResource = (mode, userId, bookingId, bookedResourceId) => {
    this.props.dispatch(fetchUserWithBookings(userId))
      .then(() => this.props.dispatch(setMode(mode)))
      .then(() => this.props.dispatch(
        selectBookingResource(
          parseInt(bookingId),
          parseInt(bookedResourceId)
        )
      ));
  }

  render() {
    if(this.props.disableRendering === true)
      return null;

    if(this.state.errored)
      return <ErrorScreen error={this.state.lastError} />

    if(!this.props.initialized)
      return null; // TODO: return generic loading...

    const {
      dispatch,
      location,
      config: { forceSelfAsUser, omniboxSearches, showTour },
      pendingTimeline
    } = this.props;
    const appStateClasses = classNames({
      "userPortal": forceSelfAsUser
    });
    const omniboxPlaceholder = `Search ${omniboxSearches.join(", ")}`;

    return (
      <Container fluid id="manta-app" className={appStateClasses}>
        {
          showTour && this.state.showTour && <MantaTour runTour={this.state.showTour} onEndTour={this.endTour} />
        }
        {
          forceSelfAsUser && <DepartmentSelector />
        }
        <Row id="actionBar" className="d-flex justify-content-between navbar-fixed-top flex-nowrap">
          <ModeSelector />
          <Logo environment={API.ENV}/>
          <div className="d-flex justify-content-end operatorTools">
            <OperatorTools onStartTour={this.startTour} />
          </div>
        </Row>
        <Row className="actionPanel">
          <Notifications />
          <Col className="controlPanel" md="6">
            <Omnibox
              className="omnibox"
              placeholder={omniboxPlaceholder}
              searchUsers={omniboxSearches.includes("users")}
              searchResources={false}
              searchBookedResources={omniboxSearches.includes("bookedResources")}
              optionSelected={(option) => {
                if(option.value === "barcode")
                  dispatch(selectBarcodeResource());
                else if(!isNaN(option.value))
                {
                  if(location.pathname !== "/app")
                    push("/app");
                  dispatch(fetchUserWithBookings(option.value));
                  dispatch(enterQuery(""));
                }
                else 
                {
                  push(option.value);
                }
              }
            } />
            <ControlPanel />
          </Col>
          <Col className="resourceBrowser" md="6">
            <ResourceBrowser
              onSelectResource={res => dispatch(addResourceToBooking(res))}
              emptyPlaceholder={
                <div className="panelStatusMessage expanded">
                  No resources match query
                </div>
              }
            />
          </Col>
          <Col md="6">
            <AddBookedResourcesButton />
          </Col>
        </Row>
        <Row className="resourceTimeline">
          <Col md="12">
            <ResourceTimeline pending={pendingTimeline} />
          </Col>
        </Row>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    pendingTimeline: state.pending.get("bookedResources") || state.pending.get("resources"),
    config: state.config,
    initialized: state.initialized,
    selfLoaded: state.user.id == state.operator.id,
    location: state.location
  }
}


export default connect(
  mapStateToProps
)(MantaApp);
