import React from "react";

import Separator from "./Separator";
import OwnBooking from "./OwnBooking";

import { loadBooking } from "../../actions";
import {
  displayTime,
  displayTimeFormatted,
  bookingRowColor,
  bookingRowTextColor
} from "./utils";

const BookingGroup = ({ group, bookings, activeTimezone, loadBooking }) => {
  if (!bookings || bookings.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <Separator key={group} text={group} />
      {
        bookings.map(booking => 
          <OwnBooking
            key={booking.id}
            userName={booking.Keeper.name}
            time={displayTimeFormatted(booking, activeTimezone)}
            color={bookingRowColor(displayTime(booking), booking.status)}
            textColor={bookingRowTextColor(displayTime(booking), booking.status)}
            booking={booking}
            bookedResources={booking.BookedResources}
            onSelectBooking={
              booking.status === "reserved"
                ? (() => loadBooking(booking))
                : undefined
            }
          />
        )
      }
    </React.Fragment>
  );
}

export default BookingGroup;
