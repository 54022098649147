import { connectRoutes } from 'redux-first-router';
import { applyMiddleware, compose, combineReducers, createStore } from 'redux';
import createHistory from 'history/createBrowserHistory';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';

import routesMap from "./routes";
import * as rootReducer from "./reducers";

export default function configureStore(preloadedState) {
  const history = createHistory();
  const {
    reducer: locationReducer,
    middleware: locationMiddleware,
    enhancer: locationEnhancer
  } = connectRoutes(history, routesMap);
  const middlewareEnhancer = applyMiddleware(
    locationMiddleware,
    thunkMiddleware,
    promiseMiddleware()
  );

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const composedEnhancers = composeEnhancers(locationEnhancer, middlewareEnhancer);

  const mantaReducer = combineReducers({ ...rootReducer, location: locationReducer });
  const mantaStore = createStore(mantaReducer, preloadedState, composedEnhancers);

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept('./reducers', () =>
      mantaStore.replaceReducer(mantaReducer)
    );
  }

  return mantaStore;
}