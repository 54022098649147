import { Map } from "immutable";
import { Department } from "../records.js";
import API from "../api";

export const departments = (state=Map(), action) => {
  switch(action.type) {
    case 'FETCH_DEPARTMENTS_FULFILLED':
      const departments = action.payload;
      return Department.makeRecords(departments);
    default:
      return state;
  }
}

const defaultSelectedDepartment = null;
export const selectedDepartment = (state=defaultSelectedDepartment, action) => {
  switch(action.type) {
    case 'SET_DEPARTMENT':
      if (![defaultSelectedDepartment, -1].includes(action.department)) {
        API.cookies.set("department", action.department);
      }
      return parseInt(action.department);
    default:
      return state;
  }
}

