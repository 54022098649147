import React from 'react';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';

import getBookedResourceStatusText from "./getBookedResourceStatusText";
import { getActiveTimezone } from "../../selectors";
import moment from "moment-timezone";

import { Card, CardHeader, CardBody, Button, Table }
  from "reactstrap";

const dateTimeFormat = 'MMM Do YYYY, h:mm a';

const generateBookingLink = bookedResource => 
  `/app/${bookedResource.Booking.status == "reserved" ? "activate" : "return"}/
${bookedResource.Booking.KeeperId}/${bookedResource.Booking.id}/${bookedResource.id}`;

const generateUserLink = bookedResource => `/manage/users/${bookedResource.Booking.Keeper.id}`;

const UserBookingRow = ({ bookedResource, activeTimezone }) => {
  const status = getBookedResourceStatusText(
    bookedResource, bookedResource.Booking, false, activeTimezone);
  const bookingLink = generateBookingLink(bookedResource);
  return (
    <tr>
      <td>{bookedResource.Resource.name}</td>
      <td>{moment.tz(bookedResource.bookedStart, activeTimezone).format(dateTimeFormat)}</td>
      <td>{moment.tz(bookedResource.bookedEnd, activeTimezone).format(dateTimeFormat)}</td>
      <td>{status}</td>
      <td><Link to={bookingLink} target="blank">Open booking</Link></td>
    </tr>
  );
};

class UserBookings extends React.Component
{
  render()
  {
    let { user, bookedResources } = this.props;
    let headerText = "Recent bookings";
    if(bookedResources)
      bookedResources = bookedResources.filter(bookedResource => bookedResource.Booking);
    return (bookedResources && <Card>
      <CardHeader>{headerText}</CardHeader>
      <CardBody>
      {
        bookedResources.length
        ? <Table>
          <thead>
            <tr>
              <th>Resource</th>
              <th>Booked at</th>
              <th>Due</th>
              <th>Status</th>
              <th>Tools</th>
            </tr>
          </thead>
          <tbody>
          {
            bookedResources.sort((a, b) => a.bookedEnd < b.bookedEnd).map(bookedResource =>
              bookedResource.Resource ? <UserBookingRow
                key={bookedResource.id}
                bookedResource={bookedResource}
                activeTimezone={this.props.activeTimezone}
              /> : null
            )
          }
          </tbody>
        </Table>
        : <i>No recent bookings for {user.name}</i> 
      }
      </CardBody>
    </Card>)
  }
}

const mapStateToProps = (state) => {
  return {
    DepartmentId: state.selectedDepartment,
    activeTimezone: getActiveTimezone(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

const UserBookingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBookings)


export default UserBookingsContainer;
