import React from "react";
import { connect } from 'react-redux';
import { renewBookingOrResource } from '../../../actions';
import { isActiveBookingAdjusted } from '../../../selectors';
import BookingControlButton from './BookingControlButton'

const RenewBookingButton = ({ booking, bookingAdjusted, dispatch }) => (
  <BookingControlButton
    bsStyle="primary"
    disabled={!bookingAdjusted}
    icon="loop2"
    caption="Renew All"
    clickHandler={() => dispatch(renewBookingOrResource(booking))}
  />
)

export default connect(
  (state) => {
    return {
      mode: state.mode,
      bookingAdjusted: isActiveBookingAdjusted(state)
    }
  }
)(RenewBookingButton)
