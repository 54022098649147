import { Map, List } from "immutable";

import { BookedResource, successfulBookingActions } from "../records.js";

export const defaultBookingConflicts = Map();
export const bookingConflicts = (state=defaultBookingConflicts, action) => {
  switch(action.type)
  {
    case "RAISE_ERROR":
      switch(action.error.code)
      {
        case "BOOKING_CLASHES":
        case "RESOURCE_NOT_AVAILABLE":
        {
          const conflictingResource = action.conflictData.unavailableResource;
          return state.merge(BookedResource.makeRecords([conflictingResource]));
        }
        default:
          return state;
      }
    case 'SET_MODE':
    case "CLEAR_ERRORS":
    case "DISCARD_BOOKINGS":
    case "DISCARD_ACTIVE_BOOKING":
      return defaultBookingConflicts;
    default:
      return state;
  }
}

export const eventConflicts = (state=List(), action) => {
  if(successfulBookingActions.includes(action.type))
    return List();

  switch(action.type)
  {
    case "RAISE_ERROR":
      switch(action.error.code)
      {
        case "BOOKING_OUTSIDE_SERVICE_HOURS":
          return List(action.conflictData.nearbyClosedTimes);
        default:
          return state;
      }
    case 'SET_MODE':
    case "SAVE_BOOKED_RESOURCES_FULFILLED":
    case "DISCARD_ACTIVE_BOOKING":
    case "DISCARD_BOOKINGS":
    case "CLEAR_ERRORS":
      return List();
    default:
      return state;
  }
}

export const errors = (state=List(), action) => {
  if(successfulBookingActions.includes(action.type))
    return List();

  switch(action.type)
  {
    case 'DISCARD_ACTIVE_BOOKING':
    case 'DISCARD_BOOKINGS':
    case 'SET_MODE':
    case 'CLEAR_ERRORS':
      return List();
    case 'RAISE_ERROR':
      return state.push(action.error);
    default:
      return state;
  }
}
