import React from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Omnibox from "../Omnibox";
import SinglePaneResourceBrowser from "../SinglePaneResourceBrowser";
import Icon from "../Icon";

import { selectBarcodeResource, addResourceToBookingById } from "../../actions";

import styles from "./index.scss";

class AddBookedResourcesButton extends React.Component {
  state = {
    modalOpen: false,
  };

  openModal = () => this.setState({ modalOpen: true });
  closeModal = () => this.setState({ modalOpen: false });

  handleOptionSelected = (option) => {
    if (option.value && !isNaN(option.value)) {
      const resourceId = option.value;
      this.props.dispatch(addResourceToBookingById(resourceId))
    }
  };

  render() {
    const { resourcesAdded } = this.state;
    return (
      <div className={styles.buttonContainer}>
        <Button className={styles.button} onClick={this.openModal}>
          <Icon icon="plus" />
          <span>Add resources</span>
        </Button>
        <Modal isOpen={this.state.modalOpen}>
          <ModalHeader>Add resources</ModalHeader>
          <ModalBody>
            <Omnibox
              placeholder="Search for resources..."
              searchResources={true}
              optionSelected={this.handleOptionSelected}
            />
            <SinglePaneResourceBrowser />
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.closeModal}>Done</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect()(AddBookedResourcesButton);
