import { Record, Map } from "immutable";

//User
export const UserRecord = Record({
  id: -1,
  name: "",
  email: "",
  role: "patron",
  notes: "",
  status: "active",
  badges: undefined,
  meta: {},
  Groups: new Set(),
  fetching: false
});
export const UserBadge = Record({
  name: "",
  style: "",
  type: ""
});
export class User extends UserRecord {
  constructor(obj) {
    super(obj);
  }

  static makeRecord(user) {
    return new User({
      ...user,
      badges: user.badges 
        ? user.badges.map(b => new UserBadge(b))
        : [] 
    })
  }

  static makeRecords(users) {
    let immutableMap = Map();
    for(let key in users)
      immutableMap = immutableMap.set(
        users[key].id,
        User.makeRecord(users[key])
      );
    return immutableMap;
  }

  // Defaults for a new user being created (manager)
  static newUser() {
    return {...new User().toJS(), id: undefined};
  }
}
