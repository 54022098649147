import { unserialize } from "../serializers.js";
import API from "../api";
import { fetchBookedResources, fetchBookings, fetchBookingActors, selectBookingResource } from "../actions.js";

export const fetchUser = (id) => ({
  type: "FETCH_USER",
  payload:
    API.get("user/" + id)
    .then(user => {
      user = unserialize(user);
      user.badges = [];
      if(user.role !== "patron")
        user.badges.push({type: "status", name: user.role, style: "success"});
      if(user.meta.clientRole)
        user.badges.push({type: "status", name: user.meta.clientRole, style: "info"});
      if(user.status === "inactive")
        user.badges.push({type: "status", name: "inactive", style: "danger"});
      return user;
    })
});

export const fetchUsers = (filter = {}) => ({
  type: "FETCH_USERS",
  payload: API.get("users/", filter)
});

export const fetchUserWithBookings = (userID) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: "FETCH_USER_WITH_BOOKINGS_PENDING" });
    if(state.mode !== "create")
    {
      dispatch(selectBookingResource(null, null));
    }
    return Promise.all([
      dispatch(fetchUser(userID)),
      dispatch(fetchBookings(userID))
        .then(payload => {
          const bookings = payload.value;
          const bookingDetailPromises = [
            dispatch(fetchBookedResources(bookings.map(b => b.id)))
          ];

          if (state.config.showBookingActors) {
            bookingDetailPromises.push(dispatch(fetchBookingActors(bookings)));
          }
          return Promise.all(bookingDetailPromises);
        })
    ])
    .then((actionResult) => {
      dispatch({ type: "FETCH_USER_WITH_BOOKINGS_FULFILLED" });
      return actionResult;
    })
  }
}

export const discardUser = () => {
  return { type: 'DISCARD_USER' }
}

// Operator is the logged in user
export const setOperator = () => {
  return { type: 'SET_OPERATOR', user: API.loggedInUser() }
}
