/*
 * Converts lists of objects that contain "id" to objects with keys as the ids
 *
 * [{id: 1, data: ...}, {id: 2, data: ...}] ----->
 * ---->  {'1': {id: '1', data: ...}, '2': {id: '2', data: ...}}
 */
export function unserialize(entity)
{
  if(Array.isArray(entity))
  {
    if(entity.length > 0 && entity[0].hasOwnProperty("id"))
    {
      entity = entity.reduce((object, x) => {
        object[x.id] = unserialize(x);
        return object;
      }, {});
    }
    return entity;
  }
  else if(entity instanceof Object)
  {
    for(let key in entity)
      entity[key] = unserialize(entity[key]);
    return entity;
  }
  else return entity;
}

/*
 * Does the opposite of unserialize
 *
 * {'1': {id: '1', data: ...}, '2': {id: '2', data: ...}} ---->
 * ----> [values only]
 */
export function serialize(entity)
{
  if(entity instanceof Object && Object.keys(entity).length > 0)
  {
    let testKey = Object.keys(entity)[0];
    if(entity[testKey].hasOwnProperty("id") 
      && testKey == entity[testKey].id)
    {
      entity = Object.values(entity)
      .map(serialize)
    }
  }
  return entity;
}
