import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import Icon from "../Icon";
import styles from "./index.global.scss";

class ResourceImageIcon extends React.Component {
  state = {
    modalOpen: false
  };

  openModal = () => this.setState({ modalOpen: true });
  closeModal = () => this.setState({ modalOpen: false });
  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });

  render() {
    const { resource } = this.props;

    return (
      <React.Fragment>
        <a id={`image-icon-${resource.id}`} onClick={this.openModal}>
          <Icon icon="images" color="muted" className="mr-1" />
        </a>
        <Modal size="lg" isOpen={this.state.modalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            {resource.name}
            {/*<Button color="link" onClick={this.closeModal}>Close</Button>*/}
          </ModalHeader>
          <ModalBody className={styles.resourceImageModalBody}
            style={{backgroundImage: `url(${API.generateLink("images/" + resource.image, { size: "large" }, true)})`}}>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ResourceImageIcon;
