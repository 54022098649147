import React from 'react';
import { connect } from 'react-redux';
import { redirect } from 'redux-first-router'
import { hot } from "react-hot-loader";

import API from "./api.js";

import MantaApp from './components/MantaApp/';
import MantaManager from './components/manager/MantaManager.jsx';
import Login from './components/Login/';
import ResetAccount from './components/ResetAccount/';
import Error from './components/Error.jsx';

const authRequiredTypes = ["APP", "APP_BOOKING", "MANAGE", "MANAGE_MODEL", "MANAGE_MODEL_ID"];
const MantaRouter = (action) => {
  const { location, redirect } = action;
  // Routes not requiring authentication
  console.log("Location", location.type);
  switch(location.type)
  {
    case "FRONT":
      redirect({ type: "APP" });
      return null;
    case "ERROR":
      return <Login API={API} {...location.payload} />
    case "LOGIN":
      return <Login API={API} previousLocation={location.prev.pathname} />
    case "LOGIN_FORWARDED":
      let { payload } = location.payload;
      try {
        payload = JSON.parse(atob(payload)); 
        API.setAuthCookies(payload);
      }
      catch(e) { console.error("Couldn't parse API's response with login payload"); }

      redirect({ type: "APP" });
      return null;
    case "RESET_ACCOUNT":
      return <ResetAccount API={API} {...location.payload} />
  }

  if(API.isAuthenticated())
  {
    /*
    API.apm.setUserContext({
      id: API.loggedInUser().id,
      username: API.loggedInUser().name,
      email: API.loggedInUser().email
    });
    API.startTransaction(API.loggedInUser().name);
    */

    // Routes requiring authentication
    switch(location.type)
    {
      case "LOGOUT":
        API.logout();
        redirect({ type: "LOGIN" });
        return null;
      case "APP":
      case "APP_BOOKING":
        return <MantaApp {...location.payload}/>;
      case "MANAGE":
        return <MantaManager />;
      case "MANAGE_MODEL":
      case "MANAGE_MODEL_ID":
        return <MantaManager {...location.payload}/>;
    }
  }
  else if(authRequiredTypes.includes(location.type)) {
    return <Login API={API} />
  }

  return <Error code={404} />
}

const mapStateToProps = (state) => {
  return {
    location: state.location
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    redirect: (action) => dispatch(redirect(action))
  }
}

export default hot(module)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MantaRouter)
);

