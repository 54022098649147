import { Record } from "immutable";

export const GroupRecord = Record({
  id: -1, 
  name: "", 
  notes: ""
});
export class Group extends GroupRecord {
  static newGroup() {
    return {...new Group().toJS(), id: undefined};
  }
}

