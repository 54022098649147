import React from 'react';
import { connect } from 'react-redux';
import { User } from '../../records.js';
import CardOverlayLoader from "../CardOverlayLoader.jsx";
import MetaFieldEditor from "./MetaFieldEditor.jsx";
import FormElement from "./FormElement.jsx";
import Icon from "../Icon.jsx";

import { Card, CardHeader, CardBody, Input, Label, FormGroup, Button, ButtonGroup}
  from "reactstrap";

class GroupAffinityEditor extends React.Component
{
  render() {
    const { groups, userGroups, setGroup } = this.props;
    return (
      <div>
        <hr />
        <h5 className="mt-3">Group affinity</h5>
        {groups.map(group => (
          <div key={group.id}>
            <FormElement type="checkbox" name={group.id.toString()} 
              onChange={(event) => setGroup(group, event.target.checked)}
              value={userGroups.has(group.id)} checkboxText={group.name}
            />
          </div>
        ))}
      </div>
    )
  }
}

const userMetaMap = [
  {
    key: "agreedToTerms",
    name: "Has agreed to Terms & Conditions",
    type: "boolean"
  },
  {
    key: "studentID",
    name: "Student ID",
    type: "int"
  },
  {
    key: "clientRole",
    name: "Organization Role",
    type: "enum",
    options: [
      { value: "student", name: "Student" },
      { value: "faculty", name: "Faculty" },
      { value: "staff", name: "Staff" },
      { value: "alum", name: "Alum" }
    ],
    defaultValue: "student"
  }
];

class UserEditor extends React.Component
{
  constructor(props)
  {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleMetaChange = this.handleMetaChange.bind(this);
  }

  state = {
    user: this.props.user
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState({ user: nextProps.user });
  }

  setGroup = (group, checked) => {
    const { user } = this.state;
    if(checked) 
      user.Groups.add(group.id);
    else
      user.Groups.delete(group.id);
    this.setState({ user });
  }

  getElementValue = (element) => {
    if(element.type === "checkbox" || element.type === "radio")
      return element.checked;
    else if(element.type === "number")
      return parseInt(element.value);
    else return element.value;
  }

  handleFieldChange(event)
  {
    this.setState({
      user: {
        ...this.state.user, 
        [event.target.name]: this.getElementValue(event.target)
      }
    });
  }

  handleMetaChange(event)
  {
    this.setState({
      user: {
        ...this.state.user,
        meta: {
          ...this.state.user.meta,
          [event.target.name]: this.getElementValue(event.target)
        }
      }
    });
  }

  render()
  {
    const { user } = this.state;
    const headerText = user.id ? `${user.name}` : " Creating a new user";
    return (
      <Card>
        <CardHeader className="d-flex">
          {headerText}
          <ButtonGroup className="ml-auto">
            <Button size="sm" onClick={this.props.onClear}>
              <Icon icon="redo2" />&nbsp;Reset
            </Button>
            { user.id &&
              <Button onClick={() => this.props.deleteUser(user)} size="sm" color="danger">
                <Icon icon="cross" />&nbsp;Delete
              </Button>
            }
            <Button size="sm" color="success"
              onClick={() => this.props.saveUser(user)}>
              <Icon icon="checkmark" />&nbsp;Save
            </Button>
          </ButtonGroup>
        </CardHeader>
        {this.props.pending && <CardOverlayLoader />}
        <CardBody>
          <FormElement name="name" label="Name" type="text"
            value={user.name} onChange={this.handleFieldChange} />

          <FormElement name="email" label="Email" type="text"
            value={user.email} onChange={this.handleFieldChange} />

          <FormElement name="role" label="Role" type="select"
            value={user.role} onChange={this.handleFieldChange}
          >
            <option key="admin" value="admin">Admin (can manage everything)</option>
            <option key="staff" value="staff">Staff (can manage everything except fines and service hours)</option>
            <option key="patron" value="patron">Patron (can make reservations for self)</option>
          </FormElement>

          <FormElement name="notes" label="Notes" type="textarea"
            value={user.notes} onChange={this.handleFieldChange}
          />

          <FormElement name="status" label="Status" type="select"
            value={user.status} onChange={this.handleFieldChange}
          >
            <option key="active" value="active">Active</option>
            <option key="inactive" value="inactive">Inactive</option>
          </FormElement>
          <GroupAffinityEditor groups={this.props.groups} userGroups={user.Groups} setGroup={this.setGroup}/>
          <MetaFieldEditor entity={user} metaMap={userMetaMap}
            handleMetaChange={this.handleMetaChange} />
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

const UserEditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEditor);


export default UserEditorContainer;
