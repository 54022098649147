import React from 'react';

import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  ListGroup, ListGroupItem, Badge, Input } from "reactstrap";
import Icon from "../../Icon.jsx";

import { connect } from 'react-redux';
import { getActiveTimezone } from "../../../selectors";
import Datetime from 'react-datetime';
import moment from "moment-timezone";
import classNames from "classnames";

import styles from "./index.scss";

const timeFormat = "h:mm a";

export class GroupPicker extends React.Component
{
  state = { open: false }
  toggle = () => this.setState({ open: ! this.state.open })
  render() {
    const { groups, selectedGroup, constraint, onChange, onDelete } = this.props;
    const group = groups.find(g => g.id === selectedGroup);
    const groupName = group ? group.name : "select group...";
    return (
      <ButtonDropdown isOpen={this.state.open} toggle={this.toggle} className="ml-1">
        <DropdownToggle size="sm">
          {groupName}
          <span className="caret sm" />
        </DropdownToggle>
        <DropdownMenu>
        {
          groups.map(group =>
            <DropdownItem key={group.id} onClick={() => onChange(constraint, group)}>
              {group.name}
            </DropdownItem>
          )
        }
        </DropdownMenu>
        <Button size="sm" color="danger" onClick={() => onDelete(constraint)}>
          <Icon icon="cross" size={12} />
        </Button>
      </ButtonDropdown>
    );
  }
}

class DurationField extends React.Component {
  state = { dropdownOpen: false };
  toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen })
  render() {
    const { duration, onChange } = this.props;
    return (
      <span>
        <Input style={{display: "inline", width: 60}} type="number" step="1" bsSize="sm" min={0} max={999} value={duration.value}
          onChange={event => onChange({...duration, value: event.target.value })}/>
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle size="sm" caret>{duration.unit}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => onChange({...duration, unit: "minutes"})}>minutes</DropdownItem>        
            <DropdownItem onClick={() => onChange({...duration, unit: "hours"})}>hours</DropdownItem>        
            <DropdownItem onClick={() => onChange({...duration, unit: "days"})}>days</DropdownItem>        
          </DropdownMenu>
        </ButtonDropdown>
      </span>
    );
  }
}

class FrequencyField extends React.Component {
  state = { dropdownOpen: false };
  toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen })
  render() {
    const { frequency, onChange } = this.props;
    return (
      <span>
        <Input style={{display: "inline", width: 50}} type="number" step="1" bsSize="sm" min={0} max={99} value={frequency.n}
          onChange={event => onChange({...frequency, n: event.target.value })}/>
        &nbsp;times per&nbsp;
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle size="sm" caret>{frequency.per}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => onChange({...frequency, per: "day"})}>day</DropdownItem>        
            <DropdownItem onClick={() => onChange({...frequency, per: "week"})}>week</DropdownItem>        
            <DropdownItem onClick={() => onChange({...frequency, per: "month"})}>month</DropdownItem>        
          </DropdownMenu>
        </ButtonDropdown>
      </span>
    );
  }
}

const TimePickerRaw = ({ time, activeTimezone, onChange }) => (
  <Datetime
    className="timePicker"
    value={time}
    dateFormat={false}
    timeFormat={timeFormat}
    onChange={onChange}
  />
);
const TimePicker = connect((state) => ({ activeTimezone: getActiveTimezone(state) }))(TimePickerRaw);

const nullGet = (object, key) => (object || {})[key];

export class ConstraintEditor extends React.Component
{
  constraintEditors = {
    "duration": (constraint) => 
      <span>
        Can be booked for a minimum of &nbsp;
        <DurationField duration={constraint.definition.minDuration} onChange={this.onChangeDuration("minDuration")}/>
        &nbsp; and a maximum of &nbsp;
        <DurationField duration={constraint.definition.maxDuration} onChange={this.onChangeDuration("maxDuration")}/>
      </span>,

    "time": (constraint) => 
      <span>Can only be booked from &nbsp;<TimePicker time={constraint.definition.fromTime} onChange={this.onChangeTime("fromTime")}/> 
      &nbsp;to&nbsp;<TimePicker time={constraint.definition.toTime} onChange={this.onChangeTime("toTime")}/></span>,

    "service_h": (constraint) => 
      <span>Can only be booked within service hours</span>,

    "frequency": (constraint) => 
      <span>Can't be booked more than &nbsp;<FrequencyField frequency={constraint.definition} onChange={this.onChangeFrequency}/></span>
  }

  onChangeDefinition = key => value => 
    this.props.onChange({...this.props.constraint, 
      definition: { ...this.props.constraint.definition, [key]: value } });

  onChangeDuration = field => duration =>
    this.props.onChange({...this.props.constraint, 
      definition: { ...this.props.constraint.definition, [field]: duration }});

  onChangeTime = field => time =>
    this.props.onChange({...this.props.constraint, 
      definition: { ...this.props.constraint.definition, [field]: time.format(timeFormat) }});

  onChangeFrequency = (frequency) =>
    this.props.onChange({...this.props.constraint, definition: frequency});

  render() {
    const { constraint } = this.props;
    if (!constraint)
      return null;

    console.log(constraint.type)

    return (
      <ListGroupItem style={{display: "block"}}>
        {this.constraintEditors[constraint.type](constraint)}
        <Button className="float-right" size="sm" color="danger"
          onClick={() => this.props.onDelete(constraint)}>
          <Icon icon="cross" />
        </Button>
      </ListGroupItem>
    );
  }
}

export class GroupConstraintEditor extends React.Component
{
  state = { groups: [] }

  componentWillMount() {
    API.get("groups").then(groups => this.setState({ groups }));
  }

  onChangeGroup = (constraint, group) => {
    this.props.onChange({ ...constraint, GroupId: group.id });
  }

  usableGroupList = (unusedGroups, constraint) => {
    const usableGroups = [...unusedGroups];
    if (constraint.GroupId) {
      const selectedGroup = this.state.groups.find(group => group.id === constraint.GroupId);
      if (selectedGroup) {
        usableGroups.push(selectedGroup);
      }
    }
    return usableGroups;
  }

  render() {
    const { groups } = this.state;
    const { constraints } = this.props;
    if (!constraints || constraints.length == 0)
      return null;

    const sufficientConstraints = constraints.filter(constraint => Boolean(nullGet(constraint.definition, "sufficient")));
    const insufficientConstraints = constraints.filter(constraint => !Boolean(nullGet(constraint.definition, "sufficient")));
    const usedGroupIds = constraints.map(constraint => constraint.GroupId);
    const unusedGroups = groups.filter(group => !usedGroupIds.includes(group.id));

    return (
      <ListGroupItem style={{display: "flex"}}>
        <div>
          Can only be booked by users that meet <strong>any</strong> of the following conditions:<br />
          <ul className={styles.groupConstraintList}>
            <li>
              <span className={classNames({"text-muted": !sufficientConstraints || sufficientConstraints.length === 0 })}>
                are in <strong>any</strong> of groups
              </span>
              {
                sufficientConstraints && sufficientConstraints.map((constraint, i) => (
                  <GroupPicker
                    key={`${constraint}-${i}`}
                    constraint={constraint}
                    groups={this.usableGroupList(unusedGroups, constraint)}
                    selectedGroup={constraint.GroupId}
                    onChange={this.onChangeGroup}
                    onDelete={this.props.onDelete}
                  />
                ))
              }
              {
                (constraints.length < groups.length) && 
                <Button className="ml-1 px-2" size="md" color="success" onClick={() => this.props.onAdd({definition: { sufficient: true }})}>
                  <Icon icon="plus" size={14} />
                </Button>
              }
            </li>
            <li>or</li>
            <li>
              <span className={classNames({"text-muted": !insufficientConstraints || insufficientConstraints.length === 0})}>
                are in <strong>all</strong> of groups
              </span>
              {
                insufficientConstraints && insufficientConstraints.map((constraint, i) => (
                  <GroupPicker
                    key={`${constraint}-${i}`}
                    constraint={constraint}
                    groups={this.usableGroupList(unusedGroups, constraint)}
                    selectedGroup={constraint.GroupId}
                    onChange={this.onChangeGroup}
                    onDelete={this.props.onDelete}
                  />
                ))
              }
              {
                (constraints.length < groups.length) && 
                <Button className="ml-1 px-2" size="md" color="success" onClick={() => this.props.onAdd({definition: { sufficient: false }})}>
                  <Icon icon="plus" size={14} />
                </Button>
              }
            </li>
          </ul>
        </div>
        <div className="ml-auto">
          <Button className="float-right" size="sm" color="danger"
            onClick={() => constraints.forEach(constraint => this.props.onDelete(constraint))}>
            <Icon icon="cross" />
          </Button>
        </div>
      </ListGroupItem>
    );
  }
}

export class ConstraintsPane extends React.Component
{
  state = { addConstraintOpen: false };

  getNewId = () => {
    const minId = this.props.constraints.reduce((id, constraint) => Math.min(id, constraint.id), 0);
    console.log("new id is", minId - 1);
    return minId - 1;
  }

  toggle = () => {
    this.setState({
      addConstraintOpen: !this.state.addConstraintOpen
    });
  }

  addGroupConstraint = (props = {}) => {
    const constraint = {
      id: this.getNewId(),
      definition: { sufficient: false },
      type: "group",
      status: "active",
      ...props
    }
    this.props.onAdd(constraint);
  }

  addDurationConstraint = () => {
    const constraint = {
      id: this.getNewId(),
      type: "duration",
      definition: {
        minDuration: { value: 5, unit: "minutes" },
        maxDuration: { value: 2, unit: "days" }
      },
      status: "active",
    }
    this.props.onAdd(constraint);
  }

  addTimeConstraint = () => {
    const constraint = {
      id: this.getNewId(),
      type: "time",
      definition: {
        fromTime: "12:00",
        toTime: "15:00"
      },
      status: "active",
    }
    this.props.onAdd(constraint);
  }

  addServiceHoursConstraint = () => {
    const constraint = {
      id: this.getNewId(),
      type: "service_h",
      status: "active",
    }
    this.props.onAdd(constraint);
  }

  addFrequencyConstraint = () => {
    const constraint = {
      id: this.getNewId(),
      type: "frequency",
      definition: {
        n: 5,
        per: "day"
      },
      status: "active",
    }
    this.props.onAdd(constraint);
  }

  constraintOfTypeExists = (constraints, type) => {
    return constraints.filter(constraint => constraint.type == type).length > 0;
  }

  render() {
    const { constraints, onAdd, onChange, onDelete } = this.props;
    const { addConstraintOpen } = this.state;
    const groupConstraints = constraints && constraints.filter(constraint => constraint.type === "group")
    const otherConstraints = constraints && constraints.filter(constraint => constraint.type !== "group")
    return (
      <div>
        <hr />
        <h5 className="mt-3">
          Constraints
          <ButtonDropdown 
            direction="down"
            isOpen={addConstraintOpen} 
            toggle={this.toggle} 
            className="float-right"
          >
            <DropdownToggle caret color="primary"><Icon icon="plus"/>&nbsp;Add constraint</DropdownToggle>
            <DropdownMenu style={{marginLeft: -245}}>
              <DropdownItem
                onClick={() => this.addGroupConstraint({ definition: { sufficient: true }})}
                disabled={this.constraintOfTypeExists(constraints, "group")}
              >
                Can only be booked by users in any specified <Badge pill>groups</Badge>
              </DropdownItem>
              <DropdownItem
                onClick={() => this.addGroupConstraint({ definition: { sufficient: false }})}
                disabled={this.constraintOfTypeExists(constraints, "group")}
              >
                Can only be booked by users in all specified <Badge pill>groups</Badge>
              </DropdownItem>
              <DropdownItem onClick={this.addDurationConstraint} disabled={this.constraintOfTypeExists(constraints, "duration")}>
                Can be booked for a minimum/maximum of <Badge pill>duration</Badge>
              </DropdownItem>
              <DropdownItem onClick={this.addTimeConstraint} disabled={this.constraintOfTypeExists(constraints, "time")}>
                Can only be booked from <Badge pill>time</Badge> to <Badge pill>time</Badge>
              </DropdownItem>
              <DropdownItem onClick={this.addServiceHoursConstraint} disabled={this.constraintOfTypeExists(constraints, "service_h")}>
                Can only be booked within service hours
              </DropdownItem>
              <DropdownItem onClick={this.addFrequencyConstraint} disabled={this.constraintOfTypeExists(constraints, "frequency")}>
                Can't be booked more than <Badge pill>n</Badge> times per <Badge pill>day/week/month</Badge>
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </h5>
        <br/>
        <div>
          <ListGroup>
          {
            groupConstraints &&
            <GroupConstraintEditor
              constraints={groupConstraints}
              onChange={onChange}
              onDelete={onDelete}
              onAdd={this.addGroupConstraint}
            />
          }
          {
            otherConstraints && otherConstraints.map((constraint, index) => (
              <ConstraintEditor
                key={`${constraint.id}-${index}`}
                constraint={constraint}
                onChange={onChange}
                onDelete={onDelete}
              />
          ))}
          </ListGroup>
        </div>
      </div>
    )
  }
}
