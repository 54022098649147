import React from 'react';
import Link from 'redux-first-router-link';
import { ListGroupItem } from "reactstrap";

import classNames from "classnames";
import Icon from "../Icon";

const stopPropagation = clickHandler => event => {
	event.stopPropagation();
	clickHandler(event);
}

const OwnBooking = ({ userName, time, color, textColor, booking, bookedResources, bookingLink, onSelectBooking }) => {
	const resourceList = bookedResources.length > 2
		? bookedResources
				.slice(0, 2)
				.map(br => br.Resource.name)
				.join(", ")
				.concat(` and ${bookedResources.length - 2} more`)
		: bookedResources
			.map(br => br.Resource.name)
			.join(", ");
	return (
	  <ListGroupItem
	  	action={Boolean(onSelectBooking)}
	  	color={color}
	  	className={`bookingText-${textColor}`}
	  	onClick={onSelectBooking}
	  >
	  	<div className="nearestBooking">
		    <div className="nearestBookingTime">{time}</div>
		    <div className="nearestBookingUser">
		      <strong>
		      	{resourceList}
		      	{booking.status === "canceled" && <small>(Canceled)</small>}
		      </strong>
		    </div>
		  </div>
	  </ListGroupItem>
	);
}

export default OwnBooking;
