import { unserialize } from "../serializers.js";

import API from "../api.js";

export const fetchDepartments = () => ({
  type: "FETCH_DEPARTMENTS",
  payload: 
    API.get("departments")
    .then(departments => unserialize(departments))
});

export const setDepartment = (department) => ({
  type: "SET_DEPARTMENT",
  department
});

export const autoSelectDepartment = () => (dispatch, getState) => {
  const state = getState();
  const { departments, config } = state;
  const isInUserPortal = config.forceSelfAsUser;
  const storedDepartmentId = parseInt(API.cookies.get("department"));

  const permittedDepartments = isInUserPortal
    ? departments.filter(department => department.config.enableUserPortal)
    : departments;
  const permittedDepartmentIds = permittedDepartments.map(d => d.id);
  if (permittedDepartmentIds.includes(storedDepartmentId)) {
    return dispatch(setDepartment(storedDepartmentId));
  }

  const defaultToDepartmentId = -1;
  let firstAvailableDepartmentId = permittedDepartmentIds.first();
  if (!firstAvailableDepartmentId || isInUserPortal) {
    firstAvailableDepartmentId = defaultToDepartmentId;
  }
  return dispatch(setDepartment(firstAvailableDepartmentId));
};
