import React from 'react';
import { Badge } from "reactstrap";


class UserBadge extends React.Component {  
  constructor(props)
  {
    super(props);
    this.state = {
      name: props.name,
      style: props.style
    };
  }

  render() {
    return (
      <Badge color={this.props.style}>{this.props.name}</Badge>
    );
  }

  componentWillMount()
  {
    // setInterval(this.tick, 300);
  }
}


export default UserBadge;