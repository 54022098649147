import React from 'react';
import { connect } from 'react-redux';
import { Map } from "immutable";

import { Card, CardHeader, CardFooter, CardBody, ListGroup, ListGroupItem,
  Container, Row, Col, Button, Collapse, Form, Input, UncontrolledTooltip }
  from "reactstrap";
import Datetime from 'react-datetime';
import moment from "moment-timezone";

import { adjustAllResourceTime, saveBooking, cancelBooking, setBookingNotes }
  from "../../actions";
import { getActiveBooking, getActiveBookedResources, getBookingBounds,
  getAutoCirculatingResources, getActiveTimezone }
  from "../../selectors";

import Icon from "../Icon.jsx";
import GenericSpinner from "../GenericSpinner.jsx";
import ValidatingDateTime from "../ValidatingDateTime";

import UserWidget from './UserWidget';
import BookingErrors from './BookingErrors';
import BookingStatus from './BookingStatus';
import BookedResourceList from './BookedResourceList';
import BookingControls from './BookingControls';
import NearestBookings from '../NearestBookings';
import OwnBookings from '../OwnBookings';
import ControlPanelWidget from './ControlPanelWidget';

import "./index.global.scss";

const ControlPanel = ({ mode, user, users, userPending, usersPending, activeBooking, activeBookedResources, autoCirculatingResources, bookingBounds, activeTimezone, config, dispatch }) => {
  const userSelected = user.id !== -1;
  const hasActiveResources =  activeBooking && !activeBookedResources.isEmpty();
  const hasAutoCirculatingResources = !autoCirculatingResources.isEmpty();

  let currentBookingOptionsLayout = "d-flex flex-wrap flex-column justify-content-start align-items-";
  currentBookingOptionsLayout += (userSelected || userPending) ? "start" : "center";
  const currentBookingResourcesLayout = "d-flex flex-column " + (hasActiveResources ? "align-items-end" : "align-items-center");

  return (
    <Choose>
      <When condition={config.showOwnBookings && !hasActiveResources}>
        <OwnBookings />
      </When>
      <When condition={userSelected || userPending || usersPending || hasActiveResources}>
        <Card className="controlPanel">
          <div className="controlPanelContainer">
            <div className={"options " + currentBookingOptionsLayout}>
              <ListGroup flush>
                <UserWidget />
                {
                  config.showBookingActors && activeBooking.status !== "tentative" &&
                  <ControlPanelWidget static icon="user-tie" text="Created by"
                    id="bookingCreatorWidget" childClassName="creator">
                    <a>{users.getIn([activeBooking.ServicedById, "name"], "Unknown User")}</a>
                  </ControlPanelWidget>
                }
                <ControlPanelWidget static icon="clock" text="Booking times"
                  id="bookingTimesWidget" childClassName="times d-flex">
                {
                  hasActiveResources
                    ? <React.Fragment>
                        <ValidatingDateTime
                          timezone={activeTimezone}
                          value={moment.tz(bookingBounds.start, activeTimezone)}
                          onChange={start => dispatch(adjustAllResourceTime(activeBooking.id, start, null))}
                        />
                        <ValidatingDateTime
                          timezone={activeTimezone}
                          additionalValidValues={["varied"]}
                          value={bookingBounds.end
                            ? moment.tz(bookingBounds.end, activeTimezone)
                            : "varied"}
                          onChange={end => dispatch(adjustAllResourceTime(activeBooking.id, null, end))}
                        />
                      </React.Fragment>
                    : <div className="panelStatusMessage">
                        <small>Select resources first</small>
                      </div>
                }
                </ControlPanelWidget>
                <ControlPanelWidget
                  static
                  compact
                  id="agreementWidget"
                  text="User Agreement"
                  icon="clipboard"
                  childClassName="agreementCheckbox"
                >
                  <Input type="checkbox" disabled
                    checked={user.meta.agreedToTerms || 0}
                    value={user.meta.agreedToTerms || 0}
                  />
                </ControlPanelWidget>
                <ControlPanelWidget icon="file-empty" text="Add Notes">
                  <Form>
                    <Input type="textarea" name="text"
                      value={activeBooking.notes || ""}
                      placeholder="e.g. printer model used, long-term rental, etc."
                      onChange={event => {
                        dispatch(setBookingNotes(activeBooking.id, event.target.value))
                      }} />
                  </Form>
                </ControlPanelWidget>
                {
                  hasAutoCirculatingResources &&
                  <ControlPanelWidget static compact icon="spinner10" text="Auto-circulating">
                    <Icon icon="info" color="dark" className="ml-auto" id="auto-circulating-info-icon" />
                    <UncontrolledTooltip placement="right" delay={0}
                      target="auto-circulating-info-icon">
                      You have added auto-circulating resources to this booking, which will automatically activate and return in the indicated times.<br /><br />Other regular resources cannot be added to such bookings.
                    </UncontrolledTooltip>
                  </ControlPanelWidget>
                }
                {/*<ControlPanelWidget icon="cog" text="Advanced Options">
                  <small>Options here...</small>
                </ControlPanelWidget>*/}
              </ListGroup>
            </div>
            <div className={"resources " + currentBookingResourcesLayout}>
              <BookedResourceList />
              <BookingErrors />
            </div>
          </div>
          <CardFooter className="d-flex footer">
            <div className="status">
              <BookingStatus />
            </div>
            <div className="controls">
              <BookingControls />
            </div>
          </CardFooter>
        </Card>
      </When>
      <Otherwise>
        <NearestBookings />
      </Otherwise>
    </Choose>
  );
}

const mapStateToProps = (state) => {
  return {
    activeBooking: getActiveBooking(state),
    activeBookedResources: getActiveBookedResources(state),
    autoCirculatingResources: getAutoCirculatingResources(state),
    bookingBounds: getBookingBounds(state),
    user: state.user,
    userPending: state.pending.user,
    users: state.users,
    usersPending: state.pending.users,
    mode: state.mode,
    activeTimezone: getActiveTimezone(state),
    config: state.config
  }
}

const ControlPanelContainer = connect(
  mapStateToProps
)(ControlPanel)

export default ControlPanelContainer;
