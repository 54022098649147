import React from "react";
import { connect } from 'react-redux';
import { Button, ButtonGroup } from "reactstrap";
import Icon from '../../Icon.jsx';

import makeCancelable from "../../../../utils/makeCancelable";

// Visual / interaction logic only
class BookingControlButton extends React.Component {
  constructor(props) {
    super(props);
    this.activeOnClick = {
      cancel(){}
    }
  }

  state = {
    forceDisable: false
  }

  componentWillUnmount() {
    this.activeOnClick.cancel();
  }

  handleOnClick = (clickHandler) => {
    if (this.props.disabled || this.state.forceDisable) {
      return;
    }
    this.setState({ forceDisable: true });
    this.activeOnClick = makeCancelable(
      new Promise((resolve, reject) => {
        const clickResult = clickHandler();
        if (typeof clickResult.then === "function") {
          clickResult
            .then(result => resolve(result))
            .catch(e => reject(e))
        } else {
          resolve();
        }
      })
    );
    this.activeOnClick
      .promise
      .then(() => this.setState({ forceDisable: false }))
      .catch((e) => {
        if (e && !e.isCanceled) {
          this.setState({ forceDisable: false })
        }
      });
  }

  render() {
    const { id, bsStyle, disabled, icon, caption, clickHandler } = this.props;
    return (
      <ButtonGroup>
        <Button
          id={id}
          color={bsStyle}
          disabled={disabled || this.state.forceDisable}
          onClick={() => this.handleOnClick(clickHandler)}
        >
          <Icon icon={icon} />&nbsp;{ caption }
        </Button>
      </ButtonGroup>
    );
  }
}

export default connect()(BookingControlButton);
