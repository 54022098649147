import React from 'react';
import { push, go, replace } from 'redux-first-router';

import { User } from '../../records';
import UserEditor from "./UserEditor.jsx";
import UserBookings from "./UserBookings.jsx";
import Omnibox from '../Omnibox/';
import MessageBox from "../MessageBox.jsx";

import { showNotification } from '../../actions';

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle,
  CardText, Container, Row, Col } from "reactstrap";

class UsersSection extends React.Component
{
  state = {
    pending: false,
    editedUser: User.newUser(),
    editedUserBookings: null,
    groups: []
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.loadUser)
      this.loadUserInEditor({ value: nextProps.loadUser });
  }

  componentDidMount() {
    API.get("groups").then(groups => this.setState({ groups }));
  }

  loadUserInEditor = (userOption) => {
    API.get("user/" + userOption.value, { associations: "Group" })
    .then(user => {
      return API.get("bookedResourceWithExtras", {UserId: user.id}).then(bookings => {
        user.Groups = new Set(user.Groups.map(g => g.id));
        this.setState({ editedUserBookings: bookings });
        this.setState({ editedUser: user });
      });
    });
  }

  clear = () => {
    this.setState({ editedUser: User.newUser(), editedUserBookings: null });
  }

  selectUser = (userOption) => {
    push("/manage/users/" + userOption.value);
  }

  saveUser = (changedUser) => {
    this.setState({ pending: true });
    const user = {...changedUser, Groups: [...changedUser.Groups]};
    const existingUser = user && user.id && user.id > 0;
    (() => {
      if(existingUser)
        return API.put("user/" + user.id, user);
      else
        return API.post("users", user);
    })()
    .then((user) => {
      if(!existingUser)
        this.clear();
      else
        this.loadUserInEditor({value: user.id})
      this.props.dispatch(showNotification({ message: `User ${user.name} has been saved` }))
      this.setState({ pending: false });
    })
    .catch(response => {
      this.props.dispatch(showNotification({
        message: response.message,
        color: "danger",
        dismiss: 10000
      }));
      this.setState({ pending: false });
      return;
    });
  }

  userHasUnreturnedBookings = () => {
    const badResource = br => !["complete", "canceled", "noshow"].includes(br.Booking.status);
    return this.state.editedUserBookings.filter(badResource).length > 0;
  }

  deleteUser = (user) => {
    if(this.userHasUnreturnedBookings())
    {
      this.setState({ userUnreturnedBookingsModal: true });
      return;
    }
    if(!confirm("Are you sure you want to delete this user?"))
      return;
    return API.delete("user/" + user.id)
    .then(() => {
      this.setState({ editedUser: User.newUser(), editedUserBookings: null});
      this.props.dispatch(showNotification({
        message: `User ${user.name} has been deleted`
      }));
    });
  }

  render() {
    return (
      <Row className="manager-row">
        <Col md={4} className="d-flex flex-column align-items-stretch justify-content-start">
          <Omnibox searchUsers={true} optionSelected={this.selectUser} placeholder="Search users" />
        </Col>
        <Col md={8} className="scroll-col">
          <UserEditor
            pending={this.state.pending}
            user={this.state.editedUser}
            saveUser={this.saveUser}
            deleteUser={this.deleteUser}
            onClear={this.clear}
            groups={this.state.groups}
          />
          <br/>
          <UserBookings user={this.state.editedUser} bookedResources={this.state.editedUserBookings} />
          <MessageBox 
            showing={this.state.userUnreturnedBookingsModal} 
            title="User has incomplete bookings"
            body="To delete a user, they should not have any active bookings or upcoming reservations. Please process all active bookings and cancel any upcoming reservations before deleting this user."
            showCancel={false}
            toggle={() => this.setState({userUnreturnedBookingsModal: false})} 
          />
        </Col>
      </Row>
    );
  }
}

export default UsersSection;
