import { Map, Record } from "immutable";

const defaultConfig = {
  enableUserPortal: false
}

export const DepartmentRecord = Record({
  id: -1, 
  name: "", 
  config: defaultConfig,
  notes: "",
  status: "active",
  timezone: "America/New_York",
  ClientId: null
});

export class Department extends DepartmentRecord {

  static makeRecord(rawDep) {
    if(rawDep && !rawDep.config)
      rawDep.config = defaultConfig;

    return new Department(rawDep);
  }

  static makeRecords(departments) {
    let immutableMap = Map();
    for(let key in departments)
      immutableMap = immutableMap.set(
        departments[key].id,
        Department.makeRecord(departments[key])
      );
    return immutableMap;
  }
}
